// Angular
import { NgModule, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Routes, RouterModule, RouterStateSnapshot, TitleStrategy } from '@angular/router';

// Application
import { WorklistComponent } from './worklist/worklist.component';
import { NotifyComponent } from './notify/notify.component';
import { MediaComponent } from './media/media.component';
import { MediaPlayComponent } from './media-play/media-play.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './login/login.component';
import { ActivityComponent } from './activity/activity.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsComponent } from './settings/settings.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { UnauthorizedComponent } from './error/unauthorized/unauthorized.component'; 
import { environment } from 'src/environments/environment';
import { AuthGuard } from './shared/helpers/authGuard';
import { HelpGuideComponent } from './help-guide/help-guide.component';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title    
    ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(environment.productName + `${title}`);
    }
  }
}

const routes: Routes = [
 {
    path: 'login',
    component: LoginComponent,
    title: ' | Login'
  },{
    path: 'logout',
    component: LogoutComponent,
    title: ' | Logout'
  },{
    path: 'notify',
    component: NotifyComponent,
    title: ' | Notify',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'media',
    component: MediaComponent,
    title: ' | Media',
    canActivate: [
      AuthGuard
    ],
  },{
    path: 'mediaplay',
    component: MediaPlayComponent,
    title: ' | Media Play',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'worklist',
    component: WorklistComponent,
    title: ' | Worklist',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'activity',
    component: ActivityComponent,
    title: ' | Activity',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'reports',
    component: ReportsComponent,
    title: ' | Reports',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'settings',
    component: SettingsComponent,
    title: ' | Settings',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'help',
    component: HelpGuideComponent,
    title: ' | Help',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'error', 
    component: NotFoundComponent,
    title: ' | Error 404'
  },{
    path: 'unauthorized', 
    component: UnauthorizedComponent,
    title: ' | Unauthorized'
  },{
    path: '',
    redirectTo: '/login', 
    pathMatch: 'full'
  },{ // Wildcard route should always be the last
    path: '**', 
    redirectTo: 'error'
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
})],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy
    }
  ]
})
export class AppRoutingModule { }