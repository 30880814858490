// Angular
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl}  from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';

// Shared Lib
import { CloudApiResponse, CustomerDefaultMediaPreference } from 'kscigcorelib';
import { LoadingBarService, LoggingService, NotificationBarService, EncryptionService } from 'kscigcorelib';
import { MediaModel, MediaType, MediaCategory, MediaCategoryMap } from 'kscigcorelib';

// Material
import { MatTabChangeEvent as MatTabChangeEvent } from '@angular/material/tabs'

// Application
import { RouteHelper } from '../../shared/helpers/route.helper';
import { SessionHelper } from '../../shared/helpers/session.helper';
import { MediaService } from '../../shared/services/media.service';


@Component({
  selector: 'app-media-default',
  templateUrl: './media-default.component.html',
  styleUrls: ['./media-default.component.css']
})
export class MediaDefaultComponent implements OnInit {

  public isModuleEnabled:boolean = false;

  public customerId:string;
  public mediaTypeList: MediaType[];  
  public mediaList: MediaModel[];
  public savedCustomerDefaultMediaPref: CustomerDefaultMediaPreference = new CustomerDefaultMediaPreference();
  public unsavedCustomerDefaultMediaPref: CustomerDefaultMediaPreference = new CustomerDefaultMediaPreference();  
  public selectedMedia: MediaModel = new MediaModel();
  public selectedMediaType: MediaType = new MediaType();
  


  constructor( 
    private routeHelper:RouteHelper,
    private sessionHelper:SessionHelper,
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private mediaService:MediaService,
    private notificationBarService: NotificationBarService,
    private encryptionService: EncryptionService) { 
    }

  ngOnInit() {
    
    this.isModuleEnabled = this.sessionHelper.isMediaModuleEnabled();
    
    if(this.isModuleEnabled){
      this.mediaTypeList = [];
      this.mediaList = [];
      if(this.sessionHelper.isValidUserSession()){
        // Start loading bar
        this.loadingBarService.startBar();
        this.customerId = this.sessionHelper.getCustomerId();
        // Load Page Data
        this.loadPageData();
      }
    }
  }

  private loadPageData(){
    this.mediaService.getMediaTypes(this.customerId)
      .subscribe({ 
          next: (mediaTypeResult: CloudApiResponse) => {
                this.loggingService.logVerbose(mediaTypeResult.payload);
                this.mediaTypeList = mediaTypeResult.payload;
                                  
                  // get Media
                  this.mediaService.getMediaAll(this.customerId)
                  .subscribe({ 
                      next: (mediaResult: CloudApiResponse) => {
                            var deserializedResult = this.encryptionService.decryptUsingAES256(mediaResult.payload);
                            this.loggingService.logVerbose(deserializedResult);
                            this.mediaList = JSON.parse(deserializedResult);  

                              // get Customer Default Media Preference
                              this.mediaService.getCustomerDefaultMediaPreference(this.customerId)
                              .subscribe({
                                  next: (apiResponse: CloudApiResponse) => {
                                        var deserializedResult = this.encryptionService.decryptUsingAES256(apiResponse.payload);
                                        this.loggingService.logVerbose(deserializedResult);
                                        this.savedCustomerDefaultMediaPref = JSON.parse(deserializedResult);
                                        this.copyObject(this.unsavedCustomerDefaultMediaPref, this.savedCustomerDefaultMediaPref);
                                                    
                                        this.selectedMedia = this.mediaList.find(m=>m.MediaId == this.savedCustomerDefaultMediaPref.MediaId)
                                        if (this.selectedMedia != undefined) {
                                          this.selectedMediaType = this.mediaTypeList.find(m=>m.mediaTypeId == this.selectedMedia.MediaTypeId);
                                        }
                                        
                                        this.loadingBarService.stopBar();     
                                      },
                                  error: () => { this.loggingService.logError("Error loading Customer Default media preference"); },
                                  complete: () => { this.loggingService.logVerbose("Completed loading Customer Default media preference"); }          
                              });
                          },
                      error: () => { this.loggingService.logError("Error loading Media All"); },
                      complete: () => { this.loggingService.logVerbose("Completed loading Media All"); } 
                  });                         
                },
          error: () => { this.loggingService.logError("Error loading Media Types"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Media Types");}
      });
  }

  public onMediaSelectionChange(media: MediaModel) {    
    
    // enable selection save button
    this.selectedMedia = media;
    this.selectedMediaType = this.mediaTypeList.find(m=>m.mediaTypeId == this.selectedMedia.MediaTypeId);
    this.unsavedCustomerDefaultMediaPref.MediaId = media.MediaId;
    this.unsavedCustomerDefaultMediaPref.MediaTypeId = media.MediaTypeId;
    
    // Show slide show does not apply to video, so set to false
    // For audio, by default we want to show SlideShow, so set to true
    this.unsavedCustomerDefaultMediaPref.ShowSlideShow = this.selectedMediaType.mediaTypeName == "Video" ? false : true;
    
  }
   
  public saveSelectedMedia() {
     
    this.mediaService.setCustomerDefaultMediaPreference(this.unsavedCustomerDefaultMediaPref)
      .subscribe({ 
          next: (isSaved: boolean) => {
                this.copyObject(this.savedCustomerDefaultMediaPref, this.unsavedCustomerDefaultMediaPref);
                
                this.loggingService.logVerbose("Successfully Saved default media selection");
                this.notificationBarService.showSuccess("Successfully Saved default media selection");
              },
          error: () => {
                this.loggingService.logError("Error Saving default Media selection");
                this.notificationBarService.showError("Error Saving default Media selection");  
              },
          complete: () => { this.loggingService.logVerbose("Completed Saving default Media selection"); }
      });
  }

  private copyObject(destination: CustomerDefaultMediaPreference, source:CustomerDefaultMediaPreference) {
    destination.CustomerId = source.CustomerId;
    destination.MediaId = source.MediaId;
    destination.MediaTypeId = source.MediaTypeId;
    destination.ShowSlideShow = source.ShowSlideShow;
  }

}


