<h2 mat-dialog-title class="DialogCaptionLabel">
    <span *ngIf="isAdd">Add</span><span *ngIf="!isAdd">Edit</span> Patient Information
</h2>
<mat-dialog-content>

  <div class="alignCenter">
    <mat-form [formGroup]="wlItemEditorForm">
      <mat-form-field>
          <mat-label>Patient Id</mat-label>
          <input matInput type="text" placeholder="" formControlName="patientId" />   
          <mat-error>
            <div *ngIf="wlItemEditorForm.get('patientId').hasError('required')">
              Patient Id is Required
            </div>              
          </mat-error>         
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput type="text" placeholder="First Name" formControlName="firstName"/>
        <mat-error>
          <div *ngIf="wlItemEditorForm.get('firstName').hasError('required')">
            First Name is Required
          </div>
          <div *ngIf="wlItemEditorForm.get('firstName').hasError('maxlength')">
            First Name can be at max 50 characters long
          </div>
          <div *ngIf="wlItemEditorForm.get('firstName').hasError('pattern')">
            First Name can only have characters (a-z A-Z , . - ')
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Middle Name</mat-label>
        <input matInput type="text" placeholder="Middle Name" formControlName="middleName"/>
        <mat-error>
          <div *ngIf="wlItemEditorForm.get('middleName').hasError('maxlength')">
            Middle Name can be at max 50 characters long
          </div>
          <div *ngIf="wlItemEditorForm.get('middleName').hasError('pattern')">
            Middle Name can only have characters (a-z A-Z , . - ')
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput type="text" placeholder="Last Name" formControlName="lastName"/>
        <mat-error>
          <div *ngIf="wlItemEditorForm.get('lastName').hasError('required')">
            Last Name is Required
          </div>
          <div *ngIf="wlItemEditorForm.get('lastName').hasError('maxlength')">
            Last Name can be at max 50 characters long
          </div>
          <div *ngIf="wlItemEditorForm.get('lastName').hasError('pattern')">
            Last Name can only have characters (a-z A-Z , . - ')
          </div>
        </mat-error>
      </mat-form-field>
      <br>        
      <mat-form-field>
        <mat-label>Gender</mat-label>                      
          <mat-select placeholder="Select gender" formControlName="gender">
              <mat-option value="F" >F</mat-option>
              <mat-option value="M" >M</mat-option>
              <mat-option value="O" >O</mat-option>
              <mat-option value="U" >U</mat-option>
          </mat-select> 
          <mat-error>
            <div *ngIf="wlItemEditorForm.get('gender').hasError('required')">
              Gender is required
            </div>                           
          </mat-error>               
      </mat-form-field>
      <br>
      <mat-form-field>
          <mat-label>Date of Birth</mat-label>
          <input matInput [matDatepicker]="picker1" formControlName="dob" >
          <mat-hint>mm/dd/yyyy</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error>
            <div *ngIf="wlItemEditorForm.get('dob').hasError('required')">
              DOB is required
            </div>   
            <div *ngIf="wlItemEditorForm.get('dob').hasError('dobaftsurdate')">
              Date of Birth must be before Surgery Date
            </div>              
          </mat-error>       
      </mat-form-field>
      <br>
      <!--
      <mat-form-field>
        <mat-label>Surgery Date</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker" formControlName="surgeryDate">
        <mat-hint>mm/dd/yyyy, hh:mm</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [enableMeridian]="false" [disabled]="false">
          <ngx-mat-datepicker-actions>
            <button mat-raised-button ngxMatDatepickerCancel>Cancel</button>
            <button mat-raised-button ngxMatDatepickerApply>Apply</button>
          </ngx-mat-datepicker-actions>
        </ngx-mat-datetime-picker>
        <mat-error>
          <div *ngIf="wlItemEditorForm.get('surgeryDate').hasError('required')">
            Surgery Date is required
          </div>  
          <div *ngIf="wlItemEditorForm.get('surgeryDate').hasError('dobaftsurdate')">
            Date of Birth must be before Surgery Date
          </div>              
        </mat-error>
      </mat-form-field>
      -->
      <mat-form-field>
        <mat-label>Surgery Date</mat-label>
        <input matInput [owlDateTime]="picker" formControlName="surgeryDate">
        <mat-hint>mm/dd/yyyy, hh:mm</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <owl-date-time [pickerMode]="'popup'" #picker style="height: 100%;"></owl-date-time>
        <mat-error>
          <div *ngIf="wlItemEditorForm.get('surgeryDate').hasError('required')">
            Surgery Date is required
          </div>  
          <div *ngIf="wlItemEditorForm.get('surgeryDate').hasError('dobaftsurdate')">
            Date of Birth must be before Surgery Date
          </div>              
        </mat-error>
      </mat-form-field>

      <br>
      <mat-form-field>
          <mat-label>Location</mat-label>
          <mat-select placeholder="Select a location" formControlName="location" >
              <mat-option *ngFor="let loc of locations" [(value)]="loc.Name" >{{loc.Name}}</mat-option>                
          </mat-select>
          <mat-error>
            <div *ngIf="wlItemEditorForm.get('location').hasError('required')">
              Location is required
            </div>                           
          </mat-error>              
      </mat-form-field>
      <br>

      <mat-form-field>
          <mat-label>Surgeon First Name</mat-label>
          <input matInput type="text" placeholder="" formControlName="surgeonFirstName"/>            
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Surgeon Last Name</mat-label>
        <input matInput type="text" placeholder="" formControlName="surgeonLastName"/>            
      </mat-form-field>
      <br>

      <mat-form-field>
          <mat-label>Procedure</mat-label>
          <input matInput type="text" placeholder="" formControlName="procedure"/>            
      </mat-form-field>
      <br>
      <mat-form-field>
          <mat-label>Accession #</mat-label>
          <input matInput type="text" placeholder="" formControlName="accessionNumber"/>            
      </mat-form-field>
      <br> 
    </mat-form>
  </div>

</mat-dialog-content>
  
<mat-dialog-actions class="alignCenter">
  <button mat-raised-button mat-dialog-close class="btn-icon-default" style="margin-left:5px;" mat-dialog-close>Cancel
    <mat-icon>cancel</mat-icon>
  </button>   
  <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" [disabled]="!wlItemEditorForm.dirty || !wlItemEditorForm.valid" (click)="saveWorklistItem()">Save
    <mat-icon>save</mat-icon>
  </button>
</mat-dialog-actions>
