export enum applicationToolbar {
    none = 0,
    worklist = 1,
    notify = 2,
    media = 3,
    mediaPlay = 4
}

export enum appPageName{
    login = "/login",
    logout = "/logout",
    home = "/home",
    worklist = "/worklist",
    notify = "/notify",
    media = "/media",
    settings = "/settings",
    error = "/error"
}

export enum cookieName {
    applicationData = "KS_ComfortOR1AppData",
}