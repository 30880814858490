// Angular 
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, EnvService } from 'kscigcorelib';
import { HandleErrorService, EncryptionService, ApiService } from 'kscigcorelib';

// Application


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private encryptionService: EncryptionService,
    private apiService: ApiService,
    private envService: EnvService
  ) { }

  // Check if valid customer by domain name
  public isValidCustomer(domainName: string): Observable<CloudApiResponse> {
    if(domainName.indexOf('@') > -1){
      domainName = domainName.substring(domainName.indexOf('@') + 1, domainName.length);
    }
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AuthApiCustomer/isValid?encdn=' + this.apiService.secureApiQueryString(domainName), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('isValidCustomer', []))
      );
  }

  public getCustomerInstallDetails(customerId:string):Observable<CloudApiResponse>{
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AuthApiCustomer/getDetails?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('getCustomerInstallDetails', []))
      );
  }

  public getCustomerIdleTimeoutMinutes(customerId:string):Observable<CloudApiResponse>{
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AuthApiCustomer/getIdleTimeoutMinutes?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('getCustomerIdleTimeoutMinutes', []))
      );
  }
  
}
