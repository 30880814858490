import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog as MatDialog, MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef} from '@angular/material/dialog';
import {FormsModule} from '@angular/forms';

export interface DialogData {
  title: string;
  message: string;
  errorMessage: string;
  inputLabel: string;
  userInput: string;
  validateInputWith: string;  
}

@Component({
  selector: 'app-validate-input-dialog',
  templateUrl: './validate-input-dialog.component.html',
  styleUrls: ['./validate-input-dialog.component.css']
})
export class ValidateInputDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ValidateInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onSubmitClick(): void {  
    // validate input
    if (this.data.userInput === this.data.validateInputWith) {      
      // validated
      this.dialogRef.close(true);
    } else {
      // input not valid, display error message
      document.getElementsByClassName("error")[0].removeAttribute("hidden");     
    } 
    
  }
}
