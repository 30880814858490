// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, EnvService } from 'kscigcorelib';
import { HandleErrorService, ApiService } from 'kscigcorelib';

// Component
import { UserMessageCategory } from '../../notify/notify-model';
 
@Injectable({
    providedIn: 'root'
})
export class UserSubscriptionsService {

    constructor(
      private http: HttpClient,
      private handleError: HandleErrorService,
      private apiService:ApiService,
      private envService: EnvService
    ) { }

    public getMessageCategorySubscription(customerId: string): Observable<CloudApiResponse> {
        return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiSubscription/GetMsgCatSub?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
        .pipe(
            catchError(this.handleError.handleError<any>('Get Message Category Subscription', []))
        );
    }

    public updateUserMessageCategorySubscription(customerId:string, userId:string, msgCategoryId:string, isSelected:boolean): Observable<CloudApiResponse> {        
        return this.http.get(this.envService.apiUrl + '/NotifyApiSubscription/UpdateMsgCatSub?ecid=' + this.apiService.secureApiQueryString(customerId) + "&euid="+ this.apiService.secureApiQueryString(userId) + "&catId=" + msgCategoryId + "&excl=" + !isSelected, this.apiService.getCustomHeader())
        .pipe(
          catchError(this.handleError.handleError<any>('Update Message Category Subscription'))
        );
    }

    public applyUserMessageCategorySubscriptionToDept(customerId:string, userId:string): Observable<CloudApiResponse> {        
        return this.http.get(this.envService.apiUrl + '/NotifyApiSubscription/ApplyMsgCatSubToDept?ecid=' + this.apiService.secureApiQueryString(customerId) + "&euid="+ this.apiService.secureApiQueryString(userId), this.apiService.getCustomHeader())
        .pipe(
          catchError(this.handleError.handleError<any>('Apply Message Category Subscription To Dept'))
        );
    }


    public getLocationSubscription(customerId: string): Observable<CloudApiResponse> {
        return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/WorklistApiSubscription/GetLocSub?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
        .pipe(
            catchError(this.handleError.handleError<any>('Get Location Subscription', []))
        );
    }

    public updateUserLocationSubscription(customerId:string, userId:string, locId:string, isSelected:boolean): Observable<CloudApiResponse> {        
        return this.http.get(this.envService.apiUrl + '/WorklistApiSubscription/UpdateLocSub?ecid=' + this.apiService.secureApiQueryString(customerId) + "&euid="+ this.apiService.secureApiQueryString(userId) + "&locId=" + locId + "&excl=" + !isSelected, this.apiService.getCustomHeader())
        .pipe(
          catchError(this.handleError.handleError<any>('Update Location Subscription'))
        );
    }

    public applyUserLocationSubscriptionToDept(customerId:string, userId:string): Observable<CloudApiResponse> {        
        return this.http.get(this.envService.apiUrl + '/WorklistApiSubscription/LocSubToDept?ecid=' + this.apiService.secureApiQueryString(customerId) + "&euid="+ this.apiService.secureApiQueryString(userId), this.apiService.getCustomHeader())
        .pipe(
          catchError(this.handleError.handleError<any>('Apply Location Subscription To Dept'))
        );
    }
}  