<div *ngIf="!isModuleEnabled">
  <div class="alignCenter Padding5px">
      Oops! You are not subscribed to this module.
  </div>
</div>

<div *ngIf="isModuleEnabled" class="media-grid">

  <lib-media-template #mediatemp (galleryMediaSelectionChangeEvent)="onMediaSelectionChange($event)" 
                                 (selectionPanelMediaSelectionSaveEvent)="saveMediaSelection($event)"
                                 (showSlideShowSliderChangeEvent)="updateShowSlideShowChoice($event)"
                                 (selectionPanelMediaSelectionClearEvent)="clearMediaSelection($event)" >
  </lib-media-template>

  <div class="contentBox media-grid-item-contents borderDarkBlue">

    <div>
      
      <div class="filter">
        <mat-form-field style="width: auto;">
          <mat-label>Category Filter</mat-label>
          <mat-select [(ngModel)]="selectedMediaCategory"  >
              <mat-option *ngFor="let mediaCategory of mediaCategoryList" [value]="mediaCategory">
              {{mediaCategory.displayCategoryName}}                                         
              </mat-option>
          </mat-select>
        </mat-form-field>          
      </div>                                    
       
      <!-- Media Gallery -->        
        <ng-container [ngTemplateOutlet]="mediatemp.mediaGallery" [ngTemplateOutletContext]="{ mediaTypeList: mediaTypeList, mediaList: mediaList, selectedMedia: selectedMedia, savedMediaPref: savedMediaPref, selectedMediaCategory: selectedMediaCategory }"
        ></ng-container>
                      
    </div> 
    
  </div>
  
  <div class="contentBox media-grid-item-selections borderDarkBlue">
      <div class="CaptionLabel mediaCaptionLabel">Media Selection:</div>
      <div class="alignCenter">
        <div class="margin-top-10px" *ngIf="!selectedMedia || !selectedMedia.MediaId">
          <div>Please select media to play.</div>
          <div class="margin-top-10px">OR</div>
          <div class="margin-top-10px">System default media will be played.</div>
        </div>
      
        <div class="margin-top-10px" *ngIf="selectedMedia && selectedMedia.MediaId">
          <ng-container [ngTemplateOutlet]="mediatemp.mediaSelectionPanel" [ngTemplateOutletContext]="{ selectedMedia: selectedMedia, selectedMediaType: selectedMediaType, savedMediaPref: savedMediaPref, unsavedMediaPref: unsavedMediaPref }"
            ></ng-container>            
        </div>

      </div>
      
  </div>
</div>
