<div class="msg-categories-container">

    <div class="borderBlue">
        <mat-table [dataSource]="messageCategoryList" class="mat-elevation-z1" matSort>
            <ng-container matColumnDef="MessageCategoryName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Message Category Name </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="ContactCategoryName">
                <mat-header-cell *matHeaderCellDef> Contact Category Name </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="DisplayOrder">
                <mat-header-cell *matHeaderCellDef>Display Order</mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="EditDelete">
                <mat-header-cell *matHeaderCellDef>
                    <div class="alignCenter">
                        Actions
                    </div>
                </mat-header-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        </mat-table>
    </div>

    <mat-table #dataTable [dataSource]="messageCategoryViewModel" class="mat-elevation-z1 notify-messagecategories-data-table" matSort >
        <ng-container matColumnDef="ContactCategoryName">
            <mat-cell *matCellDef="let element"> 
                <div *ngIf="!element.isEditing">
                    {{element.ContactCategoryName}} 
                </div>
                <div *ngIf="element.IsEditing">

                    <mat-form-field>
                        <mat-select [(value)]="element.ContactCategoryId" >
                            <mat-option *ngFor="let contactCategory of contactCategoryList" [value]="contactCategory.ContactCategoryId">
                                {{contactCategory.ContactCategoryName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="MessageCategoryName">
            <mat-cell *matCellDef="let element"> 
                <div *ngIf="!element.IsEditing">
                    {{element.MessageCategoryName}} 
                </div>
                <div *ngIf="element.IsEditing">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Category Name" maxlength=50 [(ngModel)]="element.MessageCategoryName"/>
                    </mat-form-field>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="DisplayOrder">
            <mat-cell *matCellDef="let element"> 
                <div *ngIf="!element.IsEditing">
                    {{element.DisplayOrder}} 
                </div>
                <div *ngIf="element.IsEditing">
                    <mat-form-field>
                        <input matInput type="text" placeholder="DisplayOrder" [(ngModel)]="element.DisplayOrder"/>
                    </mat-form-field>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="EditDelete">
            <mat-cell *matCellDef="let element"> 
                <div class="alignCenter" *ngIf="element.IsDeleted == true">
                    <button mat-icon-button (click)="undoDeleteMessageCategory(element)" title="Undo">
                        <mat-icon>undo</mat-icon>
                    </button>
                </div>
                <div class="alignCenter" *ngIf="element.IsDeleted == false">
                    <div class="edit-mode" *ngIf="!element.IsEditing">
                        <button mat-icon-button (click)="startEdit(element)" title="Edit">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="deleteMessageCategory(element)" title="Delete">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <div class="edit-mode" *ngIf="element.IsEditing">
                        <button mat-icon-button (click)="editMessageCategory(element)" title="Update">
                            <mat-icon>update</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEdit(element)" title="Cancel">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-toolbar class="secondary">
        <mat-table [dataSource]="messageCategoryList" class="mat-elevation-z1">
            <ng-container matColumnDef="ContactCategoryName">
                <mat-footer-cell *matFooterCellDef> 
                    <mat-form-field>
                        <mat-label>Contact Category Name</mat-label>
                        <mat-select placeholder="Contact Category Name" [(ngModel)]="newMessageCategory.ContactCategoryId" (selectionChange)="ValidateMessageCategory()">
                            <mat-option *ngFor="let contactCategory of contactCategoryList" [value]="contactCategory.ContactCategoryId">
                                {{contactCategory.ContactCategoryName}}
                            </mat-option>
                        </mat-select>
                        <div class="error" *ngIf="newMessageCategory.MessageCategoryName.trim() != '' && !this.isValidContactCategory">
                            Please select
                        </div>
                    </mat-form-field>
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="MessageCategoryName">
                <mat-footer-cell *matFooterCellDef> 
                    <mat-form-field>
                        <mat-label>Message Category Name</mat-label>
                        <input matInput maxlength="50" [(ngModel)]="newMessageCategory.MessageCategoryName" (keyup)="ValidateMessageCategory()"> 
                        <div class="error" *ngIf="(this.newMessageCategory.ContactCategoryId != undefined || newMessageCategory.MessageCategoryName.trim() != '') && !isValidMessageCategoryName">
                            Unique name required
                        </div>
                    </mat-form-field>
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="DisplayOrder">
                <mat-footer-cell *matFooterCellDef> 
                    <mat-form-field>
                        <mat-label>Display Order</mat-label>
                        <input matInput [(ngModel)]="newMessageCategory.DisplayOrder"> 
                    </mat-form-field>                    
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="EditDelete">
                <mat-footer-cell *matFooterCellDef>
                    <div class="alignCenter">
                        <button mat-icon-button (click)="addMessageCategory(newMessageCategory)" title="Add Message Category" [disabled] = "!isValidMessageCategoryName || !this.isValidContactCategory">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelAdd()" title="Cancel">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </mat-footer-cell>
            </ng-container>

            <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
        </mat-table>
    </mat-toolbar>

</div>