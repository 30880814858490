// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material Modules
import { MaterialModule  } from 'kscigcorelib';

// Application Components
import { SettingsComponent } from './settings.component';
import { NotifyContactCategoriesComponent } from './notify-contact-categories/notify-contact-categories.component';
import { NotifyMessageCategoriesComponent } from './notify-message-categories/notify-message-categories.component';
import { MediaDefaultComponent } from './media-default/media-default.component';
import { NotifyGeneralComponent } from './notify-general/notify-general.component';
import { NotifyMessagesComponent } from './notify-messages/notify-messages.component';
import { NotifyMessageEditorComponent } from './notify-messages/notify-message-editor/notify-message-editor.component';
import { NotifyContactsComponent } from './notify-contacts/notify-contacts.component';
import { AppGeneralComponent } from './app-general/app-general.component';
import { NotifyStatsComponent } from './notify-stats/notify-stats.component';
import { UserSubscriptionsComponent } from './user-subscriptions/user-subscriptions.component';

@NgModule({
    declarations: [
        SettingsComponent,
        NotifyContactCategoriesComponent,
        NotifyMessageCategoriesComponent,
        MediaDefaultComponent,
        NotifyGeneralComponent,
        NotifyMessagesComponent,
        NotifyMessageEditorComponent,
        NotifyContactsComponent,
        AppGeneralComponent,
        NotifyStatsComponent,
        UserSubscriptionsComponent
    ],
    imports: [
        CommonModule,
        MaterialModule
    ]
})
export class SettingsModule { }
