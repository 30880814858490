// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, EnvService } from 'kscigcorelib';
import { HandleErrorService, ApiService  } from 'kscigcorelib';

// Application
import { IdleTimeoutSetting } from '../models/idletimeout.model'

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutSettingService {

  private idleTimeoutSubject = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
    ) { }


  enableIdleTimeout(pData: boolean) {
      this.idleTimeoutSubject.next(pData);      
  }

  getIdleTimeoutObservable(): Observable<any> {
    return this.idleTimeoutSubject.asObservable();
  }

  public getIdleTimeoutSetting(customerId: string, deviceId:string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiIdleTimeout/getIdleTimeoutSetting?ecid=' + this.apiService.secureApiQueryString(customerId) + "&edid=" + this.apiService.secureApiQueryString(deviceId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Idle Timeout Setting'))
    );
  }
    
  public setIdleTimeoutSetting(idleTimeoutSetting: IdleTimeoutSetting){    
    return this.http.post(this.envService.apiUrl + '/AppCoreApiIdleTimeout/setIdleTimeoutSetting', this.apiService.createApiRequestPayload(idleTimeoutSetting), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Set Idle Timeout Setting'))
    );
  }
  
}
