// Angular
//import {ValidatorFn, UntypedFormGroup} from '@angular/forms';

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ContactPointValidator(): ValidatorFn {
    
    return (control: AbstractControl): ValidationErrors | null => {
        const formGroup = control as FormGroup;
        const mobile = formGroup.get('mobile')?.value;
        const email = formGroup.get('email')?.value;
    
        if((mobile == null || mobile == '') && (email == null || email == '')){
          return { inValidContactPoint: true };
        } 
        return null;
  };
}
