// Angular
import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { UntypedFormBuilder, FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { formatDate } from '@angular/common';


// Material
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, Patient, EncryptionService } from 'kscigcorelib';


// Application
import { WorklistItem, WorklistItemViewModel } from '../../shared/models/worklist.interface';
import { WorklistService } from '../worklist.service';
import { SharedPatientService } from '../../shared/services/sharedpatient.service';
import { EMPTY } from 'rxjs';


@Component({
  selector: 'app-worklistitem-editor',
  templateUrl: './worklistitem-editor.component.html',
  styleUrls: ['./worklistitem-editor.component.css']
})

export class WorklistitemEditorComponent implements OnInit {

  public wlItemEditorForm: FormGroup;
  public wlItemData:WorklistItem;
  public locations: Location[];
  public isAdd: boolean;
  public customerId: string;
  public sessionId:string;

  constructor(
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private worklistService:WorklistService,    
    private patientService: SharedPatientService,
    private encryptionService: EncryptionService,
    private fb: UntypedFormBuilder,    
    public dialogRef: MatDialogRef<WorklistitemEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    @Inject(LOCALE_ID) public locale: string
  ) {

      this.wlItemData = this.inputData.wlItem;
      this.locations = this.inputData.locations;
      this.isAdd = this.inputData.isAddItem;
      this.customerId = this.inputData.customerId;
      this.sessionId = this.inputData.sessionId;
           
      let surgeonFN = ""; 
      let surgeonLN = "";

      if (this.wlItemData.Surgeon) {
        let surgeonList = this.wlItemData.Surgeon.split(",");

        if (surgeonList.length == 1) {
          if (this.wlItemData.Surgeon.startsWith(","))
            surgeonFN = surgeonList[0];
          else
            surgeonLN = surgeonList[0];
        } else if (surgeonList.length > 1) {
          surgeonFN = surgeonList[0];
          surgeonLN = surgeonList[1];
        }
      }
      
      this.wlItemEditorForm = new FormGroup({ 
        userAddedWorklistId: new FormControl(this.wlItemData.UserAddedWorklistId),
        patientId: new FormControl(this.wlItemData.PatId, [Validators.required]),
        firstName: new FormControl(this.wlItemData.FN, [Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]),
        middleName: new FormControl(this.wlItemData.MN, [Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]),
        lastName: new FormControl(this.wlItemData.LN, [Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]),
        gender: new FormControl(this.wlItemData.G, [Validators.required]),
        dob: new FormControl(this.wlItemData.DOB, [Validators.required]),
        location: new FormControl(this.wlItemData.Loc, [Validators.required]),
        surgeryDate: new FormControl(this.wlItemData.SurgeryDT ? new Date(this.wlItemData.SurgeryDT) : new Date(), [Validators.required]),
        procedure: new FormControl(this.wlItemData.Procedure),
        surgeonFirstName: new FormControl(surgeonFN),
        surgeonLastName: new FormControl(surgeonLN),
        accessionNumber: new FormControl(this.wlItemData.AN),
        // byUser: new FormControl(this.isAdd ? true : this.wlItemData.ByUser),
      }, { validators: [this.checkDobBeforeSurgeryDate()] });
    
   }

  
  ngOnInit(): void {
    
    this.loadPageData();
  }

  private loadPageData(){
    this.loggingService.logVerbose(this.wlItemData);
    if(this.wlItemData != null && this.wlItemData.PatId != null){
      this.loggingService.logVerbose("Edit WorklistItem");
      // this.wlItemData = this.inputData;
      // this.setPageData();
    } else {
      this.loggingService.logVerbose("New WorklistItem");
    }
  }
  
  saveWorklistItem() { 
                
    this.wlItemData.PatId = this.wlItemEditorForm.controls["patientId"].value;
    this.wlItemData.FN = this.wlItemEditorForm.controls["firstName"].value;
    this.wlItemData.MN = this.wlItemEditorForm.controls["middleName"].value;
    this.wlItemData.LN = this.wlItemEditorForm.controls["lastName"].value;
    this.wlItemData.G = this.wlItemEditorForm.controls["gender"].value;
    this.wlItemData.DOB = this.wlItemEditorForm.controls["dob"].value;
    this.wlItemData.Loc = this.wlItemEditorForm.controls["location"].value;
    this.wlItemData.SurgeryDT = formatDate(this.wlItemEditorForm.controls["surgeryDate"].value, 'yyyy-MM-dd H:mm', this.locale);
    this.wlItemData.Procedure = this.wlItemEditorForm.controls["procedure"].value;
    let lastname: string = this.wlItemEditorForm.controls["surgeonLastName"].value.trim();
    let firstName: string = this.wlItemEditorForm.controls["surgeonFirstName"].value.trim();
    if (firstName || lastname)
      this.wlItemData.Surgeon = lastname + ", " + firstName;
    else
      this.wlItemData.Surgeon = '';
    this.wlItemData.AN = this.wlItemEditorForm.controls["accessionNumber"].value;
    this.wlItemData.Loc = this.wlItemEditorForm.controls["location"].value;
    this.wlItemData.ByUser = this.isAdd ? true : this.wlItemData.ByUser;
    
    if (this.isAdd) {  
            
      this.worklistService.addPatient(this.customerId, this.wlItemData)
          .subscribe({
              next: (patientguid: string) => {
                  this.loggingService.logVerbose("Successfully added Patient Information");
                  this.notificationBarService.showSuccess("Successfully added Patient Information");
                  this.wlItemData.PatGId = patientguid;
                  this.dialogRef.close(true);
              },
              error: () => {
                  this.loggingService.logVerbose("Error adding Patient Information");
                  this.notificationBarService.showError("Error adding Patient Information");  
                  this.dialogRef.close(false);
              },
              complete: () => { this.loggingService.logVerbose("Completed adding Patient Information"); }
          });

    } else {
      this.worklistService.updatePatient(this.customerId, this.wlItemData)
          .subscribe({
              next: (isSaved: boolean) => {
                this.loggingService.logVerbose("Successfully updated Patient Information");
                this.notificationBarService.showSuccess("Successfully updated Patient Information");

                var currPatient = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getCurrentPatient()));
                if (currPatient != null && currPatient.PatientId == this.wlItemData.PatId) {
                  let patient = new Patient();
                      patient.PatientGuid = this.wlItemData.PatGId;
                      patient.PatientId = this.wlItemData.PatId;
                      patient.Name = this.wlItemData.FN + " " + (this.wlItemData.MN ? this.wlItemData.MN + " " : '' ) + this.wlItemData.LN;
                      patient.DOB = this.wlItemData.DOB;
                      patient.Gender = this.wlItemData.G;
                  this.patientService.setCurrentPatient(this.encryptionService.encryptUsingAES256(JSON.stringify(patient)));
                }
                this.dialogRef.close(true);
              },
              error: () => {
                this.loggingService.logVerbose("Error updating Patient Information");
                this.notificationBarService.showError("Error updating Patient Information"); 
                this.dialogRef.close(false); 
              },
              complete: () => { this.loggingService.logVerbose("Completed updating Patient Information"); }
          });
    }
    
  }
  

  public checkDobBeforeSurgeryDate(): ValidatorFn {
    return (fg: FormGroup): ValidationErrors | null => {

      const dob:any = fg.get('dob').value;
      const surgeryDate:any = fg.get('surgeryDate').value;
      
      if (dob != EMPTY && surgeryDate != 'Invalid Date' && dob && surgeryDate) {        
        const isDobBeforeSurgeryDt = new Date(dob).getTime() < new Date(surgeryDate).getTime();        
        if (isDobBeforeSurgeryDt) {          
          if (fg.get('dob').hasError('dobaftsurdate')) {                        
            fg.get('dob').setErrors(null);
          }
          if (fg.get('surgeryDate').hasError('dobaftsurdate')) {
            fg.get('surgeryDate').setErrors(null);
          }
          return null;
        }
        
        fg.get('dob').setErrors({dobaftsurdate: true});  
        fg.get('surgeryDate').setErrors({dobaftsurdate: true});  
      }
      return null;    
    };
  };

  
}
