<h2 mat-dialog-title class="DialogCaptionLabel">
  <span *ngIf="isAdd">Add</span><span *ngIf="!isAdd">Edit</span>&nbsp;Contact
</h2>
<mat-dialog-content>
  <div class="alignCenter">
    <mat-form [formGroup]="contactEditorForm">
      <div style="margin-left: -200px;">
        <mat-checkbox [checked]="isContactPreferred" (change)="onChangeIsContactPreferred($event)">Preferred Contact</mat-checkbox>
      </div>
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput type="text" placeholder="Ex. John" formControlName="firstName"/>
        <mat-error>
          <div *ngIf="firstName.hasError('required')">
            Required
          </div>
          <div *ngIf="firstName.hasError('maxlength')">
            50 chars only
          </div>
          <div *ngIf="firstName.hasError('pattern')">
            Only allowed chars (A-Z , . - ')
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Middle Name</mat-label>
        <input matInput type="text" placeholder="Ex. Smith" formControlName="middleName"/>
        <mat-error>
          <div *ngIf="middleName.hasError('maxlength')">
            50 chars only
          </div>
          <div *ngIf="middleName.hasError('pattern')">
            Only allowed chars (A-Z , . - ')
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput type="text" placeholder="Ex. Doe" formControlName="lastName"/>
        <mat-error>
          <div *ngIf="lastName.hasError('required')">
            Required
          </div>
          <div *ngIf="lastName.hasError('maxlength')">
            50 chars only
          </div>
          <div *ngIf="lastName.hasError('pattern')">
            Only allowed chars (A-Z , . - ')
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Alias</mat-label>
        <input matInput type="text" placeholder="Ex. Surgeon" formControlName="alias"/>
        <mat-error>
          <div *ngIf="alias.hasError('maxlength')">
            50 chars only
          </div>
          <div *ngIf="lastName.hasError('pattern')">
            Only allowed chars (A-Z , . - ')
          </div>
        </mat-error>
      </mat-form-field>      
      <br *ngIf="isPatientContact" />
      <mat-form-field *ngIf="isPatientContact">        
        <mat-label>Preferred Language</mat-label>
        <mat-select formControlName="language">
          <mat-option *ngFor="let language of contactEditorData.languageData" [value]="language.languageId">
            {{language.languageName}}
          </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="language.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-checkbox [checked]="isEmailPreferred" (change)="onChangeIsEmailPreferred($event)" style="margin-left: -140px;">Email Preferred</mat-checkbox>
      <br>
      <mat-form-field >
        <mat-label>Email</mat-label>
        <input type="email" matInput placeholder="Ex. mail@gmail.com" formControlName="email"/>
        <mat-icon matSuffix>mail_outline</mat-icon>
        <mat-error>
          <div *ngIf="email.hasError('email')">
            Invalid email
          </div>
        </mat-error>
        <mat-error style="font-size: 75%;">
          <div *ngIf="contactEditorForm.dirty && contactEditorForm.hasError('inValidContactPoint')">
            One contact point (email or mobile) is required
          </div>
        </mat-error> 
      </mat-form-field>        
      <br>
      <mat-checkbox [checked]="isSMSPreferred" (change)="onChangeIsSMSPreferred($event)" style="margin-left: -140px;">SMS Preferred</mat-checkbox>
      <br>
      <mat-form-field style="width: 120px;">
        <mat-label>Country</mat-label>
        <mat-select (selectionChange)="onChangeMobileCountryCode($event)" formControlName="countryCode">
          <mat-option *ngFor="let mobileCountryCode of contactEditorData.mobileCountryCodeData" [value]="mobileCountryCode.mobileCountryCodeId">
            {{mobileCountryCode.countryCode}}({{mobileCountryCode.countryNameCode}})
          </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="countryCode.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>      
      <mat-form-field style="width: 185px;">
        <mat-label>Mobile</mat-label>
        <input matInput type="text" placeholder="1112223333" formControlName="mobile" />
        <mat-icon matSuffix>phone</mat-icon>
        <mat-error>
          <div *ngIf="mobile.hasError('maxlength')">
            10 chars only
          </div>
          <div *ngIf="mobile.hasError('pattern')">
            Required format 1112223333
          </div>
          <div *ngIf="contactEditorForm.hasError('inValidContactPoint')">
            One contact point is required
          </div>
        </mat-error>
        <mat-error style="font-size: 75%;">
          <div *ngIf="contactEditorForm.dirty && contactEditorForm.hasError('inValidContactPoint')">
            One contact point (email or mobile) is required
          </div>
        </mat-error>
      </mat-form-field>      
      <br>
      <mat-form-field>
        <mat-label>Mobile Service Provider</mat-label>
        <mat-select formControlName="mobileProvider">
          <mat-option *ngFor="let mobileServiceProvider of mobileServiceProvidersToShow" [value]="mobileServiceProvider.mobileServiceProviderId">
              {{mobileServiceProvider.serviceProviderName}}
          </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="mobileProvider.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>
      
    </mat-form>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  
  <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(isDisableSave)">Cancel
    <mat-icon>cancel</mat-icon>
  </button>   
  <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" [disabled]="!contactEditorForm.dirty || isDisableSave" (click)="saveContact()">Save
    <mat-icon>save</mat-icon>
  </button>
  <div *ngIf="isPatientContact && isSendWelcomeText" style="margin-left:50px;text-align: right;" class="colorSuccessDark">
    {{sendWelcomeTextMessageStatus}}
  </div>
  <div *ngIf="isPatientContact && isSendWelcomeTextFailed" style="margin-left:50px; text-align: center;" class="colorError">
    {{sendWelcomeTextMessageFailed}}
  </div>
</mat-dialog-actions>