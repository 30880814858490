import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import {MatDialog as MatDialog, MatDialogRef as MatDialogRef, MatDialogState as MatDialogState} from '@angular/material/dialog';


import { EnumMediaType, MediaModel } from 'kscigcorelib';
import { LoadingBarService, LoggingService, NotificationBarService } from 'kscigcorelib';

import { ValidateInputDialogComponent } from '../validate-input-dialog/validate-input-dialog.component';

declare var amp: any;


@Component({
  selector: 'app-media-player-amp',
  templateUrl: './media-player-amp.component.html',
  styleUrls: ['./media-player-amp.component.css'] 
})
export class MediaPlayerAmpComponent implements OnInit, AfterViewInit {

  @ViewChild('videoPlayer') videoElementRef: ElementRef | undefined;
  @ViewChild('videoContainer') videoContainerRef: ElementRef | undefined;

  @Input() mediaUrl: string = '';
  @Input() posterUrl: string = '';
  @Input() startPlayPositionSeconds: number = 0;
  @Input() showSlideShow: boolean = false;
  @Input() slideShowImages: MediaModel[] = []; 
  @Input() mediaTypeId: number = 0;
  
  @Input() isPatientModeEnabled: boolean = false;
  @Input() patientModePassword: string = '';

  @Output() mediaPlayEvent = new EventEmitter();
  @Output() mediaPlayExitEvent = new EventEmitter<{playPositionSeconds: number, isPasswordValidated: boolean}>();

  videoElement: HTMLVideoElement | undefined;
  videoContainerElement: HTMLDivElement | undefined;
  ampPlayer: any;
  slideShowInProgress: boolean = false;
  
  dialogRef: MatDialogRef<ValidateInputDialogComponent>;



  constructor(
    private loggingService:LoggingService,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {
      this.loadAmpStylesheet();
   }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {

    this.loadAmpScript().then(() => {
      this.videoElement = this.videoElementRef?.nativeElement;
      this.videoContainerElement = this.videoContainerRef?.nativeElement;
      
      this.setUpAndPlayAmp();
      this.handleBrowserBackNavigation();     
    });
        
  }

  
  private loadAmpScript() {
    var promise = new Promise((resolve, reject) => {
      const scrpt = this.renderer.createElement('script');
      scrpt.src = '../../../../assets/scripts/azuremediaplayer.min.js';
      scrpt.type = 'text/javascript';
      scrpt.async = false;
      this.renderer.appendChild(document.head, scrpt);
      scrpt.onload = resolve;
    });
    return promise;    
  }

  private loadAmpStylesheet() {
    const ssheet = this.renderer.createElement('link');
    ssheet.href = '../../../../assets/styles/azuremediaplayer.min.css';
    ssheet.rel = 'stylesheet';
    this.renderer.appendChild(document.head, ssheet);
  }

  /* On browser back navigation logout in Patient Mode
  */
  private handleBrowserBackNavigation() {
    
    if (this.isPatientModeEnabled) {
      this.loggingService.logDebug("Patient Mode is enabled. Restricting browser back navigation event.");

      window.onpopstate = (event) => {
        this.pauseMedia();
        this.mediaPlayExitEvent.emit({playPositionSeconds: this.getPlayPositionSeconds(), isPasswordValidated: false});     
      };
    } else {
      this.loggingService.logDebug("Patient Mode is disabled. Allow normal browser back navigation");
    }    
  }

  setUpAndPlayAmp() {
    
      var options = { techOrder: ["azureHtml5JS", "html5", "html5FairPlayHLS", "silverlightSS"], logo: { "enabled": false } }
      this.ampPlayer = amp(this.videoElement, options);
      this.ampPlayer.src([
        {          
          src: this.mediaUrl,          
          type: "application/vnd.ms-sstr+xml"
        }
      ]);
      
      this.ampPlayer.addEventListener('fullscreenchange', (event) => { 
        this.loggingService.logVerbose("Video fullscreen changed");     
        if (!this.ampPlayer.isFullscreen()) { 
          this.loggingService.logVerbose("Media fullscreen exited");
          
          this.onMediaPlayExit();
        }
      });

      this.enterBrowserFullscreen();

      this.ampPlayer.addEventListener('loadeddata', (event) => {      
        this.ampPlayer.currentTime(this.startPlayPositionSeconds);                  
      });
      
      this.ampPlayer.addEventListener('play', (event) => {            
        
        if (this.mediaTypeId == EnumMediaType.Audio.valueOf()) { 
                
            if (this.showSlideShow && this.slideShowImages && this.slideShowImages.length > 0)
                this.startSlideShow();
            else {              
                document.getElementsByClassName("vjs-player")[0].setAttribute("style", "background-image: url('" + this.posterUrl + "');background-size:cover;background-repeat:no-repeat;");    
                document.getElementsByClassName("vjs-tech")[0].setAttribute("style", "z-index: -1");
            }
        }
        this.mediaPlayEvent.emit();     
      });

      this.ampPlayer.addEventListener('pause', (event) => {   
        if (this.mediaTypeId == EnumMediaType.Audio.valueOf()) { 
                
          if (this.showSlideShow && this.slideShowImages && this.slideShowImages.length > 0)
              this.stopSlideShow();
        }
      });

      // Handle full screen failure on page load
      if (this.isPatientModeEnabled && !document.fullscreenElement) {
        this.onMediaPlayExit();        
      }
  }

  private handleFullscreenFailure(error) {
    this.loggingService.logError(error);
    if (this.isPatientModeEnabled && !document.fullscreenElement) {
      this.onMediaPlayExit();        
    }
  }

  startSlideShow() {
    this.slideShowInProgress = true;
    this.animateSlides(0);
  }

  stopSlideShow() {
      this.slideShowInProgress = false;    
  }

  animateSlides(slideIndex: number) {    
    
    if (this.slideShowInProgress) {                  
        document.getElementsByClassName("vjs-player")[0].setAttribute("style", "background-image: url('" + this.slideShowImages[slideIndex].MediaUrl + "');background-size:cover;background-repeat:no-repeat;transition:background-image 5s cubic-bezier(0.42,0,0.58,1)");    
        document.getElementsByClassName("vjs-tech")[0].setAttribute("style", "z-index: -1");               
        slideIndex++;
        if (slideIndex >= this.slideShowImages.length) { slideIndex = 0 }
        setTimeout(() => {          
          this.animateSlides(slideIndex);
        }, 5000); // Change image every 5 seconds        
    }
  }

  private enterBrowserFullscreen() {
    // try/catch any promise rejection errors on fullscreen request
    try{
      this.ampPlayer.enterFullscreen(); //.catch((e: any) => { this.loggingService.logError(e); });       
    } catch(error) {
      this.loggingService.logError(error);
    }
  }

  private getPlayPositionSeconds(): number {
    return Number(this.ampPlayer.currentTime().toFixed());
  }

  private pauseMedia() {
    this.loggingService.logDebug("Pausing Media: " + this.getPlayPositionSeconds());
    this.ampPlayer.pause();   
  }

  private playMedia() {
    this.loggingService.logDebug("Playing Media");
    this.ampPlayer.play();
  }

  /* Pause media and save play position before exit.
     If Patient mode is on, then prevent exit from page, unless password is validated.
  */
  onMediaPlayExit() {
               
    this.pauseMedia(); 
    let playPositionSeconds: number = this.getPlayPositionSeconds();
    
    if (this.isPatientModeEnabled) {
      
      if (!this.dialogRef || this.dialogRef.getState() !== MatDialogState.OPEN) {

        this.dialogRef = this.dialog.open(ValidateInputDialogComponent, {
          data: {title: "Enter Password", message: "Enter password to exit", 
                  errorMessage: "Invalid password", inputLabel: "", validateInputWith: this.patientModePassword},
          disableClose: true,
          //closeOnNavigation: false,
          enterAnimationDuration: '1000ms',
          exitAnimationDuration: '500ms'
        });
          
        let isPatientModePasswordValidated: boolean = false;
        this.dialogRef.afterClosed().subscribe(result => {
          this.loggingService.logVerbose('The password dialog was closed');
          isPatientModePasswordValidated = result;
          if (isPatientModePasswordValidated)
            this.mediaPlayExitEvent.emit({playPositionSeconds: playPositionSeconds, isPasswordValidated: isPatientModePasswordValidated});
          else {
            this.enterBrowserFullscreen();
            this.playMedia();
          }
        });
      }
    } else {
      this.mediaPlayExitEvent.emit({playPositionSeconds: playPositionSeconds, isPasswordValidated: false});
    }
  }
  
}
