// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, EnvService } from 'kscigcorelib';
import { HandleErrorService, ApiService } from 'kscigcorelib';

 
@Injectable({
    providedIn: 'root'
})
export class NotifyStatsService {

    constructor(
      private http: HttpClient,
      private handleError: HandleErrorService,
      private apiService:ApiService,
      private envService: EnvService
    ) { }

    public getStats(customerId: string, timeFilter: string, notifyType:string): Observable<CloudApiResponse> {
        return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiMessage/GetMessageSendStats?ecid=' + this.apiService.secureApiQueryString(customerId) + "&time="+ timeFilter + "&cptype=" + notifyType, this.apiService.getCustomHeader())
        .pipe(
            catchError(this.handleError.handleError<any>('Get Stats', []))
        );
    }

}  