


<div id="videoContainer" #videoContainer >
    <video #videoPlayer id="videoPlayer" poster="{{posterUrl}}" controls="true" autoplay="true" class="azuremediaplayer amp-default-skin"  >
        
        <p class="amp-no-js">
            Your browser doesn't support HTML5 video
        </p>
    </video>
    
</div>
