import { Contact, CustomerLanguagePreference, SMSProvider } from 'kscigcorelib';
import { EmailSendType, SMSSendType, OptInStatusType, ContactType} from './notify.enum';

export class ContactCategory {
    ContactCategoryId:string;
    CustomerId: string;
    ContactCategoryName:string;
    IsDeletable: boolean;
    IsDeleted: boolean;

    constructor(){
        this.ContactCategoryId = null;
        this.CustomerId = null;
        this.ContactCategoryName = '';
        this.IsDeletable = false;
        this.IsDeleted = true;
    }
}

export class NotifyContact extends Contact {
    ContactCategoryId:string;
    LanguageId:string;
    LanguageName:string;
    MobileCountryCodeId:string;
    MobileCountryCode:string;
    MobileServiceProviderId:string;
    MobileServiceProviderName:string;
    IsContactPreferred:boolean;
    IsSMSPreferred:boolean;
    IsEmailPreferred:boolean;
    IsDeleted:boolean;
    ContactType:ContactType;
    
    constructor(contactId:string = null,
                firstName:string = null,
                middleName:string = null,
                lastName:string = null,
                alias:string = null,
                email:string = null,
                mobile: string = null){
            super();
            this.ContactCategoryId = null;
            this.LanguageId = null;
            this.LanguageName = '';
            this.MobileCountryCodeId = null;
            this.MobileCountryCode = '';
            this.MobileServiceProviderId = null;
            this.MobileServiceProviderName = '';
            this.IsContactPreferred = false;
            this.IsSMSPreferred = false;
            this.IsEmailPreferred = false;
            this.IsDeleted = false;
            this.ContactType = ContactType.None;
        }
}

export class GlobalContact extends NotifyContact {
    ContactCategoryId : string;

    constructor(contactCategoryId = null,
        languageId = null,
        mobileCountryCodeId = null,
        mobileServiceProviderId = null,
        isContactPreferred = false,
        isSMSPreferred = false,
        isEmailPreferred = false,
        isDeleted = false){
            super();
            this.ContactCategoryId = null;
        }
}

export class PatientContact extends GlobalContact {
    PatientGUID:string;
    IsUserAdded:boolean;
    OptInStatusEnumVal: OptInStatusType;
    constructor(OptInStatus: OptInStatusType = OptInStatusType.Unknown) {
        super();
        this.PatientGUID = null;
        this.IsUserAdded = false;
        this.OptInStatusEnumVal = OptInStatusType.Unknown;
    }
}

export class MobileCountryCode {
    mobileCountryCodeId: string;
    countryName:string;
    CountryNameCode:string;
    countryCode:string;
    isDeleted:boolean;
    constructor(){
            this.mobileCountryCodeId = null;
            this.CountryNameCode = '';
            this.countryName = '';
            this.countryCode = ''
            this.isDeleted = false;
        }
}

export class MobileServiceProvider {
    mobileServiceProviderId:string;
    mobileCountryCodeId: string;
    serviceProviderName:string;
    isDeleted:boolean;
    constructor(){
            this.mobileServiceProviderId = null;
            this.mobileCountryCodeId = null;
            this.serviceProviderName = '';
            this.isDeleted = false;
        }
}

export class ContactEditorData{
    customerId: string;
    contactData:NotifyContact;
    contactCategoryData:ContactCategory;
    mobileServiceProviderData:MobileServiceProvider[];
    mobileCountryCodeData:MobileCountryCode[];
    languageData:CustomerLanguagePreference[];
    contactOptInStatus:OptInStatusType;
    isOptInSMSEnabled:boolean;
    systemMessage:MessageLanguages;
    constructor(){
            this.customerId = "";
            this.contactData = new NotifyContact('', '', '','','', '','');
            this.contactCategoryData = new ContactCategory();
            this.mobileServiceProviderData = [];
            this.mobileCountryCodeData = [];
            this.languageData = [];
            this.contactOptInStatus = OptInStatusType.Unknown;
            this.systemMessage = new MessageLanguages();
            this.isOptInSMSEnabled = true; // Default is true for safety
        }
}

export class MessageCategory {
    MessageCategoryId: string;
    CustomerId: string;
    ContactCategoryId:string;
    MessageCategoryName: string;
    ContactCategoryName:string;
    DisplayOrder:number;
    IsSystem: boolean;
    IsDeleted: boolean;
    constructor(){
            this.MessageCategoryId = null;
            this.CustomerId = null;
            this.ContactCategoryId = null;
            this.MessageCategoryName = '';
            this.ContactCategoryName = '';
            this.DisplayOrder = 0;
            this.IsSystem = false;
            this.IsDeleted = true; 
        }
}

export class Message {
    MessageId: string;
    MessageCategoryId: string;
    MessageCategoryName: string;
    LanguageId:string;
    LanguageName:string;
    MessageText:string;
    IsDeleted: boolean;
    IsOldEditedEntry: boolean;

    constructor(){
            this.MessageId = null;
            this.MessageCategoryId = null;
            this.MessageCategoryName = '';
            this.LanguageId = null;
            this.LanguageName = '';
            this.MessageText = '';
            this.IsDeleted = false; 
            this.IsOldEditedEntry = false; 
        }
}

export class MessageLanguageText {
    languageId:string;
    messageText:string;
    constructor(){
            this.languageId = null;
            this.messageText = '';
        }
}

export class MessageLanguages {
    messageId: string;
    messageLanguageTexts:MessageLanguageText[];
    constructor(){
            this.messageId = null;
            this.messageLanguageTexts = [];
        }
}

export class EmailSendRequest {
    recipientList:PatientContact[];
    emailType:EmailSendType;
    message: string;
    subject: string;
    from: string
    constructor(){
        this.recipientList = [];
        this.emailType = EmailSendType.None;
        this.message = '';
        this.subject = '';
        this.from = '';
    }
}

export class EmailProviderResponse {
    Recipient:PatientContact;
    IsSent :boolean;
    ResponseMessage:string;
    EmailProviderId:string;
    constructor(){
        this.Recipient = new PatientContact();
        this.IsSent = false;
        this.ResponseMessage = '';
        this.EmailProviderId = null;
    }
}

export class EmailProviderResponses
{
    ResponseMessage:string
    IsSent:boolean
    EmailProviderResponse: EmailProviderResponse[] = [];

    constructor()
    {
        this.ResponseMessage = '';
        this.IsSent = false;
        this.EmailProviderResponse = [];
    }
}


export class EmailSendResponse {
    IsSent:Boolean;
    EmailProviderResponses: EmailProviderResponses[];
    constructor() {
        this.IsSent = false;
        this.EmailProviderResponses = [];
    }
}

export class SMSSendRequest {
    RecipientList: PatientContact[];
    SMSType:SMSSendType;
    Message:MessageLanguages;
    constructor() {
        this.RecipientList = [];
        this.SMSType = SMSSendType.None;
        this.Message = new MessageLanguages();
    }
}

export class SMSProviderResponse {
    Recipient: PatientContact;
    IsSent:boolean;
    ResponseMessage:string;
    SMSProviderId:string;
}

export class SMSProviderResponses {
    ResponseMessage:string;
    IsSent: boolean;
    SMSProviderResponse:SMSProviderResponse[];
}

export class SMSSendResponse {
    IsSent:boolean;
    SMSProviderResponses:SMSProviderResponses[];
}

export class MessageSendRequest {
    recipientList: PatientContact[];
    emailType: EmailSendType;
    smsType: SMSSendType;
    message: MessageLanguages;

    constructor(){
        this.recipientList = [];
        this.smsType = SMSSendType.None;
        this.emailType = EmailSendType.None;
        this.message = new MessageLanguages();
    }
}

export class MessageSendResponse {
    IsSent:boolean;
    EmailSendResponse:EmailSendResponse;
    SMSSendResponse:SMSSendResponse;
    constructor(){
        this.IsSent = false;
        this.EmailSendResponse = null;
        this.SMSSendResponse = null;
    }
}

export class MessageSendResultDetails{
    contactPoint:string;
    contactName:string;
    isSent:boolean;
    responseMessage:string;
    constructor(){
        this.isSent = false;
        this.contactPoint = '';
        this.responseMessage = '';
        this.contactName = '';
    }
}


export class MessageSendData {
    ContactPointType: string;
    MessageSendType: string;
    SentOn: Date;
    ContactPoint: string; // Phone number or Email
    IsSent: boolean;    
    Carrier: string;
    Provider: string;
    ErrorMessage: string;
}

export class UserMessageCategory {
    UserId: string;
    MessageCategoryId: string;
    
    constructor() {
        this.UserId = null;
        this.MessageCategoryId = null;        
    }
}

export class UserLocation {
    UserId: string;
    LocationId: string;

    constructor() {
        this.UserId = null;
        this.LocationId = null;        
    }
}

export class LocationViewModel {
    LocationId: number;
    Name: String;
    IsSelected: boolean;
    constructor() {
        this.LocationId = null;
        this.Name = null;
        this.IsSelected = true;
    }
}
