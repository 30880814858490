// Angular
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { EncryptionService } from 'kscigcorelib';

import { WorklistPref, Criteria, Location, WLTimeFilter, WLSMSConsentFilter } from '../models/worklist.interface'
import { SessionHelper } from '../helpers/session.helper';
import { KSUserPrefCookie } from '../models/cookie.model';



@Injectable({
    providedIn: 'root'
})
export class UserCookieService {

    private cookiePrefix: string = '_ksuserpref_';
    private cookieName: string = null;
    private ksUserPrefCookie: KSUserPrefCookie = null;


    constructor(
        private cookieService: CookieService,
        private sessionHelper: SessionHelper,
        private encryptionService: EncryptionService
        ) {
            this.initialize();
        }


    private initialize() {        
        // let isCookieLoaded = false;
        // // if user cookies exist, load current user's cookie
        // let cookiesArr:string[] = document.cookie.split("; ").filter((row) => row.startsWith(this.cookiePrefix));
        // cookiesArr.forEach((element) => { 
        //     let kvp_arr = element.split("=");
        //     var cookie_name = kvp_arr[0];            
        //     if (this.isCurrentUserCookie(cookie_name)) {
        //         this.cookieName = cookie_name;                    
        //         let cookie:string = this.cookieService.get(this.cookieName);                    
        //         this.ksUserPrefCookie = JSON.parse(cookie);
        //         isCookieLoaded = true;                    
        //     }
        // });
    
        this.setCookieName();
        // if cookie already exists load it, else create a default cookie
        if (this.cookieService.check(this.cookieName)) {
            let cookie:string = this.cookieService.get(this.cookieName);
            this.ksUserPrefCookie = JSON.parse(cookie);
        } else {
        //if (!isCookieLoaded) {
            
            this.ksUserPrefCookie = new KSUserPrefCookie();
            this.ksUserPrefCookie.wlPref = new WorklistPref();
            this.ksUserPrefCookie.wlPref.WLSortBy = "SurgeryDt";
            this.ksUserPrefCookie.wlPref.WLSortOrder = "asc";
            this.saveCookie();
        }
    }

    public setSortInfo(sortByCol: string, sortOrder: string) {
        this.ksUserPrefCookie.wlPref.WLSortBy = sortByCol;
        this.ksUserPrefCookie.wlPref.WLSortOrder = sortOrder;
        this.saveCookie();
    }


    public setCriteria(criteria: Criteria) {
        this.ksUserPrefCookie.wlPref.WLCriteria = criteria;
        this.saveCookie();
    }

    public setLocationFilter(loc: Location) {
        this.ksUserPrefCookie.wlPref.WLLocationFilter = loc;
        this.saveCookie();
    }

    public setTimeFilter(timeFilter: WLTimeFilter) {
        this.ksUserPrefCookie.wlPref.WLTimeFilter = timeFilter;
        this.saveCookie();
    }

    public setSMSConsentFilter(smsConsentFilter: WLSMSConsentFilter) {
        this.ksUserPrefCookie.wlPref.WLSMSConsentFilter = smsConsentFilter;
        this.saveCookie();
    }

    public setItemsPerPage(itemsPerPage: number) {
        this.ksUserPrefCookie.wlPref.WLItemsPerPage = itemsPerPage;
        this.saveCookie();
    }

    private saveCookie() {
        this.cookieService.set(this.cookieName, JSON.stringify(this.ksUserPrefCookie), 365, "/");
    }

    public getWorklistPreference(): WorklistPref {
        return this.ksUserPrefCookie.wlPref;
    }

    private setCookieName() {
        this.cookieName = this.cookiePrefix + btoa(this.encryptionService.encryptUsingAES256(this.getUser()));
    }

    private isCurrentUserCookie(cookieName: string) {
        let extractedUsr:string = '';
        if (cookieName) {
            var encodedStr = cookieName.substring(this.cookiePrefix.length);
            extractedUsr = this.encryptionService.decryptUsingAES256(atob(encodedStr));            
        }
        return extractedUsr == this.getUser();
    }

    private getUser():string {
        let username: string = '';

        var loggedInUser = this.sessionHelper.getLoggedInUser();
        if (loggedInUser != null) {
            var userEmail = loggedInUser.Email;
            username = userEmail.substring(0, userEmail.indexOf("@"));
        }
        return username;
    }
}