// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse } from 'kscigcorelib';
import { HandleErrorService, ApiService, EnvService } from 'kscigcorelib';

// Component
import { PatientContact, MessageSendRequest } from './notify-model';


@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
    ) { }

  public getMessageCategory(customerId: string, userOnly: boolean): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiMessageCategory?ecid=' + this.apiService.secureApiQueryString(customerId) + "&userOnly=" + userOnly, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get MessageCategory data', []))
    );
  }

  public getMessage(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiMessage?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Message data', []))
    );
  }

  public getContactCategories(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiContactCategory?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get ContactCategory data', []))
    );
  }

  public getSelfContactForPatients(customerId: string, patientGuids:string[]): Observable<CloudApiResponse> {
    return this.http.post<CloudApiResponse>(this.envService.apiUrl + '/WorklistApiPatientContact/getSelfForPats?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(patientGuids, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get PatientContact data', []))
    );
  }

  public getPatientContacts(customerId: string, patientGuId:string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/WorklistApiPatientContact/all?ecid=' + this.apiService.secureApiQueryString(customerId) + '&epid=' + this.apiService.secureApiQueryString(patientGuId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get PatientContact data', []))
    );
  }

  public addPatientContact(customerId: string, contact: PatientContact) {
    return this.http.post(this.envService.apiUrl + '/WorklistApiPatientContact/add?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(contact, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Add Patient Contact data', []))
    );
  }

  public updatePatientContact(customerId: string, contact: PatientContact) {
    return this.http.put(this.envService.apiUrl + '/WorklistApiPatientContact/update?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(contact, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Update Patient Contact data', []))
    );
  }

  public deletePatientContact(customerId: string, contactId: string) {
    return this.http.delete(this.envService.apiUrl + '/WorklistApiPatientContact/delete?ecid=' + this.apiService.secureApiQueryString(customerId) + "&epcid=" + this.apiService.secureApiQueryString(contactId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Delete Patient Contact data', []))
    );
  }

  public getGlobalContacts(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiGlobalContact?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get GlobalContact data', []))
    );
  }

  // Send Message
  public sendMessage(customerId: string, messageSendRequest: MessageSendRequest): Observable<CloudApiResponse> {
    return this.http.post<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiMessage/Send?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(messageSendRequest, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Post Send Message', []))
    );
  }

  public getOptInStatus(customerId: string, contactId:string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiMessage/GetOptInStatus?ecid=' + this.apiService.secureApiQueryString(customerId) + '&econtid=' + this.apiService.secureApiQueryString(contactId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get OptInStatus data', []))
    );
  }

  public sendOptInTextToPatients(customerId: string, patientGuids:string[]): Observable<CloudApiResponse> {
    return this.http.post<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiMessage/sendWelcomeTextToPats?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(patientGuids, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Send OptInTextToPatients', []))
    );
  }
  
}
