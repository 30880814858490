// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, HandleErrorService, ApiService, EnvService } from 'kscigcorelib';

// Component
import { ContactCategory } from '../../notify/notify-model';
import { ContactCategoryViewModel } from './notify-contact-categories-view-model';


@Injectable({
  providedIn: 'root'
})
export class NotifyContactCategoriesService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
  ) { }

  public getAllContactCategories(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiContactCategory/all?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get all ContactCategory data', []))
    );
  }

  public addContactCategory(customerId: string, contactCategory: ContactCategory) {
    return this.http.post(this.envService.apiUrl + '/NotifyApiContactCategory/add?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(contactCategory, false), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Add ContactCategory data', []))
    );
  }

  public updateContactCategory(customerId: string, contactCategory: ContactCategoryViewModel) {
    return this.http.put(this.envService.apiUrl + '/NotifyApiContactCategory/update?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(contactCategory, false), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Update ContactCategory data', []))
    );
  }

  public deleteContactCategory(customerId: string, contactCategoryId: string) {
    return this.http.put(this.envService.apiUrl + '/NotifyApiContactCategory/delete?ecid=' + this.apiService.secureApiQueryString(customerId) + "&eccid=" + this.apiService.secureApiQueryString(contactCategoryId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Delete ContactCategory data', []))
    );
  }

  public undoDeleteContactCategory(customerId: string, contactCategoryId: string) {
    return this.http.put(this.envService.apiUrl + '/NotifyApiContactCategory/undo?ecid=' + this.apiService.secureApiQueryString(customerId) + "&eccid=" + this.apiService.secureApiQueryString(contactCategoryId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Undo Delete ContactCategory data', []))
    );
  }
}
