// Angular 
import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";

// Shared Lib
import { CoreHelper, coreAppPageMode } from 'kscigcorelib';

// Application 
import { applicationToolbar } from '../shared/constants/app.enum';
import { RouteHelper } from '../shared/helpers/route.helper';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  
  @Input() public isWorklistEnabled:boolean = false;
  @Input() public isNotifyEnabled:boolean = false;
  @Input() public isMediaEnabled:boolean = false;

  public coreAppPageModeEnum = coreAppPageMode;
  public coreAppPageMode = coreAppPageMode.none;
  public applicationToolbarEnum = applicationToolbar;
  public currentToolbarPage = applicationToolbar.none;
  public companyName:string = "";

  public btnworkliststate = 'btn-toolbar-default';
  public btnnotifystate = 'btn-toolbar-default';
  public btnmediastate = 'btn-toolbar-default';
  public btnmediaplaystate = 'btn-toolbar-default';

  constructor(
      private routeHelper: RouteHelper, 
      private coreHelper:CoreHelper,
      router: Router) { 

      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          // Set Toolbar
          this.setFooter();
        }
      });
  }

  ngOnInit() {
    // Set Toolbar
    this.setFooter();
  }

  private setFooter(){
    this.companyName = environment.companyName;
    this.coreAppPageMode = this.coreHelper.getApplicationPageMode();
    this.currentToolbarPage = this.routeHelper.getCurrentToolBarPageName();
    
    this.btnworkliststate = 'btn-toolbar-default';
    this.btnnotifystate = 'btn-toolbar-default';
    this.btnmediastate = 'btn-toolbar-default';
    this.btnmediaplaystate = 'btn-toolbar-default';

    if(this.currentToolbarPage == applicationToolbar.worklist){
      this.btnworkliststate = 'btn-toolbar-active';
    } else if(this.currentToolbarPage == applicationToolbar.notify){
      this.btnnotifystate = 'btn-toolbar-active';
    } else if(this.currentToolbarPage == applicationToolbar.media){
      this.btnmediastate = 'btn-toolbar-active';
    } else if(this.currentToolbarPage == applicationToolbar.mediaPlay){
      this.btnmediaplaystate = 'btn-toolbar-active';
    }
  }
}
