export class MessageCategoryViewModel {
    RowEditMode?: boolean;
    IsEditing: boolean;
    MessageCategoryId: string;
    ContactCategoryId:string;
    ContactCategoryName:string;
    DisplayOrder:Number;    
    IsSystem: boolean;
    IsDeleted: boolean;
    MessageCategoryName: string;
    IsSelected: boolean;

    constructor(
        ){
            this.IsEditing = false;
            this.RowEditMode = false;
            this.MessageCategoryId = null;
            this.ContactCategoryId = null;
            this.ContactCategoryName = '';
            this.MessageCategoryName = '';
            this.DisplayOrder = 0;
            this.IsSystem = false;
            this.IsDeleted = true;
            this.IsSelected = true; 
        }
}