<body class="bodyBaseColor">
  <div class="app-grid" *ngIf="networkStatus"> 
    <app-header [isWorklistEnabled]="isWorklistEnabled" [isNotifyEnabled]="isNotifyEnabled" [isMediaEnabled]="isMediaEnabled"></app-header>
    <div class="app-grid-content" >
      <router-outlet></router-outlet>
    </div>
    <app-footer [isWorklistEnabled]="isWorklistEnabled" [isNotifyEnabled]="isNotifyEnabled" [isMediaEnabled]="isMediaEnabled"></app-footer>
  </div>
  <div class="app-grid" *ngIf="!networkStatus">
    <div></div>
    <div style="margin-left: 30%;">
      <h2> Oh Snap! Please check your internet connection. <mat-icon>wifi_off</mat-icon> </h2>
    </div>
    <div></div>
  </div>
  <noscript>
    <div style="text-align: center; color: #052850">
      <h2>JavaScript is not enabled!</h2>
    </div>
</noscript>
</body>