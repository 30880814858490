// Angular 
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
  })
export class DeviceDetectorService {

    constructor(        
    ) { }


    public isMobile(): boolean {
        return this.isIPhone() || this.isAndroidMobile();
    }

    public isTablet() {
        return this.isIPad() || this.isAndroidTablet(); 
    }

    public isIPad(): boolean {
        //var isTouchDevice = "ontouchend" in document;     - this is true for ipad. Leave this here for any issues that might arise due to apple updates
        return (navigator.userAgent.match(/iPad/g) != null || navigator.userAgent.match(/Intel Mac/g) != null) && navigator.maxTouchPoints > 1;
    }

    public isAndroidTablet(): boolean {
        return navigator.userAgent.match(/Android/g) != null && navigator.userAgent.match(/Mobile/g) == null;
    }

    public isAndroidMobile(): boolean {
        return navigator.userAgent.match(/Android/g) != null && navigator.userAgent.match(/Mobile/g) != null;            
    }

    public isIPhone(): boolean {
        return navigator.userAgent.match(/iPhone/g) != null;        
    }

    public isBrowserChrome() {
        return (navigator.userAgent.indexOf("Safari") == -1) &&
                (navigator.userAgent.indexOf("Chrome") != -1);
    }

    public isBrowserSafari() {
        return (navigator.userAgent.indexOf("Safari") != -1) &&
                (navigator.userAgent.indexOf("Chrome") == -1);
    }

    public isPlatformMac() {
        return navigator.platform.toLowerCase().indexOf('mac') != -1;
    }
}
