// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, CustomerMediaData, EnvService, PatientModeAdminConfig, PatientModeSetting } from 'kscigcorelib';
import { HandleErrorService, ApiService  } from 'kscigcorelib';

// Application


@Injectable({
  providedIn: 'root'
})
export class PatientModeService {

  private patientModeSubject = new Subject<boolean>();
  
  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
    ) { }


  enablePatientMode(pData: boolean) {
    this.patientModeSubject.next(pData);            
  }

  getPatientModeObservable(): Observable<any> {
    return this.patientModeSubject.asObservable();
  }
  

  //#region Api Calls 

  public getPatientModeSetting(customerId: string, deviceId: string, isTablet: boolean): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiPatientMode/getPatientModeSetting?ecid=' + this.apiService.secureApiQueryString(customerId) + "&edid=" + this.apiService.secureApiQueryString(deviceId) + "&isTablet=" + isTablet, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Patient Mode setting'))
    );
  }
  
  public getPatientModeAdminConfig(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiPatientMode/getAdminConfig?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Patient Mode admin config'))
    );
  }

  public setPatientModeSetting(patientModeSetting: PatientModeSetting){
    return this.http.post(this.envService.apiUrl + '/AppCoreApiPatientMode/setPatientModeSetting', this.apiService.createApiRequestPayload(patientModeSetting), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('PatientModeTrigger'))
    );
  }

  public setPatientModeAdminConfig(patientModeAdminConfig: PatientModeAdminConfig){
    return this.http.put(this.envService.apiUrl + '/AppCoreApiPatientMode/setAdminConfig', this.apiService.createApiRequestPayload(patientModeAdminConfig), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('PatientModeAdminConfig'))
    );
  }

  //#endregion Api Calls
}
