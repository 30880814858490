import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Patient } from 'kscigcorelib';

 
@Injectable({
    providedIn: 'root'
  })
export class SharedPatientService {
    private patientSubject = new Subject<string>();
    private currentPatient: string;
 
    private bulkNotifyPatients: string; // Patient Guids

    //#region Selected Patient
    
    setCurrentPatient(pData: string) {
        this.patientSubject.next(pData);
        this.currentPatient = pData;
    }
 
    clearCurrentPatient() {
        this.currentPatient = null;
        this.patientSubject.next(this.currentPatient);        
    }
 
    getCurrentPatientObservable(): Observable<any> {
        return this.patientSubject.asObservable();
    }

    getCurrentPatient() {
        return this.currentPatient;
    }

    //#endregion Selected Patient

    //#region Bulk Notify Patient selection

    setBulkNotifyPatients(pData: string) {
        this.bulkNotifyPatients = pData;
    }
 
    clearBulkNotifyPatients() {        
        this.bulkNotifyPatients = null;           
    }
     
    getBulkNotifyPatients() {
        return this.bulkNotifyPatients;
    }

    //#endregion Bulk Notify Patient selection
}