export class ContactCategoryViewModel {
    RowEditMode?: boolean;
    IsEditing: boolean;
    ContactCategoryId:string;
    ContactCategoryName:string;
    IsDeletable:  boolean;
    IsDeleted:  boolean;

    constructor() {
        this.RowEditMode = false;
        this.IsEditing = false;
        this.ContactCategoryId = null;
        this.ContactCategoryName = '';
        this.IsDeletable = false;
        this.IsDeleted = false;
    }
}
