import { Component, OnInit } from '@angular/core';

// Shared Lib
import { CloudApiResponse, EncryptionService, EnumMediaType, PatientModeAdminConfig, SessionService } from 'kscigcorelib';
import { LoadingBarService, LoggingService, NotificationBarService } from 'kscigcorelib';
import { ApplicationConfig, EnumPatientModeTrigger, PatientModeSetting } from 'kscigcorelib';

// Application
import { SessionHelper } from '../../shared/helpers/session.helper';
import { PatientModeService } from '../../shared/services/patientmode.service';
import { IdleTimeoutSetting } from '../../shared/models/idletimeout.model';
import { IdleTimeoutSettingService } from '../../shared/services/idletimeoutsetting.service';
import { DeviceDetectorService } from '../../shared/services/devicedetector.service';
import { DeviceCookieService } from 'src/app/shared/services/ksdevicecookie.service';

@Component({
  selector: 'app-app-general',
  templateUrl: './app-general.component.html',
  styleUrls: ['./app-general.component.css']
})

export class AppGeneralComponent implements OnInit {

  public customerId:string;
  public isMediaModuleEnabled:boolean = false;
  public isNotifyModuleEnabled:boolean = false;  
  public patientModeSetting: PatientModeSetting = new PatientModeSetting();  
  public adminConfig: PatientModeAdminConfig = new PatientModeAdminConfig();
  public decryptedPassword: string = '';
  public idleTimeoutSetting: IdleTimeoutSetting = new IdleTimeoutSetting();
  public deviceIsTablet: boolean = false;
  public isAdminUser: boolean = false;
  public deviceId:string = '';

  constructor(
    private sessionHelper:SessionHelper,
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private patientModeService:PatientModeService,
    private idleTimeoutSettingService: IdleTimeoutSettingService,    
    private notificationBarService: NotificationBarService,
    private encryptionService: EncryptionService,
    private deviceService: DeviceDetectorService,
    private sessionService: SessionService,
    private deviceCookieService: DeviceCookieService
  ) { }

  ngOnInit(): void {
    
    this.loadingBarService.startBar(); 

    this.isMediaModuleEnabled = this.sessionHelper.isMediaModuleEnabled();
    this.isNotifyModuleEnabled = this.sessionHelper.isNotifyModuleEnabled();
        
    this.customerId = this.sessionHelper.getCustomerId();
    this.deviceIsTablet = this.deviceService.isTablet();
    this.deviceId = this.deviceCookieService.getDeviceId(true);

    if (this.isMediaModuleEnabled || this.isNotifyModuleEnabled) {
      this.patientModeSetting = this.sessionHelper.getPatientModeSetting();
      this.isAdminUser = this.sessionHelper.isAdminRole(); 
      
      if (this.isAdminUser) {
        this.decryptedPassword = this.sessionHelper.getPatientModeAdminPassword(true);
        this.adminConfig.CustomerId = this.sessionHelper.getCustomerId();
        this.adminConfig.Password = this.sessionHelper.getPatientModeAdminPassword(false);        
      }
    }

    this.loadPageData();
  }

  private loadPageData(){
    if (this.isAdminUser) {
      this.loadIdleTimeoutSetting();
    }
  }


  loadIdleTimeoutSetting() {
    this.loggingService.logDebug("Loading Idle Timeout setting");

    this.idleTimeoutSetting = this.sessionHelper.getIdleTimeoutSetting();

    if (this.idleTimeoutSetting) {      
      this.loggingService.logDebug("Loaded Idle Timeout setting from session");
      this.loadingBarService.stopBar();
    } else {
      this.idleTimeoutSettingService.getIdleTimeoutSetting(this.customerId, this.deviceId)
        .subscribe({ 
            next: (idleTimeoutResult: CloudApiResponse) => {
                  this.loggingService.logVerbose(idleTimeoutResult.payload);
                  let idleTimeoutSettingPayload: IdleTimeoutSetting = idleTimeoutResult.payload;
                  
                  if (this.idleTimeoutSetting != null) {
                    this.idleTimeoutSetting =  idleTimeoutSettingPayload;
                    this.loggingService.logDebug("Successfully loaded Idle Timeout setting");
                    // update session  
                    this.sessionHelper.setIdleTimeoutSetting(idleTimeoutSettingPayload);         
                  }
                  this.loadingBarService.stopBar();  
                },
            error: () => { 
                  this.loggingService.logError("Error loading Idle Timeout setting"); 
                  this.notificationBarService.showError("Error loading Idle Timeout setting");
                },
            complete: () => { this.loggingService.logVerbose("Completed loading Idle Timeout setting"); }
        });
    }
  }

  savePatientModeSetting() {
    let isEnabled = this.patientModeSetting.IsEnabled;
    this.patientModeService.setPatientModeSetting(this.patientModeSetting)
      .subscribe({ 
          next: (cloudApiResult: CloudApiResponse) => {  
                let patientModeId: number = cloudApiResult.payload;
                if(patientModeId >= 0) {
                  this.patientModeSetting.PatientModeId = patientModeId; // set PatientModeId                  
                  this.patientModeService.enablePatientMode(isEnabled);
                  this.sessionHelper.setPatientModeSetting(this.patientModeSetting); // update session
                  let msg:string = "Patient mode " + (isEnabled ? "enabled" : "disabled");           
                  this.loggingService.logVerbose(msg);
                  this.notificationBarService.showSuccess(msg);
                } 
                
              },
          error: () => {
                  let msg:string = "Error " + (isEnabled ? "enabling" : "disabling") + " Patient mode";
                  this.loggingService.logVerbose(msg);
                  this.notificationBarService.showError(msg);  
              },
          complete: () => { this.loggingService.logVerbose("Completed loading Patient mode enabled/disabled status");}
      });
  }

  savePassword() {

    this.adminConfig.Password = this.encryptionService.encryptUsingAES256(this.decryptedPassword);
    this.patientModeService.setPatientModeAdminConfig(this.adminConfig)
      .subscribe({ 
          next: (isSaved: boolean) => {
                this.sessionHelper.setPatientModeAdminPassword(this.decryptedPassword);
                this.loggingService.logVerbose("Successfully Saved Patient mode password");
                this.notificationBarService.showSuccess("Successfully Saved Patient mode password");
              },
          error: () => {
                  this.loggingService.logError("Error Saving Patient mode password");
                  this.notificationBarService.showError("Error Saving Patient mode password");  
              },
          complete: () => { this.loggingService.logVerbose("Completed Saving Patient mode password"); }
      });
  }

  onIdleTimeoutStatusChanged() {
    let isTimeoutDisabled = this.idleTimeoutSetting.isTimeoutDisabled;
    this.idleTimeoutSettingService.setIdleTimeoutSetting(this.idleTimeoutSetting)
      .subscribe({
          next: (isSaved: boolean) => {  
                if(isSaved) {                  
                  this.idleTimeoutSettingService.enableIdleTimeout(!isTimeoutDisabled);
                  this.sessionHelper.setIdleTimeoutSetting(this.idleTimeoutSetting);                  
                  let msg:string = "Idle Timeout " + (isTimeoutDisabled ? "disabled" : "enabled");           
                  this.loggingService.logVerbose(msg);
                  this.notificationBarService.showSuccess(msg);
                }
              },
          error: () => {
                  let msg:string = "Error " + (isTimeoutDisabled ? "disabling" : "enabling") + " Idle Timeout";
                  this.loggingService.logError(msg);
                  this.notificationBarService.showError(msg);  
              },
          complete: () => { this.loggingService.logVerbose("Completed " + (isTimeoutDisabled ? "disabling" : "enabling") + " Idle Timeout"); }
      });
      this.sessionHelper.clearIdleTimeoutSetting();
  }

}
