<div class="overflowAuto" style="height: calc(100vh - 64px - 37px - 86px);">
    
    <div class="backgroundWhite borderBlue">
        <div class="CaptionLabel">Settings:</div>
        <div *ngFor="let config of customerNotifyConfigsViewModel">
            <div class="borderBottomBlue Padding10px config-content-grid" *ngIf="config.Name == 'IsSMSOptInEnabled'">
                <div>
                    <mat-icon>textsms</mat-icon>
                    <label style="margin-left: 20px;">{{config.DisplayName}}:</label>
                </div>
                <div>
                <mat-slide-toggle style="margin-left: 5px" 
                    [checked]="isSMSOptInEnabled" 
                    [(ngModel)]="isSMSOptInEnabled"
                    (change)="onChangeCustomerOptInPreference($event)"></mat-slide-toggle>
                </div>
            </div>
        </div>
        <div *ngFor="let config of customerNotifyConfigsViewModel">
            <div class="borderBottomDarkBlue Padding10px" *ngIf="config.Name != 'IsSMSOptInEnabled'">
                <div class="config-content-grid">
                    <div>
                        <label>{{config.DisplayName}}:</label>
                    </div>
                    <div>
                        <div *ngIf="!config.IsEditing" >{{config.Value}}</div>
                        <input *ngIf="config.IsEditing" type="text" [(ngModel)]="config.Value" style="width: 250px;"/>
                    </div>
                    <div>
                        <div class="edit-mode Caption" *ngIf="!config.IsEditing">
                            <button mat-icon-button (click)="startEdit(config)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div class="edit-mode Caption" *ngIf="config.IsEditing">
                            <button mat-icon-button (click)="editConfig(config)">
                                <mat-icon>update</mat-icon>
                            </button>
                            <button mat-icon-button (click)="cancelEdit(config)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>


    <div class="backgroundWhite">
        <div class="CaptionLabel">Automatic Message Settings:</div>
        <div>
            <div class="borderBottomBlue Padding10px " >                
                <span>
                <mat-checkbox style="width: 340px; margin-left: 22px" 
                    [checked]="isWelcomeTextEnabledConfig.Value == 'true'"                     
                    (change)="onWelcomeTextEnabled($event.checked)">Send Welcome Text to patient contacts</mat-checkbox>                    
                </span>
                <!-- <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
                <span>
                    <mat-form-field style="width: 180px;">
                        <mat-label>Hours prior to surgery</mat-label>
                        <mat-select (selectionChange)="hrsPriorToSurgeryChanged()" [(value)]="hrsPriorToSurgeryConfig.Value" [(ngModel)]="hrsPriorToSurgeryConfig.Value">
                            <mat-option value="24">24</mat-option>
                            <mat-option value="48">48</mat-option>
                            <mat-option value="72">72</mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>                
            </div>
            <div class="borderBottomBlue Padding10px " >                
                <span>
                <mat-checkbox style="width: 340px; margin-left: 22px" 
                    [checked]="isPreOpMessageEnabledConfig.Value == 'true'"                     
                    (change)="onPreOpMessageEnabled($event.checked)">Send Pre-Op Message to patient self</mat-checkbox>                    
                </span>
                <span>
                    <mat-form-field style="width: 180px;">
                        <mat-label>Days prior to surgery</mat-label>
                        <mat-select (selectionChange)="daysPriorToSurgeryChanged()" [(value)]="daysPriorToSurgeryConfig.Value" [(ngModel)]="daysPriorToSurgeryConfig.Value">
                            <mat-option value="3">3</mat-option>
                            <mat-option value="5">5</mat-option>                            
                        </mat-select>
                    </mat-form-field>
                </span>     
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>           
                <span>
                    <mat-form-field style="width: 500px;">
                        <mat-label>Select the pre-op message to be sent</mat-label>
                        <mat-select (selectionChange)="preOpMessageSelectionChanged()" [(value)]="preOpMsgIdConfig.Value" [(ngModel)]="preOpMsgIdConfig.Value">
                            <mat-option *ngFor="let msg of preOpMessageList" [value]="msg.MessageId">
                                {{msg.MessageText}}
                            </mat-option>                        
                        </mat-select>
                    </mat-form-field>
                </span>
                <div>
                    <label>Please note, if opt-in policy is enabled then the application will send a Welcome text via SMS, if patient opt-ins only then the pre-op message will be sent.<br />The application will send pre-op message via email if email is populated.</label>
                </div>
            </div>            
        </div>
    </div>
    
    <div class="backgroundWhite">
        <div class="CaptionLabel">Languages:</div>
        <div *ngFor="let languagePreference of languagePreferences">
            <div style="padding:10px;" class="borderBottomBlue">
                <mat-icon style="vertical-align: middle;">translate</mat-icon>
                <label style="margin-left: 5px;">{{languagePreference.languageName}}</label>
                <mat-slide-toggle [disabled]="languagePreference.isDefault" style="margin-left: 15px;" [checked]="languagePreference.isEnabledForNotify" [(ngModel)]="languagePreference.isEnabledForNotify" (change)="onChangeLanguagePreference($event, languagePreference.languageId)"></mat-slide-toggle>
            </div>
        </div>
    </div>
</div>