import { Component } from '@angular/core';
import {MatListModule, MatSelectionListChange, MatSelectionList} from '@angular/material/list';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, CloudApiResponse, EncryptionService, UserInfo } from 'kscigcorelib';

// Application Core
import { SessionHelper } from '../../shared/helpers/session.helper';

// Component
import { LocationViewModel, MessageCategory, UserLocation, UserMessageCategory } from '../../notify/notify-model';
import { UserSubscriptionsService } from './user-subscriptions.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { Location } from '../../shared/models/worklist.interface';
import { NotifyMessagesService } from '../notify-messages/notify-messages.service';
import { MessageCategoryViewModel } from '../notify-message-categories/notify-message-categories-view-model';


@Component({
  selector: 'app-user-subscriptions',
  templateUrl: './user-subscriptions.component.html',
  styleUrls: ['./user-subscriptions.component.css']
})
export class UserSubscriptionsComponent {

  public customerId:string;
  public domainUsers: UserInfo[] = [];
  public userMsgCategoryExclusion: UserMessageCategory[] = [];
  public userLocationExclusion: UserLocation[] = []  
  public messageCategories: MessageCategoryViewModel[] = [];
  public locations: LocationViewModel[] = [];
  public selectedUserId:string = "";

  constructor(
    private sessionHelper:SessionHelper,
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,    
    private encryptionService: EncryptionService,
    private userSubscriptionService:UserSubscriptionsService,
    private authService: AuthService,
    private worklistService: WorklistService,
    private notifyMessagesService: NotifyMessagesService
    ) { }

    ngOnInit() {
      if(this.sessionHelper.isValidUserSession()){
        this.loadingBarService.startBar()
        this.customerId = this.sessionHelper.getCustomerId();      
        
        this.loadPageData();
        var height = document.getElementById('usersPanel').offsetHeight;
        document.getElementById('userList').style.height = (height -5 - 37) + "px";
        document.getElementById('catList').style.height = (height/2 -4 - 37) + "px";
        document.getElementById('locList').style.height = (height/2 -4 - 37) + "px";
      }
    }
   
    public loadPageData(){
      this.loadingBarService.startBar();
      this.loadUsers();      
    }

    private loadUsers() {
      this.authService.getUsers(this.customerId)
        .subscribe({
            next: (usersResult: CloudApiResponse) => {
                  var decryptedPayload = this.encryptionService.decryptUsingAES256(usersResult.payload)
                  this.loggingService.logVerbose(decryptedPayload);
                  this.domainUsers = JSON.parse(decryptedPayload);
                },
            error: () => { this.loggingService.logError("Error getting Domain Users"); },
            complete: () => { this.loggingService.logVerbose("Completed getting Domain Users"); 
                              this.loadMessageCategories();
                              this.loadLocations();
                            }
        });
    }

    private loadLocations() {
      this.worklistService.getLocations(this.customerId, false)
        .subscribe({
            next: (locationsResult:CloudApiResponse) => {
                          var decryptedLocationsPayload = this.encryptionService.decryptUsingAES256(locationsResult.payload);
                          this.loggingService.logVerbose(decryptedLocationsPayload);
                          var locs:Location[] = JSON.parse(decryptedLocationsPayload);
                          locs.forEach(element => {                            
                              let loc = new LocationViewModel();
                              loc.LocationId = element.LocationId,
                              loc.Name = element.Name
                              this.locations.push(loc);
                          })       
                        },
            error: () => { this.loggingService.logError("Error getting Domain Users"); },
            complete: () => { this.loggingService.logVerbose("Completed getting Domain Users"); 
                               this.loadUserLocationExclusion();
                            }
        });
    }

    private loadMessageCategories() {
      this.notifyMessagesService.getAllMessageCategories(this.customerId)
        .subscribe({
            next: (messageCategoryResult: CloudApiResponse) => {
                  var decryptedCatPayload = this.encryptionService.decryptUsingAES256(messageCategoryResult.payload);
                  this.loggingService.logVerbose(decryptedCatPayload);
                  var messageCategory: MessageCategory[] = JSON.parse(decryptedCatPayload);
                  messageCategory.forEach(element => {
                    if(!element.IsDeleted && element.MessageCategoryName != "System") {
                      let msgCat = new MessageCategoryViewModel();
                      msgCat.MessageCategoryId = element.MessageCategoryId,
                      msgCat.MessageCategoryName = element.MessageCategoryName
                      this.messageCategories.push(msgCat);
                    }
                  })                  
                },
            error: () => { this.loggingService.logError("Error getting Domain Users"); this.loadingBarService.stopBar(); },
            complete: () => { this.loggingService.logVerbose("Completed getting Domain Users"); 
                               this.loadUserMsgCategoryExclusion();
                            }
        });
    }

    private loadUserMsgCategoryExclusion() {
      this.userSubscriptionService.getMessageCategorySubscription(this.customerId)
        .subscribe({
            next: (messageSendDataResult: CloudApiResponse) => {
                  var decryptedPayload = this.encryptionService.decryptUsingAES256(messageSendDataResult.payload)
                  this.loggingService.logVerbose(decryptedPayload);
                  let excl = JSON.parse(decryptedPayload); 
                  if (excl != null)
                    this.userMsgCategoryExclusion = excl;
                                    
                  if (this.domainUsers.length > 0) {
                    this.selectedUserId = this.domainUsers[0].UserId;
                    this.displayUserMsgCategorySubscription(this.selectedUserId);
                  }
                },
            error: () => { this.loggingService.logError("Error getting User Message Category Subscription"); this.loadingBarService.stopBar(); },
            complete: () => { this.loggingService.logVerbose("Completed getting User Message Category Subscription"); this.loadingBarService.stopBar(); }
        });
    }

    private loadUserLocationExclusion() {
      this.userSubscriptionService.getLocationSubscription(this.customerId)
        .subscribe({
            next: (messageSendDataResult: CloudApiResponse) => {
                  var decryptedPayload = this.encryptionService.decryptUsingAES256(messageSendDataResult.payload)
                  this.loggingService.logVerbose(decryptedPayload);
                  let excl = JSON.parse(decryptedPayload); 
                  if (excl != null)
                    this.userLocationExclusion = excl;
                    
                  if (this.domainUsers.length > 0) {
                    this.selectedUserId = this.domainUsers[0].UserId;
                    this.displayUserLocationSubscription(this.selectedUserId);
                  }
                },
            error: () => { this.loggingService.logError("Error getting User Location Subscription"); this.loadingBarService.stopBar(); },
            complete: () => { this.loggingService.logVerbose("Completed getting User Location Subscription"); this.loadingBarService.stopBar(); }
        });
    }
  
    private updateUserMsgCategorySubscription(userId: string, updatedMsgCatId:string, isSelected:boolean) {
            
      this.userSubscriptionService.updateUserMessageCategorySubscription(this.customerId, userId, updatedMsgCatId, isSelected)
        .subscribe({
            next: (messageSendDataResult: CloudApiResponse) => {                   
                  var result = messageSendDataResult.payload;
                  if (result) {
                    if (!isSelected) {
                      var excl = new UserMessageCategory();
                      excl.UserId = userId;
                      excl.MessageCategoryId = updatedMsgCatId;                      
                      this.userMsgCategoryExclusion.push(excl);
                    } else {
                      var index = this.userMsgCategoryExclusion.findIndex(m => m.UserId == userId && m.MessageCategoryId == updatedMsgCatId);
                      this.userMsgCategoryExclusion.splice(index, 1);
                    }
                  }                                    
                },
            error: () => { this.loggingService.logError("Error getting User Message Category Subscription"); this.loadingBarService.stopBar(); },
            complete: () => { this.loggingService.logVerbose("Completed getting User Message Category Subscription"); this.loadingBarService.stopBar(); }
        });
    }

    private updateUserLocationSubscription(userId: string, updatedLocId:string, isSelected:boolean) {
      this.userSubscriptionService.updateUserLocationSubscription(this.customerId, userId, updatedLocId, isSelected)
        .subscribe({
            next: (messageSendDataResult: CloudApiResponse) => {                   
                  var result = messageSendDataResult.payload;
                  if (result) {
                    if (!isSelected) {
                      var excl = new UserLocation();
                      excl.UserId = userId;
                      excl.LocationId = updatedLocId;                      
                      this.userLocationExclusion.push(excl);
                    } else {
                      var index = this.userLocationExclusion.findIndex(m => m.UserId == userId && m.LocationId == updatedLocId);                      
                      this.userLocationExclusion.splice(index, 1);                      
                    }                                        
                  }                                    
                },
            error: () => { this.loggingService.logError("Error getting User Location Subscription"); this.loadingBarService.stopBar(); },
            complete: () => { this.loggingService.logVerbose("Completed getting User Location Subscription"); this.loadingBarService.stopBar(); }
        });
    }
      
    public onUserSelectionChange(event: MatSelectionListChange) { 
      this.selectedUserId = event.options[0].value;
      
      this.displayUserLocationSubscription(this.selectedUserId);
      
      this.displayUserMsgCategorySubscription(this.selectedUserId);
    }

    /**
     * Displays selected user's Location subscription
     */
    private displayUserLocationSubscription(userId: string) {
      var selectedUserlocationExclusions = this.userLocationExclusion.filter(m => m.UserId == userId);
      if (selectedUserlocationExclusions != null && selectedUserlocationExclusions.length > 0) {
        this.locations.forEach(usrLoc => {
          var locId = usrLoc.LocationId.toString();
          var locExclusion = selectedUserlocationExclusions.find(m => m.LocationId == locId);
          usrLoc.IsSelected = locExclusion == undefined;          
        });
      } else {
        this.locations.forEach(usrLoc => {          
          usrLoc.IsSelected = true;
        });        
      }
    }

    /**
     * Displays Selected user's Message category subscription
     */
    private displayUserMsgCategorySubscription(userId: string) {
      var selectedUserMsgCatExclusions = this.userMsgCategoryExclusion.filter(m => m.UserId == userId);
      if (selectedUserMsgCatExclusions != null && selectedUserMsgCatExclusions.length > 0) {
        this.messageCategories.forEach(usrMsgCat => {
          var msgCatExclusion = selectedUserMsgCatExclusions.find(m => m.MessageCategoryId == usrMsgCat.MessageCategoryId);                    
          usrMsgCat.IsSelected = msgCatExclusion == undefined;
        });
      } else {
        this.messageCategories.forEach(usrMsgCat => {          
          usrMsgCat.IsSelected = true;
        });
      }
    }

    public onLocationSelectionChange(event: MatSelectionListChange) {
      var option = event.options[0];
      this.updateUserLocationSubscription(this.selectedUserId, option.value, option.selected); 
    }

    public onMsgCategorySelectionChange(event: MatSelectionListChange) {
      var option = event.options[0];
      this.updateUserMsgCategorySubscription(this.selectedUserId, option.value, option.selected);          
    }

    public applyLocationsToDept() {
      this.loadingBarService.startBar();
      this.userSubscriptionService.applyUserLocationSubscriptionToDept(this.customerId, this.selectedUserId)
        .subscribe({
            next: (messageSendDataResult: CloudApiResponse) => {                   
                  var result = messageSendDataResult.payload;
                  if (result) {
                    this.loadUserLocationExclusion();
                  } else {
                    this.loadingBarService.stopBar();
                  }                                   
                },
            error: () => { this.loggingService.logError("Error applying User Location Subscription to Dept"); },
            complete: () => { this.loggingService.logVerbose("Completed applying User Location Subscription to Dept");  }
        });
    }

    public applyMsgCategoriesToDept() {
      this.loadingBarService.startBar();
      this.userSubscriptionService.applyUserMessageCategorySubscriptionToDept(this.customerId, this.selectedUserId)
        .subscribe({
            next: (messageSendDataResult: CloudApiResponse) => {                   
                  var result = messageSendDataResult.payload;
                  if (result) {
                    this.loadUserMsgCategoryExclusion();
                  } else {
                    this.loadingBarService.stopBar();
                  }                                   
                },
            error: () => { this.loggingService.logError("Error applying User Message Category Subscription to Dept"); },
            complete: () => { this.loggingService.logVerbose("Completed applying User Message Category Subscription to Dept");  }
        });
    }
}
