// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

// Material
import { MatTabChangeEvent as MatTabChangeEvent } from '@angular/material/tabs'
import { MatDialog as MatDialog } from '@angular/material/dialog';

// Shared Lib
import { MessageBoxComponent, MessageBoxModel, MessageBoxType, CustomerLanguagePreference, CloudApiResponse } from 'kscigcorelib';
import { NotificationBarService, LoggingService, LoadingBarService, EncryptionService } from 'kscigcorelib';

// Application Core
import { RouteHelper } from '../../shared/helpers/route.helper';
import { SessionHelper } from '../../shared/helpers/session.helper';
import { NotifyGeneralService } from '../notify-general/notify-general.service';
   
// Component 
import { NotifyService } from '../../notify/notify.service'
import { NotifyContactsService } from './notify-contacts.service'
import { ContactCategory, NotifyContact, MobileCountryCode, MobileServiceProvider, ContactEditorData } from '../../notify/notify-model';
import { ContactEditorComponent } from './../../shared/components/contact-editor/contact-editor.component';
import { ActivityService } from 'src/app/activity/activity.service';
import { EnumUserAction } from 'src/app/activity/activity.enum'
import { SharedPatientService } from '../../shared/services/sharedpatient.service'


@Component({
  selector: 'app-notify-contacts',
  templateUrl: './notify-contacts.component.html',
  styleUrls: ['./notify-contacts.component.css']
})
export class NotifyContactsComponent implements OnInit {

  public customerId:string;
  public contactCategoryList: ContactCategory[] = [];
  public globalContactList:NotifyContact[] = [];
  public contactListToShow:NotifyContact[] = [];
  public selectedContactCategory:ContactCategory = null;
  public mobileCountryCodeList : MobileCountryCode[] = [];
  public mobileServiceProviderList : MobileServiceProvider[] = [];
  public languageList: CustomerLanguagePreference[] = [];
  public currentPatientGuid: string;

  constructor(
    private routeHelper:RouteHelper,
    private sessionHelper:SessionHelper,
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private encryptionService: EncryptionService,
    private notificationBarService: NotificationBarService,
    private notifyGeneralService: NotifyGeneralService,
    private notifyContactsService: NotifyContactsService,
    private notifyService:NotifyService,
    private activityService:ActivityService,
    private patientService: SharedPatientService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if(this.sessionHelper.isValidUserSession()){
      this.loadingBarService.startBar();
      this.customerId = this.sessionHelper.getCustomerId();
      // Load Page Data
      this.loadPageData();
    }
  }

  private loadPageData(){
    this.loadContactEditorData();
    this.loadContactsData();
    // get selected/current patient
    var currPatient = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getCurrentPatient()));
    if (currPatient != null) {
      this.currentPatientGuid = currPatient.PatientGuid;      
    }
  }

  private loadContactsData(){
    this.notifyService.getContactCategories(this.customerId)
      .subscribe({ 
          next: (contactCategorysResult: CloudApiResponse) => {
                var decryptedCatPayload = this.encryptionService.decryptUsingAES256(contactCategorysResult.payload);
                this.loggingService.logVerbose(decryptedCatPayload);
                var contactCategorys: ContactCategory[] = JSON.parse(decryptedCatPayload);
                if(contactCategorys != null && contactCategorys.length > 1){
                  //Populate Contact Types
                  this.populatecontactCategoryListToShow(contactCategorys);

                  this.notifyService.getGlobalContacts(this.customerId)
                    .subscribe({ 
                        next: (contactsResult: CloudApiResponse) => {                              
                              var decryptedContactPayload = this.encryptionService.decryptUsingAES256(contactsResult.payload);
                              var contacts: NotifyContact[] = JSON.parse(decryptedContactPayload);
                              this.loggingService.logVerbose(contacts);
                              
                              this.globalContactList = contacts;
                              if(contacts != null){
                                this.selectedContactCategory = this.contactCategoryList[0];
                                this.populateContactListToShow(this.selectedContactCategory.ContactCategoryId);
                              }
                              this.loadingBarService.stopBar(); 
                            },
                        error: () => { this.loggingService.logError("Error loading Global Contacts"); },
                        complete: () => { this.loggingService.logVerbose("Completed loading Global Contacts"); }
                    });
                } else {
                  this.loadingBarService.stopBar(); 
                }

              },
          error: () => { this.loggingService.logError("Error loading Contact Categories"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Contact Categories");}
      });
  }

  private loadContactEditorData(){
    this.notifyContactsService.getMobileCountryCode()
      .subscribe({
          next: (mobileCountryCodesResult: CloudApiResponse) => {
                this.loggingService.logVerbose(mobileCountryCodesResult);
                var mobileCountryCodes: MobileCountryCode[] = mobileCountryCodesResult.payload;
                this.mobileCountryCodeList = mobileCountryCodes;
              },
          error: () => { this.loggingService.logError("Error loading Mobile Country code"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Mobile Country code"); }
      });

    this.notifyContactsService.getMobileServiceProvider()
      .subscribe({
          next: (mobileServiceProvidersResult: CloudApiResponse) => {
                this.loggingService.logVerbose(mobileServiceProvidersResult);
                var mobileServiceProviders: MobileServiceProvider[] = mobileServiceProvidersResult.payload;
                this.mobileServiceProviderList = mobileServiceProviders;
              },
          error: () => { this.loggingService.logError("Error loading Mobile Service Provider"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Mobile Service Provider"); }
      });

    this.notifyGeneralService.getLanguages(this.customerId, true)
      .subscribe({ 
          next: (languagesResult: CloudApiResponse) => {
                this.loggingService.logVerbose(languagesResult);
                var languages: CustomerLanguagePreference[] = languagesResult.payload;
                this.languageList = languages;
              },
          error: () => { this.loggingService.logError("Error loading languages"); },
          complete: () => { this.loggingService.logVerbose("Completed loading languages");}
      });
  }

  private refreshGlobalContacts(){
    this.notifyService.getGlobalContacts(this.customerId)
      .subscribe({ 
          next: (contactsResult: CloudApiResponse) => {
                this.loggingService.logVerbose(contactsResult);
                var decryptedContactPayload = this.encryptionService.decryptUsingAES256(contactsResult.payload);
                var contacts: NotifyContact[] = JSON.parse(decryptedContactPayload);
                this.globalContactList = contacts;
                if(contacts != null){
                  this.populateContactListToShow(this.selectedContactCategory.ContactCategoryId);
                }
              },
          error: () => { this.loggingService.logError("Error loading Global Contacts"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Global Contacts"); }
    });
  }

  public openContactEditor(contact:NotifyContact){
    this.loggingService.logVerbose("Open Editor");
    this.loggingService.logVerbose(contact);
    let contactEditorData:ContactEditorData = new ContactEditorData();
    contactEditorData.customerId = this.customerId;
    contactEditorData.contactData = contact;
    contactEditorData.contactCategoryData = this.selectedContactCategory;
    contactEditorData.mobileCountryCodeData = this.mobileCountryCodeList;
    contactEditorData.mobileServiceProviderData = this.mobileServiceProviderList;
    contactEditorData.languageData = this.languageList;
    const dialogRef = this.dialog.open(ContactEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: contactEditorData,
      minWidth: '40vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.refreshGlobalContacts();
      }
    });
  }

  public contactCategoryChange(tabChangeEvent: MatTabChangeEvent):void{
    this.loggingService.logVerbose(tabChangeEvent.tab.textLabel);
    this.selectedContactCategory = this.contactCategoryList.find(x=>x.ContactCategoryId == tabChangeEvent.tab.textLabel);
    this.populateContactListToShow(tabChangeEvent.tab.textLabel);
  }

  private populatecontactCategoryListToShow(ContactCategorys:ContactCategory[]){
    let tempContactCategorys:ContactCategory[] = [];
    ContactCategorys.forEach((ContactCategory:ContactCategory) => {
      if(ContactCategory.IsDeletable == true)
      {
        tempContactCategorys.push(ContactCategory);
      }
    });
    this.contactCategoryList = tempContactCategorys;
  } 

  private populateContactListToShow(contactCategoryId: string){
    let tempContacts:NotifyContact[] = [];
    this.globalContactList.forEach((contact:NotifyContact) => {
      if(contact.ContactCategoryId == contactCategoryId)
      {
        contact.MobileServiceProviderName = this.mobileServiceProviderList.find(x=>x.mobileServiceProviderId == contact.MobileServiceProviderId).serviceProviderName;
        contact.MobileCountryCode = this.mobileCountryCodeList.find(x=>x.mobileCountryCodeId == contact.MobileCountryCodeId).countryCode;
        tempContacts.push(contact);
      }
    });
    this.contactListToShow = tempContacts;
  } 

  public deleteContact(contact:NotifyContact){
    this.loggingService.logInformation(contact);

    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to delete '" + contact.FirstName + " " + contact.LastName + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loggingService.logVerbose("Delete initiated");
        this.notifyContactsService.deleteGlobalContact(this.customerId, contact.ContactId)
          .subscribe({
              next: () => {
                    this.notificationBarService.showSuccess("Deleted " +  contact.FirstName);
                    this.loggingService.logInformation("contact " +  contact.FirstName + " has been deleted");
                    
                    this.refreshGlobalContacts();
                  },
              error: () => {
                    this.notificationBarService.showError("Error deleting " +  contact.FirstName);
                    this.loggingService.logError("Error deleting contact " +  contact.FirstName);
                  },
              complete: () => { this.loggingService.logVerbose("Completed deleting contact " +  contact.FirstName); }
                });
      } else {
        this.loggingService.logVerbose("Delete was cancelled");
      }
    });
  }

  
}