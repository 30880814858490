// Angular
import {ValidatorFn, UntypedFormGroup} from '@angular/forms';

export function ContactPointValidator(fg: UntypedFormGroup): any {
    
    if((fg.get('email').value == null || fg.get('email').value == '') 
        && (fg.get('mobile').value == null || fg.get('mobile').value == '')){
           return { inValidContactPoint: true };
    } else {
        return null;
    }
}
