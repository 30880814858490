<footer>
  <mat-toolbar *ngIf="coreAppPageMode === coreAppPageModeEnum.login || coreAppPageMode === coreAppPageModeEnum.logout || coreAppPageMode === coreAppPageModeEnum.error" style="padding:10px;">
    <mat-toolbar-row>
      <span style="flex: 1 1 auto;"></span>
      <img src="/assets/images/Logo.png" />
      <span style="flex: 1 1 auto;"></span>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-toolbar class="secondary" *ngIf="coreAppPageMode === coreAppPageModeEnum.appui" style="padding:10px;">
    <mat-toolbar-row>
      <span style="flex: 4 1 auto;"></span>
      <button mat-raised-button [routerLink]="'/worklist'" [ngClass]="btnworkliststate" *ngIf="isWorklistEnabled">
          <mat-icon>list_alt</mat-icon>
          <div>Worklist</div>
      </button>
      <span style="padding-left: 40px;" *ngIf="isNotifyEnabled"></span>
      <button mat-raised-button [routerLink]="'/notify'" [ngClass]="btnnotifystate" *ngIf="isNotifyEnabled">
          <mat-icon>message</mat-icon>
          <div>Notify</div>
      </button>
      <span  style="padding-left: 40px;" *ngIf="isMediaEnabled"></span>
      <button mat-raised-button [routerLink]="'/media'" [ngClass]="btnmediastate" *ngIf="isMediaEnabled">
          <mat-icon>local_movies</mat-icon>
          <div>Media</div>
      </button>
      <span  style="padding-left: 40px;" *ngIf="isMediaEnabled"></span>
      <button mat-raised-button [routerLink]="'/mediaplay'" [ngClass]="btnmediaplaystate" *ngIf="isMediaEnabled">
        <mat-icon>play_arrow</mat-icon>
        <div>Play</div>
    </button>
      <span style="flex: 4 1 auto;"></span>
    </mat-toolbar-row>
  </mat-toolbar>
</footer>