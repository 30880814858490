// Angular
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { KSDevicePrefCookie } from '../models/cookie.model';
import { EncryptionService } from 'kscigcorelib';

@Injectable({
    providedIn: 'root'
})
export class DeviceCookieService {
 
    private cookieName: string = '_ksdevicepref_';
    private ksCookie: KSDevicePrefCookie = new KSDevicePrefCookie();
 

    constructor(        
        private cookieService: CookieService,
        private encryptionService: EncryptionService
        ) { 
            this.initialize();            
        }


    private initialize() {
        // if cookie already exists load it, else create a default cookie
        if (this.cookieService.check(this.cookieName)) {
            let cookie:string = this.cookieService.get(this.cookieName);
            this.ksCookie = JSON.parse(cookie);
        } else {            
            this.ksCookie = new KSDevicePrefCookie();
            this.ksCookie.deviceId = this.encryptionService.encryptUsingAES256(window.crypto.randomUUID());            
            this.saveCookie();
        }
    }
 
    public setDeviceId(deviceId: string) {
        this.ksCookie.deviceId = this.encryptionService.encryptUsingAES256(deviceId);
        this.saveCookie();
    }
 
    public getDeviceId(decrypted: boolean):string {
        return decrypted ? this.encryptionService.decryptUsingAES256(this.ksCookie.deviceId) : this.ksCookie.deviceId;
    }

    private saveCookie() { 
        this.cookieService.set(this.cookieName, JSON.stringify(this.ksCookie), 365, "/");
    }
         
}