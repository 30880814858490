// Angular
import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';

// Shared Lib
import { CustomerInstallDetail, sessionStorageItem, AuthenticatedUserInfo, CustomerLanguagePreference, Patient, appRoles } from 'kscigcorelib';
import { LoggingService, SessionService, PatientModeSetting } from 'kscigcorelib';
import { MobileCountryCode, MobileServiceProvider } from 'src/app/notify/notify-model';
import { IdleTimeoutSetting } from '../models/idletimeout.model';

@Injectable({
  providedIn: 'root'
})

export class SessionHelper {
    constructor(
        private loggingService:LoggingService,
        private sessionService:SessionService,
        private msalService:MsalService,
    ){ }

    public setLoggedInUser(accountInfo:AuthenticatedUserInfo){
        this.sessionService.setLoggedInUser(JSON.stringify(accountInfo));
    }
    
    public getLoggedInUser():AuthenticatedUserInfo{
        var msalActiveUser:AccountInfo = this.getMSALActiveUser();
        var userAccount:AuthenticatedUserInfo = JSON.parse(this.sessionService.getLoggedInUser(true));
        if(msalActiveUser != null && userAccount != null && userAccount.Email != '' && userAccount.Email == msalActiveUser.username) {
            return userAccount;
        }
        return null;
    }
    public isValidUserSession():boolean{
        if(this.getCustomerId() != null && this.isUserLoggedIn() && this.sessionService.getSessionId(false) != null){
            return true;
        } else {
            return false;
        }
    }

    public isAdminRole():boolean{
        var isAdmin:boolean = false;
        var userInfo:AuthenticatedUserInfo = this.getLoggedInUser();
        if(userInfo && userInfo.Role == appRoles.appAdmin){
            isAdmin = true;
        }
        return isAdmin;
    }

    public isUserRole():boolean{
        var isAdmin:boolean = false;
        var userInfo:AuthenticatedUserInfo = this.getLoggedInUser();
        if(userInfo && userInfo.Role == appRoles.appUser){
            isAdmin = true;
        }
        return isAdmin;
    }

    public isValidUserRole():boolean{
        var isAdmin:boolean = false;
        var userInfo:AuthenticatedUserInfo = this.getLoggedInUser();
        if(userInfo && (userInfo.Role == appRoles.appUser || userInfo.Role == appRoles.appAdmin)){
            isAdmin = true;
        }
        return isAdmin;
    }

    private isUserLoggedIn(){
        var userAccount:AuthenticatedUserInfo = this.getLoggedInUser();
        if(userAccount != null){
            return true;
        } else {
            this.loggingService.logError("User not authenticated.");
        }
        return false;
    }

    
    private getMSALActiveUser():AccountInfo{
        let userAccount:AccountInfo = this.msalService.instance.getActiveAccount();
        if(userAccount != null){
            this.loggingService.logVerbose(userAccount);
            return userAccount;
        } else {
            this.loggingService.logDebug("No User is logged in.");
        }
        return null;
    }

    public setCustomerInstallDetails(customerInstallDetail:CustomerInstallDetail){
        this.sessionService.setStorageItem(sessionStorageItem.customerInfo, JSON.stringify(customerInstallDetail));
    }

    public getCustomerInstallDetails():CustomerInstallDetail{
        return JSON.parse(this.sessionService.getStorageItem(sessionStorageItem.customerInfo, true));
    }

    public getSessionId() : string {
        return this.sessionService.getSessionId(true);
    }

    public getCustomerId() : string{
        let customerId = null;
        var customerDetails:CustomerInstallDetail = this.getCustomerInstallDetails();
        if (customerDetails != null) {
            this.loggingService.logVerbose(customerDetails);
            customerId = customerDetails.CustomerId;
        }
        return customerId;
    }

    public isWorklistModuleEnabled():boolean{
        var customerInstallDetails = this.getCustomerInstallDetails();
        if(customerInstallDetails.IsWorklistEnabled){
            return true;
        } else {
            return false;
        }
    }

    public isNotifyModuleEnabled():boolean{
        var customerInstallDetails = this.getCustomerInstallDetails();
        if(customerInstallDetails.IsNotifyEnabled){
            return true;
        } else {
            return false;
        }
    }

    public isMediaModuleEnabled():boolean{
      if (this.isPremierMediaEnabled()) {
        return true;
      } else {
        return false;
      }
    }
    
    public isPremierMediaEnabled():boolean{
        var customerInstallDetails = this.getCustomerInstallDetails();
      if (customerInstallDetails.IsMediaPremierEnabled) {
        return true;
      } else {
        return false;
      }        
    }

    public setPatientSearchKeyword(searchKeyword:string) {
        this.sessionService.setStorageItem(sessionStorageItem.patientSearchKeyword, searchKeyword);
    }

    public clearPatientSearchKeyword() {
        this.sessionService.removeStorageItem(sessionStorageItem.patientSearchKeyword);
    }

    public getPatientSearchKeyword():string {
        return this.sessionService.getStorageItem(sessionStorageItem.patientSearchKeyword, true);
    }
    
    public setSelectedPatient(patient:Patient) {
        this.sessionService.setStorageItem(sessionStorageItem.patient, JSON.stringify(patient));
    }

    public getSelectedPatient():Patient {
        return JSON.parse(this.sessionService.getStorageItem(sessionStorageItem.patient, true));
    }

    public clearSelectedPatient() {
        this.sessionService.removeStorageItem(sessionStorageItem.patient);
    }

    public setBulkNotifyPatients(patients:string[]) {
        this.sessionService.setStorageItem(sessionStorageItem.bullkNotifyPatients, JSON.stringify(patients));
    }

    public getBulkNotifyPatients():string[] {
        return JSON.parse(this.sessionService.getStorageItem(sessionStorageItem.bullkNotifyPatients, true));
    }

    public clearBulkNotifyPatients() {
        this.sessionService.removeStorageItem(sessionStorageItem.bullkNotifyPatients);
    }

    public setNotifyLanguages(customerLanguagePreference:CustomerLanguagePreference[]) {
        this.sessionService.setStorageItem(sessionStorageItem.notifyLanguages, JSON.stringify(customerLanguagePreference));
    }

    public getNotifyLanguages():CustomerLanguagePreference[] {
        return JSON.parse(this.sessionService.getStorageItem(sessionStorageItem.notifyLanguages, true));
    }

    public setNotifyMobileCountryCode(mobileCountryCodes:MobileCountryCode[]) {
        this.sessionService.setStorageItem(sessionStorageItem.notifyMobileCountryCode, JSON.stringify(mobileCountryCodes));
    }

    public getNotifyMobileCountryCode():MobileCountryCode[] {
        return JSON.parse(this.sessionService.getStorageItem(sessionStorageItem.notifyMobileCountryCode, true));
    }

    public setNotifyMobileSvcProviders(mobileCountryCodes:MobileServiceProvider[]) {
        this.sessionService.setStorageItem(sessionStorageItem.notifyMobileSvcProviders, JSON.stringify(mobileCountryCodes));
    }

    public getNotifyMobileSvcProviders():MobileServiceProvider[] {
        return JSON.parse(this.sessionService.getStorageItem(sessionStorageItem.notifyMobileSvcProviders, true));
    }

    public setIdleTimeoutSetting(idleTimeoutSetting:IdleTimeoutSetting) {
        this.sessionService.setStorageItem(sessionStorageItem.idleTimeoutSetting, JSON.stringify(idleTimeoutSetting));
    }

    public getIdleTimeoutSetting():IdleTimeoutSetting {
        return JSON.parse(this.sessionService.getStorageItem(sessionStorageItem.idleTimeoutSetting, true));
    }

    public clearIdleTimeoutSetting() {
        this.sessionService.removeStorageItem(sessionStorageItem.idleTimeoutSetting);
    }
    
    public getIsPatientModeInUse():boolean {
        return this.sessionService.getStorageItem(sessionStorageItem.patientModeInUse, true) === "1";
    }

    public setIsPatientModeInUse(logout:boolean) {        
        let val:string = logout ? "1" : "0";
        this.sessionService.setStorageItem(sessionStorageItem.patientModeInUse, val);
    }

    public clearIsPatientModeInUse() {
        this.sessionService.removeStorageItem(sessionStorageItem.patientModeInUse);
    }

    public getPatientModeSetting():PatientModeSetting {
        return JSON.parse(this.sessionService.getStorageItem(sessionStorageItem.patientModeEnabled, true));
    }

    public setPatientModeSetting(patientMode:PatientModeSetting) {        
        this.sessionService.setStorageItem(sessionStorageItem.patientModeEnabled, JSON.stringify(patientMode));
    }

    public getPatientModeAdminPassword(decrypted:boolean):string {
        return this.sessionService.getStorageItem(sessionStorageItem.patientModePassword, true);
    }

    public setPatientModeAdminPassword(val:string) {        
        this.sessionService.setStorageItem(sessionStorageItem.patientModePassword, val);
    }
}
