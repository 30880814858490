export class WorklistItem
{
  UserAddedWorklistId: string;
  PatGId:string;  
  PatId:string;
    FN:string; 
    LN:string; 
    MN:string; 
    G:string; 
    DOB:string; 
    Loc:string; 
    Procedure:string; 
    SurgeryDT:string; 
    Surgeon:string; 
    AN:string; 
    ByUser :boolean;    
}

export class WorklistItemViewModel
{
    UserAddedWorklistId: string;
    PatGId:string;
    PatId:string;
    FN:string; 
    LN:string;
    MN:string; 
    PatientName:string;     
    G:string; 
    DOB:string; 
    Loc:string; 
    Procedure:string; 
    SurgeryDT:string; 
    Surgeon:string; 
    AN:string; 
    ByUser :boolean;    
}

export interface Criteria {
  criteriaId:number;
  criteriaName:String;
}

export class Location {
  LocationId:number;
  Name:String;
}

export class WLSMSConsentFilter {
  WLSMSConsentFilterId:number;
  FilterNumber:number;
  FilterName:string;
}

export class WLTimeFilter {
  WLTimeFilterId:number;
  FilterNumber:number;
  FilterName:string;
}

export class WorklistPref {
  WLSortBy: string;
  WLSortOrder: string;
  WLCriteria: Criteria;
  WLLocationFilter: Location;
  WLTimeFilter: WLTimeFilter;
  WLSMSConsentFilter: WLSMSConsentFilter;
  WLItemsPerPage: number;
    
  constructor() {
    
    this.WLSortBy='';
    this.WLSortOrder='asc';
    this.WLCriteria = null;
    this.WLLocationFilter = null;
    this.WLTimeFilter = null;
    this.WLSMSConsentFilter = null;  
    this.WLItemsPerPage = 15;  
  }
}

export class SessionPatient {
  sessionId:string;
  patientGuid:string;
}