// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { HandleErrorService, ApiService, EnvService } from 'kscigcorelib';
import { CloudApiResponse } from 'kscigcorelib';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService:ApiService,
    private envService: EnvService
  ) { }

  public getUtilizationGraphData(customerId:string, startDate:string, endDate:string): Observable<any> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiUtilization/GetGraphData?ecid=' + this.apiService.secureApiQueryString(customerId) + "&startdate=" + startDate + "&enddate=" + endDate, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getUtilizationGraphData', []))
    );
  }

  public getUtilizationDrillDownData(customerId:string, startDate:string, endDate:string, keyword:string): Observable<any> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiUtilization/GetDrillDownData?ecid=' + this.apiService.secureApiQueryString(customerId) + "&startdate=" + startDate + "&enddate=" + endDate + "&keyword=" + keyword, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getUtilizationDrillDownData', []))
    );
  }
}