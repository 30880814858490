export enum EmailSendType{
    None = 0,
    NotifyModule_Regular = 1,
    AppNotification_General = 2,
    AppNotification_Jobs = 3,
    AppNotification_Usage = 4,
    
}

export enum SMSSendType{
    None = 0,
    AppNotification_General = 1,
    NotifyModule_Regular = 2,
    NotifyModule_OptInText = 3,
    NotifyModule_Bulk_OptInText = 4,
    NotifyModule_Bulk_Regular_Bulk = 5,
    NotifyModule_Auto_OptInText = 6,
    NotifyModule_Auto_PreOp = 7, 
}

export enum OptInStatusType
{
    Verifying = -1, // Loading
    Unknown = 0, // Gray
    OptInMessageNotSent = 1, // Black
    OptInMessageSent = 2, // Orange
    ContactOptedIn = 3, // Green
    ContactOptedOut = 4 // Red

}

export enum ContactType
{
    None = 0,
    Patient = 1,
    Global = 2
}