<h2 mat-dialog-title class="DialogCaptionLabel">Message Editor</h2>
<mat-dialog-content>
  <div>
    <div class="CaptionLabelSquare">Message (no PHI & 160 chars only):<span class="colorRed">*</span></div>
    <mat-form-field style="width: 100%;height: 25vh;">
      <textarea matInput class="notify-textBox contentBox backgroundWhite" 
          placeholder="Type message here" maxlength="160" 
          style="width: 100%;height: 25vh;"
          [(ngModel)]="formMessageText">{{formMessageText}}
      </textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="alignCenter">
  <button mat-raised-button mat-dialog-close class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">
    Cancel
    <mat-icon>cancel</mat-icon>
  </button>   
  <button mat-raised-button class="btn-icon-default" style="margin-left:40px; margin-right: 5px;" (click)="saveMessage()" [disabled]="formMessageText == ''">
    Save
    <mat-icon>save</mat-icon>
  </button>
  
</mat-dialog-actions>