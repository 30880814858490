import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, HandleErrorService, ApiService, EnvService } from 'kscigcorelib';

// Component
import { Message } from '../../notify/notify-model';


@Injectable({
  providedIn: 'root'
})
export class NotifyMessagesService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService:ApiService,
    private envService: EnvService
  ) { }
  
  public getAllMessageCategories(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiMessageCategory/all?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get all MessageCategory data', []))
    );
  }

  public getMessage(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiMessage/all?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Message data', []))
    );
  }
  
  public addMessage(customerId: string, message: Message): Observable<CloudApiResponse> {
    return this.http.post(this.envService.apiUrl + '/NotifyApiMessage/add?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(message, false), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Add Message data', []))
    );
  }

  public updateMessage(customerId: string, message: Message): Observable<CloudApiResponse> {
    return this.http.put(this.envService.apiUrl + '/NotifyApiMessage/update?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(message, false), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Update Message data', []))
    );
  }

  public deleteMessage(customerId: string, messageId: string): Observable<CloudApiResponse> {
    return this.http.put(this.envService.apiUrl + '/NotifyApiMessage/delete?ecid=' + this.apiService.secureApiQueryString(customerId) + "&emid=" + this.apiService.secureApiQueryString(messageId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Delete Message data', []))
    );
  }

  public undoDeleteMessage(customerId: string, messageId: string): Observable<CloudApiResponse> {
    return this.http.put(this.envService.apiUrl + '/NotifyApiMessage/undo?ecid=' + this.apiService.secureApiQueryString(customerId) + "&emid=" + this.apiService.secureApiQueryString(messageId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Undo delete Message data', []))
    );
  }
}
