// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, HandleErrorService, ApiService, EnvService } from 'kscigcorelib';

import { MessageCategory } from '../../notify/notify-model';
import { MessageCategoryViewModel } from './notify-message-categories-view-model';


@Injectable({
  providedIn: 'root'
})
export class NotifyMessageCategoriesService {

  constructor(
        private http: HttpClient,
        private handleError: HandleErrorService,
        private apiService:ApiService,
        private envService: EnvService
        ) { }

  public getAllMessageCategories(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiMessageCategory/all?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get all MessageCategory data', []))
    );
  }

  public addMessageCategory(customerId: string, messageCategory: MessageCategory) {
    return this.http.post(this.envService.apiUrl + '/NotifyApiMessageCategory/add?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(messageCategory, false), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Add MessageCategory data', []))
    );
  }
  
  public updateMessageCategory(customerId: string, messageCategory: MessageCategoryViewModel) {
    return this.http.put(this.envService.apiUrl + '/NotifyApiMessageCategory/update?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(messageCategory, false), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Update MessageCategory data', []))
    );
  }
  
  public deleteMessageCategory(customerId: string, messageCategoryId: string) {
    return this.http.put(this.envService.apiUrl + '/NotifyApiMessageCategory/delete?ecid=' + this.apiService.secureApiQueryString(customerId) + "&emcid=" + this.apiService.secureApiQueryString(messageCategoryId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Delete MessageCategory data', []))
    );
  }
  
  public undoDeleteMessageCategory(customerId: string, messageCategoryId: string) {
    return this.http.put(this.envService.apiUrl + '/NotifyApiMessageCategory/undo?ecid=' + this.apiService.secureApiQueryString(customerId) + "&emcid=" + this.apiService.secureApiQueryString(messageCategoryId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Undo Delete MessageCategory data', []))
    );
  }

}


