
<div class="overflowAuto" style="height: calc(100vh - 64px - 37px - 86px);">

    <div class="contentBox media-grid-item-selections">
        <div class="CaptionLabel">Patient Mode</div>
        
        <div class="Padding10px backgroundWhite">

            <div class="MarginTop10px"></div>
            <div><label>The app will ask for patient mode password, when patient mode is enabled.</label></div>

            <div class="MarginTop10px"></div>
                        
            <div>
                <label>Enable:&nbsp;&nbsp;</label>
                <span>
                    <mat-slide-toggle [(ngModel)]="patientModeSetting.IsEnabled" (change)="savePatientModeSetting()" >
                    </mat-slide-toggle>
                </span>
            </div>
                        
            <div *ngIf="isAdminUser" class="MarginTop10px">
                <label>Patient mode password:&nbsp;&nbsp;</label> 
                <span><input [type]="showToggle.checked ? 'text' : 'password'" placeholder="password" [(ngModel)]="decryptedPassword" ></span> 
                <span><span>&nbsp;&nbsp;<label>Show</label>&nbsp;&nbsp;<mat-slide-toggle #showToggle></mat-slide-toggle></span></span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;<button  mat-raised-button (click)="savePatientModeSetting(); savePassword()">
                    Save<mat-icon>save</mat-icon>
                </button></span>
            </div>
            
        </div>
    </div>

    <div class="contentBox" *ngIf="isAdminUser">
        <div class="CaptionLabel">Auto Timeout</div>
        
        <div class="Padding10px backgroundWhite">

            <div class="MarginTop10px"></div>
            <div><label>Enable or disable automatic idle timeout.</label></div>

            <div class="MarginTop20px"></div>

            <div>
                <label>Disable:&nbsp;&nbsp;</label>
                <span>
                    <mat-slide-toggle [(ngModel)]="idleTimeoutSetting.isTimeoutDisabled" (change)="onIdleTimeoutStatusChanged()" >
                    </mat-slide-toggle>
                </span>
            </div>
            
        </div>
    </div>

    <div class="contentBox" *ngIf="isAdminUser">
        <div class="CaptionLabel">Device Id</div>
        
        <div class="Padding10px backgroundWhite">

            <div class="MarginTop10px">
                <label>Device Id:&nbsp;&nbsp;</label>
                <span>
                    <label>{{deviceId}}</label>                    
                </span>
            </div>
                        
        </div>
    </div>

</div>