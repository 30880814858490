// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

// Material
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

// Shared Lib
import { CloudApiResponse, NotificationBarService, EncryptionService } from "kscigcorelib";
import { LoadingBarService } from 'kscigcorelib';
import { LoggingService } from 'kscigcorelib';

// Application Core
import { RouteHelper } from '../../shared/helpers/route.helper';
import { SessionHelper } from '../../shared/helpers/session.helper';

// Component 
import { NotifyContactCategoriesService } from './notify-contact-categories.service';
import { ContactCategory } from '../../notify/notify-model';
import { ContactCategoryViewModel } from './notify-contact-categories-view-model';

@Component({
  selector: 'app-notify-contact-categories',
  templateUrl: './notify-contact-categories.component.html',
  styleUrls: ['./notify-contact-categories.component.css']
})
export class NotifyContactCategoriesComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ColumnsToDisplay = [
    'ContactCategoryName',
    'EditDelete',
    'Error'
  ];

  public customerId:string;
  public currentContactCategoryList: ContactCategoryViewModel[];
  public newContactCategory: ContactCategory;
  public ContactCategoryViewData = new MatTableDataSource<ContactCategoryViewModel>();
  public displayedColumns: string[] = this.ColumnsToDisplay;
  public isValidContactCategoryName: boolean = false;
  
  constructor(
    private routeHelper:RouteHelper,
    private sessionHelper:SessionHelper,
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private encryptionService:EncryptionService,
    private notificationBarService: NotificationBarService,
    private notifyContactCategoriesService: NotifyContactCategoriesService,
    public dialog: MatDialog,
    ) { 
    }

  ngOnInit() {
    if(this.sessionHelper.isValidUserSession()){
      // Start loading bar
      this.loadingBarService.startBar();
      this.customerId = this.sessionHelper.getCustomerId();
      this.initNewContactCategory();
      // Load Page Data
      this.loadPageData();
    }
  }

  private loadPageData(){
    this.notifyContactCategoriesService.getAllContactCategories(this.customerId)
      .subscribe({ 
          next: (contactCategoryResult: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(contactCategoryResult.payload);
                this.loggingService.logVerbose(decryptedPayload);                
                var contactCategory: ContactCategoryViewModel[] = JSON.parse(decryptedPayload);
                this.currentContactCategoryList = contactCategory; 
                this.ContactCategoryViewData.data.splice(0);               
                contactCategory.forEach(element => {
                  let vm = new ContactCategoryViewModel();
                  vm.ContactCategoryId = element.ContactCategoryId;
                  vm.ContactCategoryName = element.ContactCategoryName;                  
                  vm.IsDeleted = element.IsDeleted;
                  vm.IsDeletable = element.IsDeletable;
                  vm.IsEditing = element.IsEditing;
                  vm.RowEditMode = element.RowEditMode;
                  this.ContactCategoryViewData.data.push(vm);
                });
                this.ContactCategoryViewData.sort = this.sort;
                this.loadingBarService.stopBar(); 
              },
          error: () => { this.loggingService.logError("Error loading Contact Categories"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Contact Categories"); }
      });
  }

  public hasScroll(element: ElementRef): boolean {
    return element.nativeElement.scrollHeight > element.nativeElement.clientHeight;
  }

  public isOnEditMode(): boolean {
    return this.ContactCategoryViewData.data.findIndex((ContactCategory) => ContactCategory.IsEditing) >= 0;
  }

  public startEdit(ContactCategory: ContactCategoryViewModel): void {
    ContactCategory.IsEditing = true;
  }

  public cancelEdit(contactCategory: ContactCategoryViewModel): void {
    // reset
    let origConCategory:ContactCategoryViewModel = this.currentContactCategoryList.find(m => m.ContactCategoryId == contactCategory.ContactCategoryId);
    contactCategory.ContactCategoryName = origConCategory.ContactCategoryName;    
    contactCategory.IsEditing = false;
  }

  public deleteContactCategory(contactCategory: ContactCategoryViewModel){
    this.loggingService.logInformation(contactCategory);
    this.notifyContactCategoriesService.deleteContactCategory(this.customerId, contactCategory.ContactCategoryId)
      .subscribe({
          next: () => {
                this.loadPageData();
                this.notificationBarService.showSuccess("Deleted " +  contactCategory.ContactCategoryName);
                this.loggingService.logInformation("ContactCategory " +  contactCategory.ContactCategoryName + " has been deleted");
              },
          error: () => {
                this.notificationBarService.showError("Error deleting " +  contactCategory.ContactCategoryName);
                this.loggingService.logError("Error deleting ContactCategory " +  contactCategory.ContactCategoryName);
              },
          complete: () => { this.loggingService.logVerbose("Completed deletig ContactCategory " +  contactCategory.ContactCategoryName); }
      });
  }

  public undoDeleteContactCategory(contactCategory: ContactCategoryViewModel){
    this.loggingService.logInformation(contactCategory);
    this.notifyContactCategoriesService.undoDeleteContactCategory(this.customerId, contactCategory.ContactCategoryId)
      .subscribe({
          next: () => {
                this.loadPageData();
                this.notificationBarService.showSuccess("Undone delete " +  contactCategory.ContactCategoryName);
                this.loggingService.logInformation("ContactCategory " +  contactCategory.ContactCategoryName + " has been undeleted");
              },
          error: () => {
                this.notificationBarService.showError("Error undeleting " +  contactCategory.ContactCategoryName);
                this.loggingService.logError("Error undeleting ContactCategory " +  contactCategory.ContactCategoryName);
              },
          complete: () => { this.loggingService.logVerbose("Completed undeleting ContactCategory " +  contactCategory.ContactCategoryName); }
      });
  }

  private editContactCategory(contactCategory: ContactCategoryViewModel){
    if(contactCategory.ContactCategoryName != ''){
      this.notifyContactCategoriesService.updateContactCategory(this.customerId, contactCategory)
        .subscribe({
            next: () => {
                  contactCategory.IsEditing = false;
                  this.loadPageData();
                  this.notificationBarService.showSuccess("Updated " +  contactCategory.ContactCategoryName);
                  this.loggingService.logInformation("ContactCategory " +  contactCategory.ContactCategoryName + " has been updated");
                },
            error: () => {
                  this.notificationBarService.showError("Error upating " +  contactCategory.ContactCategoryName);
                  this.loggingService.logError("Error upating ContactCategory " +  contactCategory.ContactCategoryName);
                },
            complete: () => { this.loggingService.logVerbose("Completed upating ContactCategory " +  contactCategory.ContactCategoryName); }
        });
    }
  }

  
  public cancelAdd(){
    this.initNewContactCategory();
  }

  private initNewContactCategory(){
    this.newContactCategory = new ContactCategory();
  }

  public addContactCategory(contactCategory: ContactCategory){
    this.loggingService.logInformation(ContactCategory);
    this.notifyContactCategoriesService.addContactCategory(this.customerId, contactCategory)
      .subscribe({
          next: () => {
                this.loadPageData();
                this.initNewContactCategory();
                this.notificationBarService.showSuccess("Added " +  contactCategory.ContactCategoryName);
                this.loggingService.logInformation("ContactCategory " +  contactCategory.ContactCategoryName + " has been added");
              },
          error: () => {
                this.notificationBarService.showError("Error adding " +  contactCategory.ContactCategoryName);
                this.loggingService.logError("Error adding ContactCategory " +  contactCategory.ContactCategoryName);
              },
          complete: () => { this.loggingService.logVerbose("Completed adding ContactCategory " +  contactCategory.ContactCategoryName); }
      });
  }

  public CheckContactCategoryNameIsValid() {
    var name = this.newContactCategory.ContactCategoryName.trim();
    if (name != '') {
      var elem = this.currentContactCategoryList.find(x => x.ContactCategoryName == name);
      this.isValidContactCategoryName = (elem === undefined)
    } else {
      this.isValidContactCategoryName = false;
    }
  }
}
