<div class="stats-container">
  <div class="stats-header">
    <!-- <div class="Padding5px"></div> -->
    <div> 
      <label>Total Attempted:</label> 
      <span> {{totalAttempted}} </span> &nbsp;&nbsp;&nbsp;&nbsp;
      <label>Total Sent:</label> 
      <span> {{totalSent}} </span> &nbsp;&nbsp;&nbsp;&nbsp;
      <label>Total Failed:</label> 
      <span> {{totalFailed}} </span> &nbsp;&nbsp;&nbsp;&nbsp;
      <mat-form-field style="width: 200px;">
        <mat-label>Notify Type</mat-label>
        <mat-select [(value)]="selectedNotifyType" (selectionChange)="loadPageData()" >
          <mat-option value="1">SMS</mat-option>
          <mat-option value="2">Email</mat-option>    
        </mat-select>
      </mat-form-field>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <mat-form-field style="width: 200px;">
        <mat-label>Time Filter</mat-label>
        <mat-select [(value)]="selectedTimeFilter" (selectionChange)="loadPageData()" >
          <mat-option value="1">Today</mat-option>
          <mat-option value="2">Last 2 days</mat-option>    
          <mat-option value="3">Last 3 days</mat-option>    
          <mat-option value="4">Last 4 days</mat-option>    
          <mat-option value="5">Last 5 days</mat-option>          
        </mat-select>
      </mat-form-field>      
    </div>
       
  </div>

  <div class="stats-data" >
    <table mat-table [dataSource]="msgStatsDataSource">
        <!-- ContactPoint Column -->
      <ng-container matColumnDef="ContactPoint">
        <th mat-header-cell *matHeaderCellDef>
            <div *ngIf="selectedNotifyType == '1'">Phone Number</div>
            <div *ngIf="selectedNotifyType == '2'">Email</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.ContactPoint}} </td>
      </ng-container>

      <!-- SentOn Column -->
      <ng-container matColumnDef="SentOn">
        <th mat-header-cell *matHeaderCellDef> Sent On </th>
        <td mat-cell *matCellDef="let element"> {{element.SentOn}} </td>
      </ng-container>

      <!-- SendStatus Column -->
      <ng-container matColumnDef="IsSent">
        <th mat-header-cell *matHeaderCellDef> Send Status </th>
        <td mat-cell *matCellDef="let element" >
            <div *ngIf="element.IsSent" class="colorSuccess">Sent</div>
            <div *ngIf="!element.IsSent" class="colorError">Failed</div>
        </td>      
      </ng-container>

      <!-- Carrier Column -->
      <!-- <ng-container matColumnDef="Carrier">
        <th mat-header-cell *matHeaderCellDef> Carrier </th>
        <td mat-cell *matCellDef="let element"> {{element.Carrier}} </td>
      </ng-container> -->

      <!-- Provider Column -->
      <ng-container matColumnDef="Provider">
        <th mat-header-cell *matHeaderCellDef> Provider </th>
        <td mat-cell *matCellDef="let element"> {{element.Provider}} </td>
      </ng-container>

      <!-- ErrorMessage Column -->
      <ng-container matColumnDef="ErrorMessage">
        <th mat-header-cell *matHeaderCellDef> Error Message </th>
        <td mat-cell *matCellDef="let element"> {{element.ErrorMessage}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>
  </div>
  <div class="record-count-label"><label>Total records: </label> {{msgStatsDataSource.data.length}}</div>
  <mat-paginator class="stats-paginator" [length]="msgStatsData.length" [pageSize]="15" [pageSizeOptions]="[15, 20, 25]" showFirstLastButtons aria-label="Select page"></mat-paginator>

</div>