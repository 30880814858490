import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import {MatDialog as MatDialog, MatDialogRef as MatDialogRef, MatDialogState as MatDialogState} from '@angular/material/dialog';
import { DeviceDetectorService } from '../../shared/services/devicedetector.service';
import * as shaka from 'shaka-player';


import { LoadingBarService, LoggingService, NotificationBarService } from 'kscigcorelib';

import { ValidateInputDialogComponent } from '../validate-input-dialog/validate-input-dialog.component';
import { SessionHelper } from 'src/app/shared/helpers/session.helper';


const fairplay1DrmServer:string = 'com.apple.fps.1_0';
const fairplayModernDrmServer:string = 'com.apple.fps';

@Component({
  selector: 'app-media-player-shaka',
  templateUrl: './media-player-shaka.component.html',
  styleUrls: ['./media-player-shaka.component.css']
})
export class MediaPlayerShakaComponent implements AfterViewInit {

  @ViewChild('videoPlayer') videoElementRef: ElementRef | undefined;
  @ViewChild('videoContainer') videoContainerRef: ElementRef | undefined;

  @Input() mediaUrl: string = '';
  @Input() posterUrl: string = '';
  @Input() startPlayPositionSeconds: number = 0;
  @Input() swankPortalId: string = '';
  @Input() widevineLicenseServerUrl: string = ''; 
  @Input() fairplayLicenseServerUrl: string = ''; 
  @Input() fairplayCertificateUrl: string = ''; 

  @Input() isPatientModeEnabled: boolean = false;
  @Input() patientModePassword: string = '';

  @Output() mediaPlayEvent = new EventEmitter<{playPositionSeconds: number}>();
  @Output() mediaPauseEvent = new EventEmitter<{pausePositionSeconds: number}>();
  @Output() mediaSeekEndEvent = new EventEmitter<{seekStartPositionSeconds: number, seekEndPositionSeconds: number}>();
  @Output() mediaPlayExitEvent = new EventEmitter<{stopPositionSeconds: number, isPasswordValidated: boolean, doTriggerMediaStop: boolean}>();
 
  videoElement: HTMLVideoElement | undefined; 
  videoContainerElement: HTMLDivElement | undefined; 
  shakaPlayer: any;

  isFairplay1Supported = false;
  isFairplayModernSupported = false;
  isBrowserSafari = false;

  pausedPositionSeconds: number = 0;
  isMediaPlaying: boolean = false;
  isInitialLoad = true;
  
  dialogRef: MatDialogRef<ValidateInputDialogComponent>;

  constructor(
    private deviceService: DeviceDetectorService,
    private loggingService:LoggingService,
    private sessionHelper: SessionHelper,
    public dialog: MatDialog) { }

  ngOnInit(): void {    
    
    // browser 
    this.isBrowserSafari = this.deviceService.isBrowserSafari();
    this.loggingService.logVerbose("Browser is Safari: " + this.deviceService.isBrowserSafari());
    
    if (this.isPatientModeEnabled) {
      this.sessionHelper.setIsPatientModeInUse(true);
      history.pushState(null, null, location.href);         
    }
    shaka.polyfill.installAll();
    if (this.isBrowserSafari) {
      
      this.mediaUrl = this.mediaUrl.replace(".mpd", ".m3u8");             
      this.isFairplayModernSupported = true;
      //this.swankPortalId = "C195429C-7976-426E-980E-C4BEDBEC0B95";
    }
    
  }

  async ngAfterViewInit() {
            
    // Check to see if the browser supports the basic APIs Shaka needs.
    if (shaka.Player.isBrowserSupported()) {

      this.videoElement = this.videoElementRef?.nativeElement;
      this.videoContainerElement = this.videoContainerRef?.nativeElement;
      
      await this.initPlayer();
      
      this.handleBrowserBackNavigation(); 
    } else {
      this.loggingService.logError('Browser not supported by Shaka player!');
    }
    
  }

  /* Prevent browser back navigation in Patient Mode
  */
  private handleBrowserBackNavigation() {    
    window.onpopstate = (event) => {
      if (this.isPatientModeEnabled) {
        window.history.go(1);
        this.loggingService.logDebug("Patient Mode is enabled. Restricting browser back navigation event.");

        //this.pauseMedia();
        //this.mediaPlayExitEvent.emit({ stopPositionSeconds: this.getPlayPositionSeconds(), isPasswordValidated: false, doTriggerMediaStop: doTriggerMediaStop });
      } else {
        this.loggingService.logDebug("Patient Mode is disabled. Allow normal browser back navigation");
      }      
    };    
  }

  private preventBrowserBackNavigation() {
    if (this.isPatientModeEnabled) {
      this.loggingService.logDebug("Patient Mode is enabled. Restricting browser back navigation event.");
      history.pushState(null, null, location.href);
      window.onpopstate = (event) => {
        window.history.go(1);
      };
    } else {
      this.loggingService.logDebug("Patient Mode is disabled. Allow normal browser back navigation");
    }
  }
    
  private async initPlayer() {     
    this.shakaPlayer = new shaka.Player();
    await this.shakaPlayer.attach(this.videoElement);
          
    this.videoElement.oncanplay = (event) => {  
      if (this.isPatientModeEnabled) {
        document.getElementById("backgroundBlockPanel").style.display = 'inline-block';    
      }
      document.getElementById("playButton").style.display = 'inline-block'; 
    };

    this.videoElement.onplay = (event) => { 
      this.isMediaPlaying = true;
      this.mediaPlayEvent.emit({playPositionSeconds: this.getPlayPositionSeconds()});
      if (this.isPatientModeEnabled) { 
        this.sessionHelper.setIsPatientModeInUse(true);
        document.getElementById("backgroundBlockPanel").style.display = 'none';  
      }   
      document.getElementById("playButton").style.display = 'none'; 
    };
    
    this.videoElement.onpause = (event) => {  
      this.isMediaPlaying = false;
      this.pausedPositionSeconds = this.getPlayPositionSeconds();
      this.mediaPauseEvent.emit({pausePositionSeconds: this.pausedPositionSeconds});       
    };

    // this.videoElement.onseeked = (event) => {
    //   let seekEndPosition: number = this.getPlayPositionSeconds();
    //   if (!this.isInitialLoad && this.pausedPositionSeconds != seekEndPosition) {
    //     //this.mediaSeekEndEvent.emit({seekStartPositionSeconds: this.pausedPositionSeconds, seekEndPositionSeconds: this.getPlayPositionSeconds()});
    //     console.log("***************seek end: " + this.pausedPositionSeconds + " :: " + (this.getPlayPositionSeconds()));
    //   }
    //   this.isInitialLoad = false;
    //   //this.startPlayPositionSeconds = this.getPlayPositionSeconds();
      
    // };    
    
    this.videoElement.onfullscreenchange = (event) =>  this.handleFullscreenExit;
    this.videoElement.addEventListener('webkitendfullscreen', (event) =>  this.handleFullscreenExit());
    this.videoContainerElement.addEventListener('fullscreenchange', (event) =>  this.handleFullscreenExit());
    
    await this.configureDrm();
    await this.configureLicenseRequest();                  
    
  }

  private loadPlayer() {   
    this.shakaPlayer
            .load(this.mediaUrl, this.startPlayPositionSeconds)
            .then(() => {
              //this.videoElement?.play();        
            })
            .catch((e: any) => {              
              this.loggingService.logError(e);
              //console.error('Error code', e.code, 'object', e);              
            });   
  }

  handleFullscreenExit() {
    if (!document.fullscreenElement) { 
      this.exitFullscreen(this.videoElement);      
      this.onMediaPlayExit();        
    }   
  }

  private enterBrowserFullscreen(elem: any) {
      this.loggingService.logDebug("Browser fullscreen element: " + elem);
      if (elem != null && typeof elem != "undefined") {
        if (elem.requestFullscreen) {
            elem.requestFullscreen().catch((e: any) => { this.handleFullscreenFailure(e); });
        } else if (elem.mozRequestFullScreen) { /* Firefox */ 
            elem.mozRequestFullScreen().catch((e: any) => { this.handleFullscreenFailure(e); });
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          try {
            elem.webkitRequestFullscreen();
          } catch (ex) {
            this.handleFullscreenFailure(ex);
          }
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen().catch((e: any) => { this.handleFullscreenFailure(e); });
        }        
      }    
  }

  private exitFullscreen(elem) {      
    try {        
      if (elem != null && typeof elem != "undefined") {
        if (elem.exitFullscreen) {
          elem.exitFullscreen();
        } else if (elem.mozCancelFullScreen) { /* Firefox */
          elem.mozCancelFullScreen();
        } else if (elem.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          elem.webkitExitFullscreen();
        } else if (elem.msExitFullscreen) { /* IE/Edge */
          elem.msExitFullscreen();
        }                  
      }    
    } catch (ex) {
      //alert(ex);
    }
  }

  private handleFullscreenFailure(error) {
    this.loggingService.logError(error); //alert(error);
    if (this.isPatientModeEnabled) {
      this.onMediaPlayExit();        
    }
  }
    
  private async configureDrm() {    
    this.loggingService.logVerbose("Configuring DRM");
    this.shakaPlayer.resetConfiguration();
    if (!this.isBrowserSafari) {
      
      this.shakaPlayer.configure({
        drm: {
          servers: {
            'com.widevine.alpha': this.widevineLicenseServerUrl
          }          
        }
      });
      
    } else {   // Safari 
      
      const certReq = await fetch(this.fairplayCertificateUrl);  
      const cert = await certReq.arrayBuffer();
      var certValue = new Uint8Array(cert);
            
      if (this.isFairplayModernSupported) { // modern fairplay DRM

        this.shakaPlayer.configure({
          //preferredAudioLanguage: 'en-US',
          drm: {
            servers: {
              'com.apple.fps': this.fairplayLicenseServerUrl,
            },
            advanced: {
              'com.apple.fps': {
                serverCertificate: certValue,
              },
            },
          },
        });
      } else if (this.isFairplay1Supported) { // version 1.0 fairplay DRM
        
        this.shakaPlayer.configure({
          //preferredAudioLanguage: 'en-US',
          drm: {
            servers: {
              'com.apple.fps.1_0': this.fairplayLicenseServerUrl,
            },
            advanced: {
              'com.apple.fps.1_0': {
                serverCertificate: certValue,
              },
            },
          },
        });

      }    
    }
    this.loggingService.logDebug("Configured Shaka Player");
  }

  private async configureLicenseRequest() {
    this.loggingService.logVerbose("Configuring Request Header");
    if (!this.isBrowserSafari) {
      this.shakaPlayer.getNetworkingEngine().registerRequestFilter((type, request) => {      
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {           
          request.headers['swankportal'] = this.swankPortalId;
        }
      });
    } else { // Safari
      
      // sends portal to the license server request so it can check of the content has a valid license
      // each license type has a slightly different format for headers      
      this.shakaPlayer.getNetworkingEngine().registerRequestFilter((type, request) => {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          //console.log(request);
          if (request.uris[0] === this.fairplayLicenseServerUrl) {
            this.buildFairPlayRequest(request);
          } 
        }
      });

      this.shakaPlayer.getNetworkingEngine().registerResponseFilter((type, request) => {
        if (type != shaka.net.NetworkingEngine.RequestType.LICENSE) {
          return;
        }
        if (this.isFairplay1Supported || this.isFairplayModernSupported) {
          //console.log(request);
          this.buildFairPlayResponse(request);          
        }
      });       
    }
    this.loggingService.logDebug("Configured License Request");
    this.loadPlayer(); 
  }

  
  private buildFairPlayRequest(request) { 
    this.loggingService.logVerbose("Fair play license request received");
    const originalPayload = new Uint8Array(request.body);
    
    var base64Payload = shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);
      
    const params = 'spc=' + base64Payload;
    request.headers['swankportal'] = this.swankPortalId;
    request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    request.body = shaka.util.StringUtils.toUTF8(params); 

    this.loggingService.logDebug('Build Fair Play Request Completed');
  }
  
  private buildFairPlayResponse(request) {    
      let responseText = shaka.util.StringUtils.fromUTF8(request.data);
      // Trim whitespace.
      responseText = responseText.trim();
      //this.loggingService.logVerbose("Fair play response text: " + responseText);
      
      // Look for <ckc> wrapper and remove it.
      if (responseText.substr(0, 5) === '<ckc>' &&
        responseText.substr(-6) === '</ckc>') {
        responseText = responseText.slice(5, -6);
      }
      //this.loggingService.logVerbose("Fair play - decoding response");
      // Decode the base64-encoded data into the format the browser expects.
      request.data = shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
      
      this.loggingService.logDebug('Build Fair Play Response Completed');    
  }
  
 
  private getPlayPositionSeconds(): number {
    return Number(this.videoElement?.currentTime.toFixed());
  }

  private pauseMedia() {
    this.loggingService.logDebug("Pausing Media: " + this.getPlayPositionSeconds());
    this.videoElement?.pause();
  }

  playMedia() {
    this.loggingService.logDebug("Playing Media");
    this.enterBrowserFullscreen(this.videoElement);
    this.videoElement?.play().catch((e: any) => { //alert(e);
    this.loggingService.logError(e)});       
  }

  /* Pause media and save play position before exit.
     If Patient mode is on, then prevent exit from page, unless password is validated.
  */
  onMediaPlayExit() {    
    let doTriggerMediaStop: boolean = this.isMediaPlaying;
    if (doTriggerMediaStop) {
      this.pauseMedia();      
    }
    let playPositionSeconds: number = this.getPlayPositionSeconds();

    if (this.isPatientModeEnabled) {
      
        if (!this.dialogRef || this.dialogRef.getState() !== MatDialogState.OPEN) {
          
            // Open dialog requesting password
            this.dialogRef = this.dialog.open(ValidateInputDialogComponent, {
              data: {title: "Enter Password", message: "Enter password to exit", 
                      errorMessage: "Invalid password", inputLabel: "", validateInputWith: this.patientModePassword},
              disableClose: true,
              //closeOnNavigation: false,
              enterAnimationDuration: '1000ms',
              exitAnimationDuration: '500ms'
            });
                  
            this.dialogRef.afterClosed().subscribe(result => {
              this.loggingService.logVerbose('The password dialog was closed');
              
              if (result) { 
                this.sessionHelper.setIsPatientModeInUse(false);
                this.mediaPlayExitEvent.emit({stopPositionSeconds: playPositionSeconds, isPasswordValidated: result, doTriggerMediaStop: doTriggerMediaStop});
              } else {                                      
                this.playMedia();
              }
            });
        }
      
    } else {
      this.mediaPlayExitEvent.emit({stopPositionSeconds: playPositionSeconds, isPasswordValidated: false, doTriggerMediaStop: doTriggerMediaStop});
    }
  } 

}
