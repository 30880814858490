// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Application Shared Lib
import { CloudApiResponse, CoreHelper } from 'kscigcorelib';

// Application
import { HandleErrorService, ApiService, LoggingService, EnumMediaType, EnvService  } from 'kscigcorelib';

// Application




@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  
  constructor(
    private coreHelper:CoreHelper,
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private loggingService:LoggingService,
    private envService: EnvService
    ) {
      
     }

  getTimeFilters(): Observable<CloudApiResponse>{
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiActivityLog/timeFilters', this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Activity Time Filters'))
    );
  }

  
  getActivityLogForPatient(customerId:string, patientGuid:string, timeFilterId:number, moduleType: string): Observable<CloudApiResponse>{
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiActivityLog/patient?ecid=' + this.apiService.secureApiQueryString(customerId) + 
                              "&epgid=" + this.apiService.secureApiQueryString(patientGuid) + "&timeFilterId=" + timeFilterId + "&mType=" + moduleType, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Patient Activity logs'))
    );
  }

  getActivityLogForSession(customerId:string, timeFilterId:number, moduleType: string): Observable<CloudApiResponse>{ 
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiActivityLog/user?ecid=' + this.apiService.secureApiQueryString(customerId) + 
                               "&timeFilterId=" + timeFilterId + "&mType=" + moduleType, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Session Activity logs'))
    );
  }

  getActivityLogForPatient_Sensitive(customerId:string, patientGuid:string, timeFilterName:string, moduleType: string): Observable<CloudApiResponse>{
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/WorklistApiActivityLog/patient?ecid=' + this.apiService.secureApiQueryString(customerId) + 
                              "&epgid=" + this.apiService.secureApiQueryString(patientGuid) + "&timeFilterName=" + timeFilterName + "&mType=" + moduleType, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Patient Activity logs'))
    );
  }

  getActivityLogForSession_Sensitive(customerId:string, timeFilterName:string, moduleType: string): Observable<CloudApiResponse>{ 
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/WorklistApiActivityLog/user?ecid=' + this.apiService.secureApiQueryString(customerId) + 
                               "&timeFilterName=" + timeFilterName + "&mType=" + moduleType, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Session Activity logs'))
    );
  }
  
    
}
