<div *ngIf="!isModuleEnabled">
    <div class="alignCenter Padding5px">
        Oops! You are not subscribed to this module.
    </div>
</div>

<div *ngIf="isModuleEnabled">
    <div class="worklist-header">
        <div>
            <button mat-icon-button class="square" title="Add Patient" (click)="openAddPatientDialog()">
                <mat-icon>person_add</mat-icon>
            </button>
            <button mat-icon-button class="square" style="margin-left: 1px;" title="Deselect Patient" (click)="deselectPatient()">
                <mat-icon>undo</mat-icon>
            </button>
            <button mat-icon-button class="square" style="margin-left: 1px;" alt="Notify" title="Notify" (click)="notifyPatients()" [hidden]="this.selection.selected.length == 0">
                <mat-icon>message</mat-icon>
            </button>
            <button mat-icon-button class="square" style="margin-left: 1px;" alt="Send Opt-In SMS" title="Send Opt-In SMS" (click)="sendOptInSMS()" [hidden]="!(this.selection.selected.length > 0 && this.getSMSConsentFilter() == 1)">
                <mat-icon>send</mat-icon>
            </button>
        </div>
    
        <form [formGroup]="worklistSearchForm">
            <div class="worklist-header-searchInput">
                <mat-form-field style="width: 150px;min-width: 100px;">
                    <mat-label>Criteria</mat-label>
                    <mat-select formControlName="searchCriteria" (selectionChange)="searchCriteriaChanged($event.value)">
                        <mat-option *ngFor="let criteria of Criterias" [value]="criteria.criteriaId">
                            {{criteria.criteriaName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="margin-left: 5px; width: 220px;">
                    <mat-label>Location</mat-label>
                    <mat-select formControlName="searchLocation" (selectionChange)="locationChanged()">
                        <mat-option *ngFor="let location of Locations" [value]="location.LocationId">
                            {{location.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="margin-left: 5px;width: 150px;" *ngIf="this.WLTimes.length > 1">
                    <mat-label>Time</mat-label>
                    <mat-select formControlName="searchTime" (selectionChange)="timeFilterChanged()">
                        <mat-option *ngFor="let wlTime of WLTimes" [value]="wlTime.FilterNumber">
                            {{wlTime.FilterName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="margin-left: 5px;width: 150px;" *ngIf="this.SMSConsentOptions.length > 1">
                    <mat-label>SMS Consent</mat-label>
                    <mat-select formControlName="searchSMSConsent" (selectionChange)="smsConsentFilterChanged()">
                        <mat-option *ngFor="let smsConsentOption of SMSConsentOptions" [value]="smsConsentOption.FilterNumber">
                            {{smsConsentOption.FilterName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="margin-left: 5px;width: 200px;">
                    <mat-label style="font-size: 14px;">Keyword</mat-label>
                    <input matInput placeholder="Keyword" formControlName="searchKeyword" style="padding-right: 24px;" (keydown.enter)="search()" />                     
                </mat-form-field>
                <i (click)="search()" class="material-icons" [disabled]="isKeywordSearchDisabled" style="margin-left: -24px; z-index: 1; cursor: pointer;">search</i>
            </div>
        </form>
        <div></div>
    </div>
    
    <div class="worklist-data contentBox overflowAuto" >  <!--206 (grid) + 60 (paginator)-->        
        <table mat-table [dataSource]="patientsDataSource" class="mat-elevation-z1" matSort (matSortChange)="sortChanged($event)" >
            <ng-container matColumnDef="Selector" >
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" >
                  <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                               >
                  </mat-checkbox>
                </td>
              </ng-container>
            <ng-container matColumnDef="Action" >
                <th mat-header-cell *matHeaderCellDef > Action </th>                
                <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" >
                    <button mat-icon-button *ngIf="row.ByUser" (click)="openEditPatientDialog(row)" title="Edit Patient"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button *ngIf="row.ByUser" (click)="deletePatient(row)" style="margin-left: 1px;" title="Delete Patient"><mat-icon>delete</mat-icon></button>
                </td>                
            </ng-container>
            <ng-container matColumnDef="PatId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Patient Id" sorted="">Patient Id</th>
                <td mat-cell *matCellDef="let element"> {{element.PatId}} </td>
            </ng-container>
            <ng-container matColumnDef="PatientName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Patient Name">Patient Name</th>
                <td mat-cell *matCellDef="let element"> {{element.PatientName}} </td>
            </ng-container>
            <ng-container matColumnDef="G">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gender">Gender</th>
                <td mat-cell *matCellDef="let element"> {{element.G}} </td>
            </ng-container>
            <ng-container matColumnDef="DOB">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by DOB">Date of Birth</th>
                <td mat-cell *matCellDef="let element"> {{element.DOB}} </td>
            </ng-container>
            <ng-container matColumnDef="SurgeryDT">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Surgery Time">Surgery Time</th>
                <td mat-cell *matCellDef="let element"> {{element.SurgeryDT}} </td>
            </ng-container>
            <ng-container matColumnDef="Surgeon">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Surgeon Name">Surgeon Name</th>
                <td mat-cell *matCellDef="let element"> {{element.Surgeon}} </td>
            </ng-container>
            <ng-container matColumnDef="Procedure">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Procedure Name">Procedure</th>
                <td mat-cell *matCellDef="let element"> {{element.Procedure}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let iRow=index" (click)="selectPatient(row)" [class.selected-row]="currentWorklistItem && row.PatGId === currentWorklistItem.PatGId" ></tr>
        </table>
        <div class="msg" *ngIf="getSearchCriteria() != 1 && (getSearchKeyword() == undefined || getSearchKeyword() == '')" >Please enter search Keyword</div>
        <div class="msg" *ngIf="noPatientsFound && !(getSearchCriteria() != 1 && (getSearchKeyword() == undefined || getSearchKeyword() == ''))" >No patients found</div>
    </div>
    <div class="record-count-label"><label>Total records: </label> {{worklistItems.length}}</div>
    <mat-paginator class="worklist-paginator" [length]="worklistItems.length" [pageSize]="itemsPerPage" [pageSizeOptions]="[15, 20, 25]" showFirstLastButtons aria-label="Select page of Patients" 
                                            (page)="pageEvent = handlePageEvent($event)"></mat-paginator>
</div>
