// Angular
import { Component, OnInit, ElementRef, ChangeDetectorRef} from '@angular/core';
import { FormControl}  from '@angular/forms';


// Shared Lib
import { CloudApiResponse } from 'kscigcorelib';
import { LoadingBarService, LoggingService, NotificationBarService, EncryptionService } from 'kscigcorelib';
import { MediaModel, MediaType, MediaCategory, MediaCategoryMap, MediaTemplateComponent, CustomerMediaData } from 'kscigcorelib';

// Material
import { MatTabChangeEvent as MatTabChangeEvent } from '@angular/material/tabs'

// Application
import { RouteHelper } from '../shared/helpers/route.helper';
import { SessionHelper } from '../shared/helpers/session.helper';
import { MediaService } from '../shared/services/media.service'
import { SharedPatientService } from '../shared/services/sharedpatient.service';
import { EnumUserAction } from '../activity/activity.enum';


@Component({ 
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})

export class MediaComponent implements OnInit {

  public isModuleEnabled:boolean = false;

  public customerId:string;
  public sessionId:string;  
  public currentPatientGuid: string;
  public mediaTypeList: MediaType[] = [];
  public mediaList: MediaModel[] = [];
  public mediaCategoryList: MediaCategory[] = [];
  public mediaCategoryMapList: MediaCategoryMap[] = [];
  public selectedMediaCategory: MediaCategory = new MediaCategory();
  public selectedMedia: MediaModel = new MediaModel();
  public selectedMediaType: MediaType = new MediaType();
  public savedMediaPref: CustomerMediaData = new CustomerMediaData();
  public unsavedMediaPref: CustomerMediaData = new CustomerMediaData();
  public emptyGuid = "00000000-0000-0000-0000-000000000000";

  constructor( 
    private routeHelper:RouteHelper,
    private sessionHelper:SessionHelper,
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private mediaService:MediaService,
    private notificationBarService: NotificationBarService,
    private cdr: ChangeDetectorRef,
    private patientService: SharedPatientService,
    private encryptionService: EncryptionService
    ) { 
    }

  ngOnInit() {
        
    this.isModuleEnabled = this.sessionHelper.isMediaModuleEnabled();
    
    if(this.isModuleEnabled){      
      if(this.sessionHelper.isValidUserSession()){
        // Start loading bar
        this.loadingBarService.startBar();
        this.customerId = this.sessionHelper.getCustomerId();
        this.sessionId = this.sessionHelper.getSessionId();
        var currPatient = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getCurrentPatient()))
        if (currPatient != null) {
          this.currentPatientGuid = currPatient.PatientGuid;      
        }

        this.savedMediaPref.CustomerId = this.customerId;
        this.unsavedMediaPref.CustomerId = this.customerId;

        // Load Page Data
        this.loadPageData();
      }
    }
    // ExpressionChangedAfterItHasBeenCheckedError is thrown in debug mode.
    // Angular throws this exception when an expression value has been changed after change detection has completed.
    // In this case template was loaded before page data was loaded, hence the exception. Below is one of the recommended solutions.
    // For further info on this exception please refer to https://angular.io/errors/NG0100
    this.cdr.detectChanges();
  
  }

  private loadPageData(){
        
    this.mediaService.getMediaTypes(this.customerId)
      .subscribe({
          next: (mediaTypeResult: CloudApiResponse) => {
                this.loggingService.logVerbose(mediaTypeResult.payload);
                this.mediaTypeList = mediaTypeResult.payload;
                
                  // get Media Category
                  this.mediaService.getMediaCategories(this.customerId)
                  .subscribe({
                      next: (mediaCategoriesResult: CloudApiResponse) => {
                            this.loggingService.logVerbose(mediaCategoriesResult.payload);
                            this.mediaCategoryList = mediaCategoriesResult.payload;            
                            if (this.mediaCategoryList.length > 0) {
                              this.selectedMediaCategory = this.mediaCategoryList[0];
                            }
                              
                              // get Media
                              this.mediaService.getMediaAll(this.customerId, true)
                              .subscribe({
                                  next: (mediaResult: CloudApiResponse) => {
                                        var decryptedResult = this.encryptionService.decryptUsingAES256(mediaResult.payload);
                                        this.loggingService.logVerbose(decryptedResult);
                                        this.mediaList = JSON.parse(decryptedResult);
                                                
                                          // get Media Categtory Map
                                          this.mediaService.getMediaCategoryMap(this.customerId)
                                          .subscribe({
                                              next: (mediaCatMapResult: CloudApiResponse) => {
                                                    this.loggingService.logVerbose(mediaCatMapResult.payload);
                                                    this.mediaCategoryMapList = mediaCatMapResult.payload;            
                                                    
                                                      // populate Media Category in Media list
                                                      this.mediaList.forEach(media => {
                                                        let mediaCategoryMap = this.mediaCategoryMapList.find(x => x.mediaId == media.MediaId);
                                                        if (mediaCategoryMap != undefined)
                                                        {
                                                          media.MediaCategoryId = mediaCategoryMap.mediaCategoryId;
                                                        }
                                                      });

                                                      // get customerMediaData
                                                      // if patient is selected get patient saved media data              
                                                      if (this.currentPatientGuid != undefined) { 
                                                        this.mediaService.getPatientMediaData(this.customerId, this.currentPatientGuid)
                                                            .subscribe({
                                                                next: (apiResponse: CloudApiResponse) => {
                                                                      var decryptedResult = this.encryptionService.decryptUsingAES256(apiResponse.payload);
                                                                      this.loggingService.logVerbose(decryptedResult);
                                                                      let patientMediaData: CustomerMediaData = JSON.parse(decryptedResult);

                                                                      if (patientMediaData != undefined && patientMediaData.MediaId && patientMediaData.MediaId != this.emptyGuid) {                                  
                                                                          this.setSavedMediaPref(patientMediaData);
                                                                          this.setUnsavedMediaPref(patientMediaData);                                    
                                                                          this.setSelectedMedia(patientMediaData.MediaId);
                                                                      }
                                                                      this.loadingBarService.stopBar();
                                                                    },
                                                                error: () => { this.loggingService.logError("Error getting Patient media data for patient " + this.currentPatientGuid); },
                                                                complete: () => { this.loggingService.logVerbose("Completed getting Patient media data for patient " + this.currentPatientGuid); }
                                                            }); 
                                                            
                                                        } else { 
                                                          // if patient does not exist of then get session saved media data
                                                          this.mediaService.getSessionMediaData(this.customerId, this.sessionId)
                                                              .subscribe({ 
                                                                  next: (apiResponse: CloudApiResponse) => {
                                                                        var decryptedResult = this.encryptionService.decryptUsingAES256(apiResponse.payload);
                                                                        this.loggingService.logVerbose(decryptedResult);
                                                                        let sessionMediaData: CustomerMediaData = JSON.parse(decryptedResult);
                                                                        
                                                                        if (sessionMediaData.MediaId && sessionMediaData.MediaId != this.emptyGuid) {
                                                                          this.setSavedMediaPref(sessionMediaData);
                                                                          this.setUnsavedMediaPref(sessionMediaData);                              
                                                                          this.setSelectedMedia(sessionMediaData.MediaId);                              
                                                                        } 
                                                                        this.loadingBarService.stopBar();                            
                                                                      },
                                                                  error: () => { this.loggingService.logError("Error getting session media data for session " + this.sessionId); },
                                                                  complete: () => { this.loggingService.logVerbose("Completed getting session media data for session " + this.sessionId); }
                                                              });
                                                        }             
                                                    },
                                                error: () => { this.loggingService.logError("Error getting media Category map"); },
                                                complete: () => { this.loggingService.logVerbose("Completed getting Media Category Map"); }
                                            });
                                        },
                                  error: () => { this.loggingService.logError("Error getting media all"); },
                                  complete: () => { this.loggingService.logVerbose("Completed getting media all"); }
                              }); 
                            },
                      error: () => { this.loggingService.logError("Error getting Media Categories"); },
                      complete: () => { this.loggingService.logVerbose("Completed getting Media Categories"); }                          
                  });       
                
                },
          error: () => { this.loggingService.logError("Error getting media types"); },
          complete: () => { this.loggingService.logVerbose("Completed getting Media Types"); }
      });
  }


  private setSavedMediaPref(mediaData: CustomerMediaData) {
    this.savedMediaPref.MediaId = mediaData.MediaId;
    this.savedMediaPref.ShowSlideShow = mediaData.ShowSlideShow;
  }

  private setUnsavedMediaPref(mediaData: CustomerMediaData) {
    this.unsavedMediaPref.MediaId = mediaData.MediaId;
    this.unsavedMediaPref.ShowSlideShow = mediaData.ShowSlideShow;
  }

  private setSelectedMedia(mediaId: string) { 
    this.selectedMedia = this.mediaList.find(m=>m.MediaId == mediaId);
    if (this.selectedMedia) {
      this.selectedMediaType = this.mediaTypeList.find(m=>m.mediaTypeId == this.selectedMedia.MediaTypeId);    
    }
  }

  public onMediaSelectionChange(media: MediaModel) {   
    this.selectedMedia = media;
    this.selectedMediaType = this.mediaTypeList.find(m=>m.mediaTypeId == media.MediaTypeId);
    
    this.unsavedMediaPref.MediaId = this.selectedMedia.MediaId;
    this.unsavedMediaPref.MediaTypeId = this.selectedMedia.MediaTypeId;
    // Show slide show does not apply to video, so set to false. For audio, by default we want to show SlideShow, so set to true
    this.unsavedMediaPref.ShowSlideShow = this.selectedMediaType.mediaTypeName == "Video" ? false : true;
    
  }
  
  
  public saveMediaSelection(showSlideShowChoice: boolean) {       
    
    this.unsavedMediaPref.ShowSlideShow = showSlideShowChoice;

    //if patient is selected, save selection to Patient
    if (this.currentPatientGuid != null) {

      this.unsavedMediaPref.PatientId = this.currentPatientGuid;
      this.unsavedMediaPref.SessionId = this.sessionId;
      
      this.mediaService.setPatientMediaData(EnumUserAction.MediaSelect.toString(), this.unsavedMediaPref)
        .subscribe({
            next: (isSaved: boolean) => {
                  this.savedMediaPref.PatientId = this.currentPatientGuid;
                  this.savedMediaPref.SessionId = this.sessionId;
                  this.savedMediaPref.MediaId = this.unsavedMediaPref.MediaId;
                  this.savedMediaPref.ShowSlideShow = this.unsavedMediaPref.ShowSlideShow;                      
                },
            error: () => {
                  this.loggingService.logVerbose("Error Saving Customer Patient Media selection");
                  this.notificationBarService.showError("Error Saving Customer Patient Media selection");  
                },
            complete: () => {
                  this.loggingService.logVerbose("Saved Patient media selection");
                  this.notificationBarService.showSuccess("Saved Patient media selection");
                }
        });
    }
    else // else, save selection to session
    {
      this.unsavedMediaPref.PatientId = null;
      this.unsavedMediaPref.SessionId = this.sessionId;
      
      this.mediaService.setSessionMediaData(EnumUserAction.MediaSelect.toString(), this.unsavedMediaPref)
        .subscribe({
           next: (isSaved: boolean) => {
                  this.savedMediaPref.PatientId = null;
                  this.savedMediaPref.SessionId = this.sessionId;
                  this.savedMediaPref.MediaId = this.unsavedMediaPref.MediaId;
                  this.savedMediaPref.ShowSlideShow = this.unsavedMediaPref.ShowSlideShow;    
                  
                },
           error: ()=>{
                    this.loggingService.logVerbose("Error Saving Customer Session Media selection");
                    this.notificationBarService.showError("Error Saving Customer Session Media selection");  
                  },
           complete: () => {
                    this.loggingService.logVerbose("Saved Session media selection");
                    this.notificationBarService.showSuccess("Saved Session media selection");
                  }
        });
    }

  }

  public clearMediaSelection() {
    
    // if patient is selected, clear patient media, else clear session media
    if (this.currentPatientGuid != null) {
      
      this.mediaService.deletePatientMediaData(this.customerId, this.currentPatientGuid)
        .subscribe({
            next: (result: CloudApiResponse) => {          

                  this.selectedMedia.MediaId = null;
                  this.savedMediaPref.MediaId = null; 
                  this.unsavedMediaPref.MediaId = null; //= new CustomerMediaData();
                  this.ngOnInit();
                },
            error: () => {
                    this.loggingService.logVerbose("Error Clearing Patient Media selection");
                    this.notificationBarService.showError("Error Clearing Patient Media selection");  
                  },
            complete: () => {
                    this.loggingService.logVerbose("Cleared Patient media selection");
                    this.notificationBarService.showSuccess("Cleared Patient media selection");
                  }                    
        });
    }
    else // else, save selection to session
    {            
      this.mediaService.deleteSessionMediaData(this.customerId)
        .subscribe({
            next: (result: CloudApiResponse) => {
                    var isDeleted = result.payload;
                                
                    this.selectedMedia.MediaId = null;
                    this.savedMediaPref.MediaId = null; 
                    this.unsavedMediaPref.MediaId = null;
                    this.ngOnInit();          
                    this.loggingService.logVerbose("Cleared Session media selection");
                    this.notificationBarService.showSuccess("Cleared Session media selection");
                    
                  },
            error: () => {
                    this.loggingService.logVerbose("Error Clearing Session Media selection");
                    this.notificationBarService.showError("Error Clearing Session Media selection");  
                  },
            complete: () => {
                    this.loggingService.logVerbose("Successfully cleared Session Media selection");
                  }
        });
    }
  }

  public updateShowSlideShowChoice(showSlideShowChoice: boolean) {
    this.unsavedMediaPref.ShowSlideShow = showSlideShowChoice;
  }

  
}
