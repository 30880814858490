
<div class="borderBlue">
    <mat-table [dataSource]="ContactCategoryViewData" class="mat-elevation-z1" matSort>
        <ng-container matColumnDef="ContactCategoryName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Contact Category Name </mat-header-cell>
        </ng-container>

        <ng-container matColumnDef="EditDelete">
            <mat-header-cell *matHeaderCellDef>
                <div class="alignCenter">
                    Actions
                </div>
            </mat-header-cell>
        </ng-container>

        <ng-container matColumnDef="Error">
            <mat-header-cell *matHeaderCellDef>
                
            </mat-header-cell>
        </ng-container>
        
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    </mat-table>
</div>
<mat-table #dataTable [dataSource]="ContactCategoryViewData" class="mat-elevation-z1 notify-contactcategory-data-table" matSort >
    <ng-container matColumnDef="ContactCategoryName">
        <mat-cell *matCellDef="let element"> 
            <div *ngIf="!element.IsEditing">
                {{element.ContactCategoryName}} 
            </div>
            <div *ngIf="element.IsEditing">
                <mat-form-field style="margin-left: 0px;">
                    <input matInput [(ngModel)]="element.ContactCategoryName" maxlength="50" style="width:70%"> 
                </mat-form-field>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="EditDelete">
        <mat-cell *matCellDef="let element"> 
            <div class="alignCenter" *ngIf="element.IsDeletable == true"> 
                <div class="alignCenter" *ngIf="element.IsDeleted == true">
                    <button mat-icon-button (click)="undoDeleteContactCategory(element)" title="Undo">
                        <mat-icon>undo</mat-icon>
                    </button>
                </div>
                <div class="alignCenter" *ngIf="element.IsDeleted == false">
                    <div class="edit-mode" *ngIf="!element.IsEditing">
                        <button mat-icon-button (click)="startEdit(element)" title="Edit">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="deleteContactCategory(element)" title="delete">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <div class="edit-mode" *ngIf="element.IsEditing">
                        <button mat-icon-button (click)="editContactCategory(element)" title="Save">
                            <mat-icon>update</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEdit(element)" title="Cancel">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Error">
        <mat-cell *matCellDef="let element">
            <div></div>
        </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<mat-toolbar class="secondary">
    <mat-table [dataSource]="ContactCategoryViewData" class="mat-elevation-z1">
        <ng-container matColumnDef="ContactCategoryName">
            <mat-footer-cell *matFooterCellDef>
                <mat-form-field style="margin-left: 0px;">
                    <mat-label>Enter Category Name</mat-label>
                    <input matInput [(ngModel)]="newContactCategory.ContactCategoryName" maxlength="50" style="width:70%" (keyup)="CheckContactCategoryNameIsValid()">                                         
                </mat-form-field>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="EditDelete">
            <mat-footer-cell *matFooterCellDef>
                <div class="alignCenter">
                    <button mat-icon-button (click)="addContactCategory(newContactCategory)" title="Add Contact Category" [disabled] = !isValidContactCategoryName>
                        <mat-icon>add</mat-icon>
                    </button>
                    <button mat-icon-button (click)="cancelAdd()" title="Cancel">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </mat-footer-cell>
        </ng-container>    
        <ng-container matColumnDef="Error">
            <mat-footer-cell *matFooterCellDef>
                <mat-error class="alignCenter">
                    <div *ngIf="this.newContactCategory.ContactCategoryName.trim() != '' && !isValidContactCategoryName" style="font-size: smaller;">
                      Unique name is required
                    </div>                           
                </mat-error>
            </mat-footer-cell>
        </ng-container>    
        <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </mat-table>
</mat-toolbar>
