<div class="grid-equal-cols-container">
    <div class="contentBoxBorderRight contentBoxBorderTop" id="usersPanel">
        <div class="CaptionLabel">Users</div>
        <mat-selection-list [multiple]="false" #users (selectionChange)="onUserSelectionChange($event)" id="userList"> 
            <mat-list-option *ngFor="let usr of domainUsers" [value]="usr.UserId" [selected]="usr.UserId == selectedUserId">
                {{usr.Name}}
            </mat-list-option>                
        </mat-selection-list>
    </div>
    <div class="grid-equal-rows-container">
        <div class="contentBoxBorderBottom contentBoxBorderTop">
            <div class="CaptionLabel">Locations
                <span>&nbsp;&nbsp;&nbsp;&nbsp;<button  mat-raised-button class="fit-to-caption-height float-right" (click)="applyLocationsToDept()">
                    Apply to Dept<mat-icon>save</mat-icon>
                </button></span>
            </div>
            <mat-selection-list #locs (selectionChange)="onLocationSelectionChange($event)" id="locList"> 
                <mat-list-option *ngFor="let loc of locations" [value]="loc.LocationId" [(selected)]="loc.IsSelected">
                    {{loc.Name}}
                </mat-list-option>                    
            </mat-selection-list>
        </div>
        <div id="msgCatsPanel">
            <div class="CaptionLabel">Message Categories
                <span>&nbsp;&nbsp;&nbsp;&nbsp;<button  mat-raised-button class="fit-to-caption-height float-right" (click)="applyMsgCategoriesToDept()">
                    Apply to Dept<mat-icon>save</mat-icon>
                </button></span>
            </div>
            <mat-selection-list #msgcats (selectionChange)="onMsgCategorySelectionChange($event)" id="catList"> 
                <mat-list-option *ngFor="let mcat of messageCategories" [value]="mcat.MessageCategoryId" [(selected)]="mcat.IsSelected">
                    {{mcat.MessageCategoryName}}
                </mat-list-option>                    
            </mat-selection-list>
        </div>        
    </div>
</div>








