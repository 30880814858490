// Angular 
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
  })
export class UIElementService {

    constructor(        
    ) { }

    public getHeaderHeight(): number {
        return this.getHtmlElementHeight(this.getHtmlElementFromSelector("app-header"));
    }

    public getFooterHeight(): number {
        return this.getHtmlElementHeight(this.getHtmlElementFromSelector("app-footer"));
    }

    public getAppContentHeight(): number {  /* excludes header and footer */
        return this.getElementHeight("app-grid-content");
    }

    // Gets first HTML Element from class name
    public getHtmlElement(className: string) {
        return document.getElementsByClassName(className)[0] as HTMLElement;
    }

    public getHtmlElementFromSelector(selectorName: string) {
        return document.querySelector(selectorName) as HTMLElement;
    }

    public setElementHeight(elementName: string, height: number) {
        let elem = this.getHtmlElement(elementName);
        if (elem != undefined) {
            elem.style.height = height + "px"; 
        }
    }
    
    public setElementWidth(elementName: string, width: number) {
        let elem = this.getHtmlElement(elementName);
        if (elem != undefined) {
            elem.style.width = width + "px"; 
        }
    }

    public getElementHeight(className: string) {
        let elemHeight = 0;
        let elem = this.getHtmlElement(className);
        if (elem != undefined) {
            elemHeight = elem.offsetHeight; 
        }
        return elemHeight;
    }

    public getHtmlElementHeight(htmlElement:HTMLElement) {
        let elemHeight = 0;        
        if (htmlElement != undefined) {
            elemHeight = htmlElement.offsetHeight; 
        }
        return elemHeight;
    }
    
    public getElementWidth(className: string) {
        let elemWidth = 0;
        let elem = this.getHtmlElement(className);
        if (elem != undefined) {
            elemWidth = elem.offsetWidth; 
        }
        return elemWidth;
    }

    public getHtmlElementWidth(htmlElement:HTMLElement) {
        let elemWidth = 0;        
        if (htmlElement != undefined) {
            elemWidth = htmlElement.offsetWidth; 
        }
        return elemWidth;
    }
}