// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription, fromEvent, Observable } from 'rxjs';

// Material
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import {MatPaginator as MatPaginator, PageEvent as PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, CloudApiResponse, EncryptionService } from 'kscigcorelib';

// Application Core
import { SessionHelper } from '../../shared/helpers/session.helper';

// Component
import { MessageSendData } from '../../notify/notify-model';
import { NotifyStatsService } from './notify-stats.service';
import { UIElementService } from 'src/app/shared/services/uielement.service';


@Component({
  selector: 'app-notify-stats',
  templateUrl: './notify-stats.component.html',
  styleUrls: ['./notify-stats.component.scss']
})
export class NotifyStatsComponent {

  public customerId:string;
  public msgStatsDataSource = new MatTableDataSource<MessageSendData>();
  public msgStatsData: MessageSendData[] = [];  
  public totalAttempted: number = 0;
  public totalSent: number = 0;
  public totalFailed: number = 0;  
  public selectedNotifyType:string = "1";
  public selectedTimeFilter:string = "1";
  public columnsToDisplay = ['ContactPoint', 'SentOn', 'IsSent', /*'Carrier',*/ 'Provider', 'ErrorMessage'];
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private sessionHelper:SessionHelper,
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,    
    private encryptionService: EncryptionService,
    private notifyStatsService:NotifyStatsService,
    private elemService:UIElementService
    ) { }

  ngOnInit() {
    if(this.sessionHelper.isValidUserSession()){
      this.loadingBarService.startBar();
      this.customerId = this.sessionHelper.getCustomerId();      
      
      this.loadPageData();
    }
  }

  ngAfterViewInit() {
    this.msgStatsDataSource.paginator = this.paginator;        
    this.resizeElements();

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(e => {
      this.resizeElements();
    });
  }

  resizeElements() {   
    this.elemService.setElementHeight("stats-data", this.elemService.getElementHeight("settings-grid-item-treeContent") //window?.innerHeight - this.elemService.getHeaderHeight() - this.elemService.getFooterHeight()
                                - this.elemService.getElementHeight("CaptionLabelSquare")
                                - this.elemService.getElementHeight("stats-header")        
                                - this.elemService.getElementHeight("stats-paginator"));    
  }

  public loadPageData(){
    this.loadingBarService.startBar();
    this.notifyStatsService.getStats(this.customerId, this.selectedTimeFilter, this.selectedNotifyType)
      .subscribe({
          next: (messageSendDataResult: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(messageSendDataResult.payload)
                this.loggingService.logVerbose(decryptedPayload);
                let messageSendData:MessageSendData[] = JSON.parse(decryptedPayload); 
                
                this.msgStatsData = messageSendData;
                this.msgStatsDataSource.data= this.msgStatsData;
                
                this.totalAttempted = this.msgStatsData.length;
                this.totalSent = this.msgStatsData.filter(m => m.IsSent).length;
                this.totalFailed = this.msgStatsData.filter(m => !m.IsSent).length;
                
              },
          error: () => { this.loggingService.logError("Error getting Message send stats"); this.loadingBarService.stopBar(); },
          complete: () => { this.loggingService.logVerbose("Completed getting Message send stats"); this.loadingBarService.stopBar(); }
      });
  }
  
}
