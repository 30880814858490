// Angular Modules
import { NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

// Application Modules
import { AppRoutingModule } from './app-routing.module';
import { SettingsModule } from './settings/settings.module';

// Shared Lib
import { KscigcorelibModule } from 'kscigcorelib';
import { MaterialModule } from 'kscigcorelib';
import { EnvServiceProvider, EnvService } from 'kscigcorelib';

// Application Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { WorklistComponent } from './worklist/worklist.component';
import { NotifyComponent } from './notify/notify.component';
import { MediaComponent } from './media/media.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './login/login.component';
import { ActivityComponent } from './activity/activity.component';
import { ReportsComponent } from './reports/reports.component';
import { MediaPlayComponent } from './media-play/media-play.component';
import { ContactEditorComponent } from './shared/components/contact-editor/contact-editor.component';
import { HelpGuideComponent } from './help-guide/help-guide.component';

import { environment } from 'src/environments/environment';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { MediaPlayerShakaComponent } from './media-play/media-player-shaka/media-player-shaka.component';
import { MediaPlayerAmpComponent } from './media-play/media-player-amp/media-player-amp.component';
import { ValidateInputDialogComponent } from './media-play/validate-input-dialog/validate-input-dialog.component';
import { WorklistitemEditorComponent } from './worklist/worklistitem-editor/worklistitem-editor.component';

//import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { GraphDrilldownComponent } from './reports/graph-drilldown/graph-drilldown.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const eService = inject(EnvService);
  return new PublicClientApplication({
    auth: {
      authority: eService.authority,
      clientId: eService.uiClienId,
      redirectUri: eService.redirectUrl,
      postLogoutRedirectUri: eService.postLogoutRedirectUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: environment.production? LogLevel.Error: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const eService = inject(EnvService);
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(eService.GRAPH_ENDPOINT, ['user.read']);
  protectedResourceMap.set(eService.apiUrl, [eService.APIScopeUri]);
  
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
          'user.read',
        ]
    }
  };
}

@NgModule({
    declarations: [
        AppComponent,
        WorklistComponent,
        NotifyComponent,
        MediaComponent,
        FooterComponent,
        HeaderComponent,
        HelpGuideComponent,
        HomeComponent,
        LogoutComponent,
        LoginComponent,
        ActivityComponent,
        ReportsComponent,
        MediaPlayComponent,
        ContactEditorComponent,
        NotFoundComponent,
        MediaPlayerShakaComponent,
        MediaPlayerAmpComponent,
        ValidateInputDialogComponent,
        WorklistitemEditorComponent,
        GraphDrilldownComponent       
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SettingsModule,
        BrowserAnimationsModule,
        NgxChartsModule,
        AppRoutingModule,
        MaterialModule,
        KscigcorelibModule,
        MsalModule,
        BrowserModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        //NgxMatDatetimePickerModule,
        //NgxMatNativeDateModule,
        //NgxMatTimepickerModule       
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        EnvServiceProvider,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
