// Angular
import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { Subscription, fromEvent, Observable } from 'rxjs';
import { UntypedFormBuilder, FormGroup,FormControl,Validators } from '@angular/forms';
import {MatPaginator as MatPaginator, PageEvent as PageEvent} from '@angular/material/paginator';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {MatTableDataSource as MatTableDataSource} from '@angular/material/table';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';

// Shared Lib
import { CloudApiResponse, Patient } from 'kscigcorelib';
import { NotificationBarService, LoadingBarService, LoggingService, EncryptionService, MessageBoxModel, MessageBoxType, MessageBoxComponent } from 'kscigcorelib';

// Application
import { SessionHelper } from '../shared/helpers/session.helper';
import { RouteHelper } from '../shared/helpers/route.helper';
import { NotifyService } from '../notify/notify.service';
import { WorklistService } from './worklist.service';
import { WorklistItem, Location, Criteria, WLSMSConsentFilter, WLTimeFilter, WorklistPref, WorklistItemViewModel, SessionPatient } from '../shared/models/worklist.interface';
import { appPageName } from '../shared/constants/app.enum';
import { WorklistitemEditorComponent } from './worklistitem-editor/worklistitem-editor.component';
import { SharedPatientService } from '../shared/services/sharedpatient.service';
import { UserCookieService } from '../shared/services/ksusercookie.service'
import { ActivityService } from '../activity/activity.service';
import { EnumUserAction } from '../activity/activity.enum'
import { UIElementService } from '../shared/services/uielement.service';


@Component({
  selector: 'app-worklist',
  templateUrl: './worklist.component.html',
  styleUrls: ['./worklist.component.css']
})

export class WorklistComponent implements OnInit, AfterViewInit, OnDestroy {

  public customerId:string;
  public sessionId:string
  public isModuleEnabled:boolean = false;
  public worklistItems:WorklistItemViewModel[] = [];
  public displayedColumns:string[] = ['PatId', 'PatientName', 'G', 'DOB', 'SurgeryDT', 'Surgeon', 'Procedure'];      
  public Criterias:Criteria[] = [];
  public Locations:Location[] = [];
  public WLTimes:WLTimeFilter[] = [];
  public SMSConsentOptions:WLSMSConsentFilter[] = [];
  public worklistSearchForm: FormGroup;
  public isKeywordSearchDisabled:boolean = true;
  public patientsDataSource = new MatTableDataSource<WorklistItem>();
  public currentWorklistItem: WorklistItem = null;  
  public selection = new SelectionModel<WorklistItem>(true, []);
  public noPatientsFound: boolean = true;  
  public itemsPerPage: number = 15;
  private wlRefreshInterval:NodeJS.Timer;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

 
  constructor(
    private worklistService: WorklistService,
    private notifyService: NotifyService,
    private loggingService:LoggingService,
    private sessionHelper:SessionHelper,
    private encryptionService:EncryptionService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,
    private fb: UntypedFormBuilder,
    private patientService: SharedPatientService,
    private routeHelper: RouteHelper,
    private dialog: MatDialog,
    private userCookieService: UserCookieService,
    private elemService: UIElementService,
    @Inject(LOCALE_ID) public locale: string
    ) { }

  ngOnInit() {    
    if(this.sessionHelper.isValidUserSession()){
      // Start loading bar
      this.loadingBarService.startBar();
      this.isModuleEnabled = this.sessionHelper.isWorklistModuleEnabled();
      if(this.isModuleEnabled){
        this.customerId = this.sessionHelper.getCustomerId();
        this.sessionId = this.sessionHelper.getSessionId();

        this.worklistSearchForm = new FormGroup({
          searchCriteria: new FormControl(null, Validators.required),
          searchLocation: new FormControl(null, Validators.required),
          searchTime: new FormControl(null, Validators.required),
          searchSMSConsent: new FormControl(null, Validators.required),
          searchKeyword: new FormControl(null, Validators.required),
        });
        this.patientService.clearBulkNotifyPatients();
        this.sessionHelper.clearBulkNotifyPatients();
        // Get Page data
        this.loadPageData();
      }
    } else {
      this.loggingService.logVerbose("Invalid user session");
    }
  }

  ngAfterViewInit() {
    // Case insensitive sorting    
    this.patientsDataSource.sortingDataAccessor = (dataItem: any, property: string) => {
      
      switch(property) {
        case 'SurgeryDT': return new Date(dataItem.SurgeryDT).getTime();
        case 'DOB': return new Date(dataItem.DOB);
        default: if (typeof dataItem[property] === 'string') {
                    return dataItem[property].toLocaleLowerCase();
                 }    
                 return dataItem[property];
      }
    };
    this.patientsDataSource.sort = this.sort; 
    this.sort.disableClear = true; // Disable clearing the sort state
    this.patientsDataSource.paginator = this.paginator;      

    this.resizeElements();

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(e => {
      this.resizeElements();
    });
  }
  
  resizeElements() {
    this.elemService.setElementHeight("worklist-data", window?.innerHeight - this.elemService.getHeaderHeight() - this.elemService.getFooterHeight()
                                        - this.elemService.getElementHeight("worklist-header") - 10 // margin-top for worklist-header
                                        - this.elemService.getElementHeight("worklist-paginator"));   
    // 272    
  }

  loadPageData(){
    // Patient Search Criterias
    this.Criterias = [
      {
        criteriaId: 1,
        criteriaName:'Location',
      },{
        criteriaId: 2,
        criteriaName:'PatientId',
      },{
        criteriaId: 3,
        criteriaName:'Last Name',
      },{
        criteriaId: 4,
        criteriaName:'First Name',
      }
    ];


    let wlPrefCookie = this.userCookieService.getWorklistPreference();    
    // set default ceriteria if not found in cookie
    this.getSearchCriteriaControl().setValue(wlPrefCookie.WLCriteria != null ? wlPrefCookie.WLCriteria.criteriaId : this.Criterias[0].criteriaId);

    // Set keyword stored in the session
    if(this.getSearchCriteria() > 1){ // Only load keyword if criteria is not location
      var keyword = this.sessionHelper.getPatientSearchKeyword();
      if(keyword != null){
        this.getSearchKeywordControl().setValue(keyword);
        this.search();
      }
    }
    
    this.displayedColumns = ['PatId', 'PatientName', 'G', 'DOB', 'SurgeryDT', 'Surgeon', 'Procedure'];
    this.searchCriteriaChanged(this.getSearchCriteria(), false);
    this.worklistService.getTimeFilters(this.customerId)
      .subscribe({
        next: (timeFiltersResult:CloudApiResponse)=> {
          var decryptedTimeFiltersPayload = this.encryptionService.decryptUsingAES256(timeFiltersResult.payload);
          this.loggingService.logVerbose(decryptedTimeFiltersPayload);
          this.WLTimes = JSON.parse(decryptedTimeFiltersPayload);
          if(this.WLTimes != null) {

            if(this.WLTimes.length > 0)
              this.getTimeFilterControl().setValue(wlPrefCookie.WLTimeFilter != null ? wlPrefCookie.WLTimeFilter.FilterNumber : this.WLTimes[0].FilterNumber);
            
            this.worklistService.getSMSConsentFilters(this.customerId)
              .subscribe({
                  next: (smsConsentFiltersResult:CloudApiResponse)=>{
                        var decryptedSMSConsentFiltersPayload = this.encryptionService.decryptUsingAES256(smsConsentFiltersResult.payload);
                        this.loggingService.logVerbose(decryptedSMSConsentFiltersPayload);
                        this.SMSConsentOptions = JSON.parse(decryptedSMSConsentFiltersPayload);
                        if(this.SMSConsentOptions != null) {
                          if(this.SMSConsentOptions.length > 0){
                            this.getSMSConsentFilterControl().setValue(wlPrefCookie.WLSMSConsentFilter != null ? wlPrefCookie.WLSMSConsentFilter.FilterNumber : this.SMSConsentOptions[0].FilterNumber);
                          }
                          
                          this.worklistService.getLocations(this.customerId, true)
                            .subscribe({ 
                                next: (locationsResult:CloudApiResponse) => {
                                      var decryptedLocationsPayload = this.encryptionService.decryptUsingAES256(locationsResult.payload);
                                      this.loggingService.logVerbose(decryptedLocationsPayload);
                                      this.Locations = JSON.parse(decryptedLocationsPayload);
                                      if(this.Locations != null && this.Locations.length > 0) {

                                        this.getLocationFilterControl().setValue(wlPrefCookie.WLLocationFilter != null ? wlPrefCookie.WLLocationFilter.LocationId : this.Locations[0].LocationId);
                                                                                    
                                        this.sort.active = wlPrefCookie.WLSortBy;
                                        this.sort.direction = wlPrefCookie.WLSortOrder as SortDirection;
                                        this.itemsPerPage = wlPrefCookie.WLItemsPerPage;
                                        
                                        if (wlPrefCookie.WLCriteria == null || wlPrefCookie.WLCriteria.criteriaId == 1)
                                          this.filterWorklist();
                                        else
                                          this.loadingBarService.stopBar();
                                      } else {
                                        this.notificationBarService.showError("No locations fetched");
                                        // stop loading bar
                                        this.loadingBarService.stopBar();
                                      }                                    
                                    },
                                error: () => { this.loggingService.logError("Error fetching locations"); },
                                complete: () => { this.loggingService.logVerbose("Completed fetching locations"); }
                            });
                          } else {
                            this.notificationBarService.showError("No SMS Consent Filters fetched");
                          }
                        },
                  error: () => { this.loggingService.logError("Error fetching SMS filters"); },
                  complete: () => { this.loggingService.logVerbose("Completed fetching SMS filters"); }
              });
          } else {
            this.notificationBarService.showError("No Time Filters fetched");
          }
        }
      });
  }

  
  filterWorklist() {
    clearInterval(this.wlRefreshInterval);
    this.refreshWorklist();
    this.wlRefreshInterval = setInterval(() => {
      this.refreshWorklist();
    }, 90000);   // 90 seconds interval
  }

  refreshWorklist() {
    this.loadingBarService.startBar();  
    
    var criteria = this.getSearchCriteria();
    if(criteria > 1 && this.currentWorklistItem != null){
      
    }


    this.worklistService.getPatients(this.customerId, this.getSearchCriteria(), this.getLocationFilter(), 
                                          this.getTimeFilter(), this.getSMSConsentFilter(), this.getSearchKeyword()) 
      .subscribe({
          next: (wlResult:CloudApiResponse)=>{
                var decryptedWLPayload = this.encryptionService.decryptUsingAES256(wlResult.payload);
                this.loggingService.logVerbose(decryptedWLPayload);
                var wlItems = JSON.parse(decryptedWLPayload);
                var tempWLtems:WorklistItemViewModel[] = [];
                if (wlItems != null) {
                  wlItems.forEach(element => {
                    let wlViewModel = new WorklistItemViewModel();
                    wlViewModel.UserAddedWorklistId = element.UserAddedWorklistId;
                    wlViewModel.PatGId = element.PatGId;
                    wlViewModel.PatId = element.PatId;
                    wlViewModel.FN = element.FN;
                    wlViewModel.LN = element.LN;
                    wlViewModel.MN = element.MN;
                    wlViewModel.PatientName = element.LN + ", " + element.FN;
                    wlViewModel.G = element.G;
                    wlViewModel.DOB = this.getProperDOB(element.DOB);
                    wlViewModel.Loc = element.Loc;
                    wlViewModel.Procedure = element.Procedure;
                    wlViewModel.SurgeryDT = formatDate(new Date(this.getProperSurgeryDT(element.SurgeryDT)), 'yyyy-MM-dd H:mm', this.locale);
                    wlViewModel.Surgeon = element.Surgeon;
                    wlViewModel.AN = element.AN;
                    wlViewModel.ByUser = element.ByUser;
                                
                    tempWLtems.push(wlViewModel);
                  });
                  this.worklistItems = tempWLtems;
    
                  if (this.currentWorklistItem == null) { 
                    // handle partial/full page reload - refresh from Patient service / session
                    let patient:Patient = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getCurrentPatient()));
                    if (patient == null) {
                      patient = this.sessionHelper.getSelectedPatient();
                    }
                    if (patient != null) {
                      let wli = this.worklistItems.find(m => m.PatGId == patient.PatientGuid);
                      if (wli != null)
                        this.currentWorklistItem = wli;                      
                    }
                  }

                }
                this.patientsDataSource.data = this.worklistItems;
                
                this.refreshColumnVisibility();
                this.noPatientsFound = this.patientsDataSource.data.length == 0;
                
              },
          error: () => { 
              this.loggingService.logError("Error getting Patients"); 
              // stop loading bar
              this.loadingBarService.stopBar();},
          complete: () => { 
              this.loggingService.logVerbose("Completed getting Patients"); 
              // stop loading bar
              this.loadingBarService.stopBar();
            }
      });    
  }
    
  getSearchCriteriaControl() {    
    return this.worklistSearchForm.get('searchCriteria');
  }

  getTimeFilterControl() {    
    return this.worklistSearchForm.get('searchTime');
  }

  getLocationFilterControl() {    
    return this.worklistSearchForm.get('searchLocation');
  }

  getSMSConsentFilterControl() {    
    return this.worklistSearchForm.get('searchSMSConsent');
  }

  getSearchKeywordControl() { 
    return this.worklistSearchForm.get('searchKeyword');
  }
  

  getSearchCriteria() {
    return this.getSearchCriteriaControl().value;
  }

  getTimeFilter() {
    return this.getTimeFilterControl().value ?? 0;
  }

  getLocationFilter() {    
    return this.getLocationFilterControl().value;
  }

  getSMSConsentFilter() {    
    return this.getSMSConsentFilterControl().value ?? 0;
  }

  getSearchKeyword() {
    return this.getSearchKeywordControl().value;
  }

  searchCriteriaChanged(value, pageLoadComplete:boolean = true)  {
    let locationFilter = this.getLocationFilterControl();
    let timeFilter = this.getTimeFilterControl();
    let smsConsentFilter = this.getSMSConsentFilterControl();
    let searchKeyword = this.getSearchKeywordControl();

    if(value == 1) {
      locationFilter.enable();
      timeFilter.enable();
      smsConsentFilter.enable();
      searchKeyword.disable();

      searchKeyword.setValue("");
      locationFilter.setValue(1); // show the first location
      this.isKeywordSearchDisabled = true;
      if (pageLoadComplete) {
        this.filterWorklist(); // perform search when location criteria is secleted 
      }
    } else { //
      locationFilter.setValue(0);
      timeFilter.setValue(0);
      smsConsentFilter.setValue(0);

      locationFilter.disable();
      timeFilter.disable();
      smsConsentFilter.disable();

      searchKeyword.enable();
      this.isKeywordSearchDisabled = false;
      this.patientsDataSource.data = this.worklistItems = [];

      // If patient is selected for the session then restore the keyword from the session
      if(this.sessionHelper.getSelectedPatient() != null){
        var keyword = this.sessionHelper.getPatientSearchKeyword();
        if(keyword != null){
          this.getSearchKeywordControl().setValue(keyword);
          this.search();
        }
      }
    }
    
    var criteria = this.Criterias.find(m=>m.criteriaId == value);
    if (criteria != undefined) {
      this.loggingService.logDebug("Search Criteria changed: " + criteria.criteriaName);
      // update cookie
      this.userCookieService.setCriteria(criteria);      
    }
  }
  
  locationChanged() {
    var loc = this.Locations.find(m=>m.LocationId == this.getLocationFilter());
    if (loc != undefined) {
      this.loggingService.logDebug("Location filter changed: " + loc.Name);
      this.filterWorklist();
      // update cookie
      this.userCookieService.setLocationFilter(loc); 
    }
  }

  timeFilterChanged() { 
    this.loggingService.logDebug("Time filter changed: " + this.getTimeFilter());
    this.filterWorklist();

    // update cookie
    this.userCookieService.setTimeFilter(this.WLTimes.find(m=>m.FilterNumber == this.getTimeFilter()));    
  }

  smsConsentFilterChanged() {
    this.loggingService.logDebug("SMS consent filter changed: " + this.getSMSConsentFilter());
    this.clearBulkNotifySelection();
    this.filterWorklist();
    // update cookie
    this.userCookieService.setSMSConsentFilter(this.SMSConsentOptions.find(m=>m.FilterNumber == this.getSMSConsentFilter())); 
  }

  sortChanged(sortEvt: Sort) { 
    this.loggingService.logDebug("Sort changed: " + sortEvt.active + " " + sortEvt.direction);
    // update cookie
    this.userCookieService.setSortInfo(sortEvt.active, sortEvt.direction);
  }

  // Function to search based on keyword
  search() {
    // Only enabling search if the criteria is not location 
    if(this.getSearchCriteria() > 1){
      this.filterWorklist();    
    }
  }
    
  // Display Action column only if worklistItem[] contains ByUser=true records
  refreshColumnVisibility() { 
    
    let selectorCol: string = "Selector";
    let actionCol: string = "Action";    
    let isSMSOptionVisible = this.SMSConsentOptions.length > 1;
    let isWlByUser = this.patientsDataSource.data.find(m => m.ByUser) != undefined;
        
    // Remove Selector col
    if (this.displayedColumns.find(m=>m == selectorCol) != undefined) {
        this.displayedColumns.splice(0, 1);  
    }
      
    if (isWlByUser)  {  
      if (this.displayedColumns.find(m=>m == actionCol) == undefined) {
        this.displayedColumns.splice(0, 0, actionCol);
      }        
    }
    else {
      if (this.displayedColumns.find(m=>m == actionCol) != undefined) {
        this.displayedColumns.splice(0, 1);
      }        
    }

    if (isSMSOptionVisible) { // Add Selector Col
      this.displayedColumns.splice(0, 0, selectorCol);
    }
    
  }
 
  selectPatient(wlItem: any) {
    // deselect current patient if any before selecting patient
    this.deselectPatient();

    // Save Keyword in the session 
    if(this.getSearchCriteria() > 1){
      this.sessionHelper.setPatientSearchKeyword(this.getSearchKeyword());  
    }

    let sessionPatient:SessionPatient = new SessionPatient();
    sessionPatient.sessionId = this.sessionId;
    sessionPatient.patientGuid = wlItem.PatGId;

    this.worklistService.selectOrDeselectPatient(this.customerId, sessionPatient, true)
          .subscribe({
              next: (result:CloudApiResponse)=>{                 
                    var parsedResult = JSON.parse(result.payload);
                    this.loggingService.logVerbose(parsedResult); 

                    let patient = new Patient();
                      patient.PatientGuid = wlItem.PatGId;
                      patient.PatientId = wlItem.PatId;
                      patient.Name = wlItem.FN + " " + (wlItem.MN ? wlItem.MN + " " : '' ) + wlItem.LN;
                      patient.DOB = wlItem.DOB;
                      patient.Gender = wlItem.G;

                    if(parsedResult) {

                      this.currentWorklistItem = wlItem;
                      this.sessionHelper.setSelectedPatient(patient);                                        
                      this.patientService.setCurrentPatient(this.encryptionService.encryptUsingAES256(JSON.stringify(patient)));
                      this.loggingService.logDebug("Selected patient " + patient.Name);
                      
                    } else {
                      this.notificationBarService.showError("Failed to Select Patient"); 
                      this.loggingService.logDebug("Failed to Select Patient " + patient.Name);                 
                    }
        
                    // navigate to next tab
                    if (this.sessionHelper.isNotifyModuleEnabled()) {
                      this.routeHelper.RoutePage(appPageName.notify);
                    } else if (this.sessionHelper.isMediaModuleEnabled()) {
                      this.routeHelper.RoutePage(appPageName.media);
                    }
                  },
              error: () => { this.loggingService.logError("Error Selecting Patient " + wlItem.PatGId); },
              complete: () => { this.loggingService.logVerbose("Completed Selecting Patient " + wlItem.PatGId); }
          });
  }

  deselectPatient() {
    var currPatient = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getCurrentPatient()));
    if (currPatient != null) {

      let sessionPatient:SessionPatient = new SessionPatient();
      sessionPatient.sessionId = this.sessionId;
      sessionPatient.patientGuid = this.currentWorklistItem.PatGId;
      
      this.worklistService.selectOrDeselectPatient(this.customerId, sessionPatient, false)
            .subscribe({
                next: (result:CloudApiResponse) => {
                      var parsedResult = JSON.parse(result.payload);
                      this.loggingService.logVerbose(parsedResult); 

                      if(parsedResult) {
                        this.currentWorklistItem = null;
                        this.sessionHelper.clearSelectedPatient();
                        this.patientService.clearCurrentPatient();                        
                        this.loggingService.logDebug("Successfully deselected patient " + currPatient.Name);
                        // Save Keyword in the session 
                        this.sessionHelper.clearPatientSearchKeyword();
                      }
                    },
                error: () => { this.loggingService.logError("Error Deselecting Patient " + currPatient.PatientGuid); },
                complete: () => { this.loggingService.logVerbose("Completed Deselecting Patient " + currPatient.PatientGuid); }
            });
    }
  }

  getProperSurgeryDT(surgeryDateTime:string):string{
    var surgeryDT:string;
    if(surgeryDateTime != null){
      surgeryDT = surgeryDateTime.replace('T', ' ');
    }
    return surgeryDT;
  }

  getProperDOB(dateOfBirth:string):string{
    var dob:string;
    var index:number;
    if(dateOfBirth != null){
      index = dateOfBirth.indexOf('T');
      dob = dateOfBirth.substring(0, index);
    }
    return dob;
  }

  openWLItemEditor(addWLItem: boolean, wlItemData: WorklistItem) {

    const dialogRef = this.dialog.open(WorklistitemEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: { wlItem: wlItemData, locations: this.Locations, isAddItem: addWLItem, customerId: this.customerId, sessionId: this.sessionId },
      minWidth: '40vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The ' + addWLItem ? 'add' : 'edit' + ' worklist dialog was closed'); 
      if(result){
        this.filterWorklist();
      }     
    });
  }
  
  openAddPatientDialog() {    
    let worklistItemData = new WorklistItem();
    this.openWLItemEditor(true, worklistItemData);    
  }

  openEditPatientDialog(wlItem: WorklistItem) {    
    this.openWLItemEditor(false, wlItem);
  }

  deletePatient(wlItem: WorklistItemViewModel) {
    this.deselectPatient();
    this.loggingService.logDebug("Delete clicked on " + wlItem);
    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to delete patient '" + wlItem.PatientName + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loggingService.logVerbose("Delete initiated");
        this.worklistService.deletePatient(this.customerId, wlItem.UserAddedWorklistId.toString())
            .subscribe({
                next: () => {
                        this.notificationBarService.showSuccess("Deleted patient " +  wlItem.PatientName);
                        this.loggingService.logInformation("Patient " +  wlItem.PatientName + " (worklist id " + wlItem.UserAddedWorklistId + ") has been deleted");
                        
                        // Remove the item from worklist
                        this.worklistItems = this.worklistItems.filter(m=>m.UserAddedWorklistId != wlItem.UserAddedWorklistId);
                        this.patientsDataSource.data = this.worklistItems;
                        // let contacts:NotifyContact[] = [];
                        // this.contactList.forEach(x=> {
                        //   if(x.contactId != contact.contactId){
                        //     contacts.push(x);
                        //   }
                        // });             
                        // this.contactList = contacts;     
                    },
                error: () => {
                        this.notificationBarService.showError("Error deleting patient " +  wlItem.PatientName);
                        this.loggingService.logError("Error deleting patient " +  wlItem.PatientName);
                      },
                complete: () => { this.loggingService.logVerbose("Completed deleting patient " +  wlItem.PatientName); }
            });
      } else {
        this.loggingService.logVerbose("Delete was cancelled");
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.patientsDataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() { 
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.patientsDataSource.data);
  }

  clearBulkNotifySelection() {
    this.selection.clear();
    this.patientService.clearBulkNotifyPatients();
    this.sessionHelper.clearBulkNotifyPatients();
  }
  
  handlePageEvent(evt:PageEvent) {
    this.userCookieService.setItemsPerPage(evt.pageSize);
  }

  notifyPatients() {    
    this.loadingBarService.startBar();    
    var patientGuids:string[] = [];
    var patientsSelected = this.selection.selected;
    patientsSelected.forEach(pat => {
      patientGuids.push(pat.PatGId);        
    });
    
    this.patientService.setBulkNotifyPatients(this.encryptionService.encryptUsingAES256(JSON.stringify(patientGuids)));  
    this.sessionHelper.setBulkNotifyPatients(patientGuids);  
    this.loadingBarService.stopBar(); 
    // navigate to next tab
    if (this.sessionHelper.isNotifyModuleEnabled()) {
      this.routeHelper.RoutePage(appPageName.notify);
    }
  }

  sendOptInSMS() {
    this.loadingBarService.startBar();    
    var patientGuids:string[] = [];
    var patientsSelected = this.selection.selected;
    patientsSelected.forEach(pat => {
      patientGuids.push(pat.PatGId);        
    });
        
    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to send Opt-In Text to all selected patients?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loggingService.logVerbose("Sending Op-In text to selected patients");
        this.notifyService.sendOptInTextToPatients(this.customerId, patientGuids)
            .subscribe({
                next: () => {
                        this.notificationBarService.showSuccess("Sent welcome text to selected patients");
                        this.loggingService.logInformation("Sent welcome text to selected patients");                                                
                    },
                error: () => {
                        this.notificationBarService.showError("Error sending Opt-In text to selected patients");
                        this.loggingService.logError("Error sending Opt-In text to selected patients");
                      },
                complete: () => { this.loggingService.logVerbose("Completed sending Opt-In text to selected Patients"); }
            });
      } else {
        this.loggingService.logVerbose("Sending Op-In text was cancelled");
      }
    });

    this.loadingBarService.stopBar(); 
    
  }

  ngOnDestroy() {
    if (this.wlRefreshInterval) {
      clearInterval(this.wlRefreshInterval);
    }
  }
}
