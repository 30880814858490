export class MessageViewModel {
    messageId: string;
    customerId: string;
    messageCategoryId: string;
    messageCategoryName: string;
    languageId:string;
    languageName:string;
    messageText:string;
    isDeleted: boolean;
    isOldEditedEntry: boolean;

    constructor(){
            this.messageId = null;
            this.customerId = null;
            this.messageCategoryId = null;
            this.messageCategoryName = '';
            this.languageId = null;
            this.languageName = '';
            this.messageText = '';
            this.isDeleted = false; 
            this.isOldEditedEntry = false; 
        }
}