<div class="borderBlue" style="height: calc(100% - 37px);">
    <button mat-icon-button class="square" style="float: right;top:0px; margin-right:5px;" (click)="openMessageEditor(null)" *ngIf="showAddMessage" title="Add Message">
        <mat-icon>add</mat-icon>
    </button>

    <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms" style="display:inline;" (animationDone)="contactCategoryChanged()">
        <mat-tab [label]="contactCategoryName" *ngFor="let contactCategoryName of contactCategoryNames">
            <ng-template mat-tab-label>
                <span>{{contactCategoryName}}</span>                
            </ng-template>

            <!-- Message Category tabs -->
            <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms" style="display:inline;" (selectedTabChange)="messageCategoryChanged($event)">
                <ng-container *ngFor="let messageCategory of messageCategoryList" >
                    <mat-tab [label]="messageCategory.MessageCategoryId" *ngIf="messageCategory.ContactCategoryName == contactCategoryName" >
                        
                        <ng-template mat-tab-label >
                            <span>{{messageCategory.MessageCategoryName}}</span>                        
                        </ng-template>                        
                        <div class="overflowAuto" style="height: calc(100vh - 64px - 37px - 48px - 48px - 86px);" >                                                                                                                
                            <mat-list role="list" *ngIf="getMessageCountInCategory(messageCategory.MessageCategoryId) > 0" style="max-width: 100%;">                                
                                <ng-container *ngFor="let message of messageList">                                    
                                    <mat-list-item *ngIf="message.MessageCategoryId == messageCategory.MessageCategoryId">
                                        <div class="message-row-grid text-wrapper">
                                            <div style="margin-top: 10px;">
                                                <div *ngIf="message.LanguageName!='English'" style="margin-left: 20px;">
                                                    {{message.MessageText}}                        
                                                </div>
                                                <div *ngIf="message.LanguageName=='English'">
                                                    {{message.MessageText}}                        
                                                </div>
                                            </div>
                                            <div style="text-align: right;">
                                                <div *ngIf="message.LanguageName=='English'">
                                                    <span *ngIf="!message.IsDeleted">
                                                        <button mat-icon-button (click)="openMessageEditor(message)" title="Edit">
                                                            <mat-icon>edit</mat-icon>
                                                        </button>
                                                        <button mat-icon-button (click)="deleteMessage(message)" *ngIf="!messageCategory.IsSystem" title="Delete">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                    </span>
                                                    <span *ngIf="message.IsDeleted">
                                                        <button mat-icon-button (click)="undoDeleteMessage(message)" title="Undo">
                                                            <mat-icon>undo</mat-icon>
                                                        </button>
                                                    </span>
                                                </div>
                                                <div *ngIf="message.LanguageName!='English'" style="margin-top: 10px;">
                                                    ({{message.LanguageName}})
                                                </div>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                </ng-container>
                            </mat-list>
                            <div *ngIf="getMessageCountInCategory(messageCategory.MessageCategoryId) == 0" >
                                <div style="padding:20px;" >
                                    No messages found
                                </div>
                            </div>
                        </div>
                        
                    </mat-tab>
                </ng-container>
            </mat-tab-group>

        </mat-tab>
    </mat-tab-group>

    <!-- <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms" style="display:inline;" (selectedTabChange)="messageCategoryChange($event)">
        <mat-tab [label]="messageCategory.MessageCategoryId" *ngFor="let messageCategory of messageCategoryList">
            <ng-template mat-tab-label>
                <span>{{messageCategory.MessageCategoryName}}</span>
                &nbsp;
                <span style="font-size: 8px;">({{messageCategory.ContactCategoryName}})</span>
            </ng-template>
            
        </mat-tab>
    </mat-tab-group> -->
</div>