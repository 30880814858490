<div *ngIf="!isModuleEnabled">
  <div class="alignCenter Padding5px">
      Oops! You are not subscribed to this module.
  </div>
</div>

<div *ngIf="isModuleEnabled" class="notify-grid">
  <!-- Notify Contacts Grid -->
  <div class="contentBox notify-grid-item-contacts" *ngIf="this.isMessageDataLoaded && this.isContactDataLoaded">
    <button mat-icon-button class="square" style="float: right;top:5px; right:6px;" (click)="openContactEditor(null)" *ngIf="isDisplayPatientContactAdd" title="Add Contact">
      <mat-icon>person_add</mat-icon>
    </button>
    <mat-tab-group mat-stretch-tabs="false" #contactCategoryTab animationDuration="0ms" (selectedTabChange)="ContactCategoryTabChange($event)" style="display:inline;">
      <mat-tab [label]="option.ContactCategoryId" *ngFor="let option of contactCategoryList">
        <ng-template mat-tab-label>
          <span>{{option.ContactCategoryName}}</span>
        </ng-template>
          <div class="contactList overflowAuto" [ngStyle]="{'height': getContactListHeight()}">
            <div *ngIf="contactListToShow.length > 0">
              <div *ngFor="let contact of contactListToShow">
                  <div class="notify-grid-contactList borderBlue padding10px">
                      <div>
                          <div class="notify-grid-contactnameRow">
                            <mat-checkbox [checked]="contact.IsContactPreferred" [(ngModel)]="contact.IsContactPreferred" style="margin-top: -10px;"></mat-checkbox>
                            <div style="margin-left: 10px;">
                              {{contact.FirstName}}&nbsp;{{contact.LastName}}
                            </div>
                            <div style="text-align: center;">
                              &nbsp;&nbsp;&nbsp;&nbsp;<span *ngIf="contact.Alias != null && contact.Alias != ''"> ({{contact.Alias}})</span>
                            </div>
                          </div>
                          <div class="padding-left-30">                                  
                              <div *ngIf="contact.Mobile != null && contact.Mobile != ''">
                                  <mat-checkbox [checked]="contact.IsSMSPreferred" [(ngModel)]="contact.IsSMSPreferred" style="padding-top:-90px;"></mat-checkbox>
                                  <mat-icon style="vertical-align: middle;">phone</mat-icon>
                                  <span *ngIf="contact.MobileCountryCode == '+1'">&nbsp;&nbsp;{{contact.MobileCountryCode}}({{contact.Mobile.slice(0,3)}}){{contact.Mobile.slice(3,6)}}-{{contact.Mobile.slice(6,10)}}</span>
                                  <span *ngIf="contact.MobileCountryCode != '+1'">&nbsp;&nbsp;{{contact.MobileCountryCode}}{{contact.Mobile}}</span>
                                  <span *ngIf="isSMSOptInEnabled">
                                    <span *ngIf="contact.ContactType == 1">
                                      <span *ngIf="isDisplayPatientContactAdd && contact.OptInStatusEnumVal == 0" class="dot disabledMessageBlock cursorPointer" style="margin-left: 10px;margin-bottom: -4px;" (click)="verifyOptInStatus(contact)"></span>
                                      <span *ngIf="isDisplayPatientContactAdd && contact.OptInStatusEnumVal == 1" class="dot orangeCircularBorder cursorPointer" style="margin-left: 10px;margin-bottom: -4px;" (click)="verifyOptInStatus(contact)"></span>
                                      <span *ngIf="isDisplayPatientContactAdd && contact.OptInStatusEnumVal == 2" class="dot highlightedMessageBlock cursorPointer" style="margin-left: 10px;margin-bottom: -4px;" (click)="verifyOptInStatus(contact)"></span>
                                      <span *ngIf="isDisplayPatientContactAdd && contact.OptInStatusEnumVal == 3" class="dot successMessageBlock cursorPointer" style="margin-left: 10px;margin-bottom: -4px;" (click)="verifyOptInStatus(contact)"></span>
                                      <span *ngIf="isDisplayPatientContactAdd && contact.OptInStatusEnumVal == 4" class="dot errorMessageBlock cursorPointer" style="margin-left: 10px;margin-bottom: -4px;" (click)="verifyOptInStatus(contact)"></span>
                                      <img src="/assets/images/OptIn-Loading.gif" style="margin-left: 10px;margin-bottom: 8px;" class="dot" *ngIf="isDisplayPatientContactAdd && contact.OptInStatusEnumVal == -1"/>
                                      <button mat-icon-button title="Send Welcome SMS" style="margin-left: 15px;" (click)="sendWelcomeText(contact)">
                                        <mat-icon style="vertical-align: middle;">send</mat-icon>
                                      </button>
                                    </span>
                                  </span>
                              </div>
                              
                              <div *ngIf="contact.Email != null && contact.Email != ''">
                                  <mat-checkbox [checked]="contact.IsEmailPreferred" [(ngModel)]="contact.IsEmailPreferred"></mat-checkbox>
                                  <mat-icon style="vertical-align: middle;">mail_outline</mat-icon>
                                  <span>&nbsp;&nbsp;{{contact.Email}}</span>
                              </div>
                          </div>
                      </div>

                      <div *ngIf="selectedContactCategory.IsDeletable == false">
                        <div *ngIf="!contact.isDeleted" style="text-align: right;">
                            
                            <button mat-icon-button (click)="openContactEditor(contact)" title="Edit Contact">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteContact(contact)" title="Delete Contact">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <div style="text-align: right;" *ngIf="contact.LanguageName != 'English' && contact.LanguageName != ''">
                              ({{contact.LanguageName}})
                            </div>
                            <div *ngIf="contact.LanguageName == ''" class="colorError">
                              (Unsupported)
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div *ngIf="contactListToShow.length == 0">
                <div class="msg">
                    No contacts found
                </div>
            </div>
          </div>
      </mat-tab>      
    </mat-tab-group>    
  </div>

  <div class="notify-grid-item-messages" style="height: fit-content;" *ngIf="this.isMessageDataLoaded && this.isContactDataLoaded">
    <!-- Notify Messages Grid -->
    <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms" (selectedTabChange)="messageCategoryTabChange($event)">
      <!-- Only show messagecategories related to selected contact types-->
      <mat-tab [label]="messageCategory.MessageCategoryId" *ngFor="let messageCategory of messageCategoryListToShow">
          <ng-template mat-tab-label>
            <span>{{messageCategory.MessageCategoryName}}</span>
          </ng-template>  
          <div class="overflowAuto" style="height:100%;">  <!-- 48px = tab header height -->
            <!-- Notify Messages List -->
            <mat-list *ngIf="messageListToShow.length > 0" style="max-width: 100%;">
              <mat-list-item *ngFor="let message of messageListToShow">
                <span (click)="onMessageSelection(message, $event.target)" class="cursorPointer text-wrapper" >
                  {{message.MessageText}}
                </span>
              </mat-list-item>
            </mat-list>
            <div *ngIf="messageListToShow.length == 0">
              <div class="msg">
                No messages found
              </div>
            </div>
          </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- Send Message Box-->
  <div class="contentBox notify-grid-item-sendMessage" *ngIf="this.isMessageDataLoaded && this.isContactDataLoaded">
    <div class="CaptionLabel notifty-messageSend-header">
      <div style="display: inline; font-weight: 500;">Message (no PHI & 160 chars)</div>
      <!-- Send details -->
      <div style="position: absolute;text-align: right;" class="contentBox notify-textBox-details" *ngIf="showMsgSendDetailsBox" (click)="onClickSendDetailsClose()">
        <div class="notify-grid-MsgSendDetailsHeader Padding5px CaptionLabel">
          <div style="text-align: left; margin-left: 2px; font-weight: 500;">
            Send Details
          </div>
          <div style="text-align: right; margin-right: 2px;" class="cursorPointer" (click)="onClickSendDetailsClose()">
            <u>Close</u>
          </div>
        </div>
        <div class="overflowAuto" style="height:calc(78%);">
          <div *ngFor="let sendResultDetail of messageSendResultDetails">
            <div class="notify-grid-MsgSendDetails Padding5px colorWhite borderBottomBlue">
              <div class="successMessageBlock" *ngIf="sendResultDetail.isSent" style="text-align: center; max-width: 100px;">Sent</div>
              <div class="errorMessageBlock" *ngIf="!sendResultDetail.isSent" style="text-align: center; max-width: 100px;">Failed</div>
              <div style="text-align: left; margin-left: 5px;">&nbsp;{{sendResultDetail.contactPoint}}&nbsp;({{sendResultDetail.contactName}})</div>
              <div style="margin-left: 5px;">{{sendResultDetail.responseMessage}}</div>
            </div>
          </div>
        </div>
                
      </div>
      <div class="{{messageSendResultClass}} alignCenter">{{messageSendResult}}</div>
      <div>
        <div class="cursorPointer" style="text-align: right; margin-right: 2px;" (click)="onClickSendDetails()" *ngIf="showMsgSendDetails"><u>Details</u></div>
      </div>
    </div>
    <div style="display: flow-root; ">
      <textarea  class="notify-textBox contentBox" [ngStyle]="{'height': getSendMsgTextboxHeight()}" placeholder="Select contacts and a message. Click the send button to send the message to selected contacts." readonly  maxlength="160">{{messageToSend.MessageText}}</textarea>
      <button mat-raised-button [disabled]="!enableMsgSendButton" class="btn-default" (click)="sendMessage()" style="vertical-align: bottom; margin:10px">
        <mat-icon>send</mat-icon>
        <div>Send</div>
      </button>
    </div>        
  </div>
</div>
