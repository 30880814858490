<div *ngIf="!isModuleEnabled">
    <div class="alignCenter Padding5px">
        Oops! You are not subscribed to this module.
    </div>
  </div>
  
  <div *ngIf="isModuleEnabled" class="media-grid">
  
    <div class="contentBox media-grid-item-contents borderDarkBlue">
  
      <div>
                
        <!-- <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" >
            <mat-tab label="{{mediaType.mediaTypeName}}" *ngFor="let mediaType of mediaTypeList" >   -->
              <div class="CaptionLabel">Select Video:</div>   
                <mat-list class="image-gallery">
                    <ng-container *ngFor="let media of mediaList" >
                        
                        <mat-list-item matTooltip="{{media.Title}}"  [ngClass]="{'selection-color' : selectedMedia != undefined && media.MediaId === selectedMedia.MediaId}" > <!-- Display media in the relevant media type tab -->
                            <mat-icon class="selection-icon" *ngIf="savedCustomerDefaultMediaPref != undefined && media.MediaId === savedCustomerDefaultMediaPref.MediaId">check_circle</mat-icon>
                            <a (click)="onMediaSelectionChange(media)">
                                
                                <div class="image-container">                                
                                    <img src="{{media.ThumbnailPath}}" class="thumbnail" aria-label="{{media.Title}}" />
                                    <p class="title">{{media.Title}}</p>
                                </div>
                            </a>
                        </mat-list-item>
                    </ng-container>
                </mat-list>
    
            <!-- </mat-tab>                
        </mat-tab-group> -->
      </div> 
      
    </div>
    
    <div class="contentBox media-grid-item-selections borderDarkBlue">
        <div class="CaptionLabel">Media Selection:</div>
        <div class="alignCenter">
            <div class="margin-top-10px"></div>

            <div *ngIf="selectedMedia == undefined">
                Please select a default media
            </div>

            <div *ngIf="selectedMedia != undefined">
                <div class="margin-top-10px">
                    <button mat-raised-button aria-label="Save default media selection" (click)="saveSelectedMedia()" 
                        [disabled]="savedCustomerDefaultMediaPref.MediaId === unsavedCustomerDefaultMediaPref.MediaId && savedCustomerDefaultMediaPref.ShowSlideShow === unsavedCustomerDefaultMediaPref.ShowSlideShow">
                        Save <mat-icon>save</mat-icon>
                    </button>
                </div>

                <div class="margin-top-10px"></div>

                <div class="CaptionLabel">
                    {{selectedMediaType.mediaTypeName}}
                </div>

                <img *ngIf="selectedMedia.ThumbnailPath" src="{{selectedMedia.ThumbnailPath}}" class="thumbnail-large" />
                <p class="title">{{selectedMedia.Title}}</p>

                <div class="Padding5px" *ngIf="selectedMediaType.mediaTypeName === 'Audio'">
                    <label>Show Slide Show:&nbsp;&nbsp;</label>
                    <span>
                        <mat-slide-toggle
                            [checked]="unsavedCustomerDefaultMediaPref.ShowSlideShow" 
                            [(ngModel)]="unsavedCustomerDefaultMediaPref.ShowSlideShow" 
                            [disabled]="selectedMediaType.mediaTypeName !== 'Audio'">
                        </mat-slide-toggle>
                    </span>
                </div>
            </div>
        </div>
    </div>
  </div>