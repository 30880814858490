// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, EnvService } from 'kscigcorelib';
import { ApplicationConfig, CustomerLanguagePreference, HandleErrorService, ApiService } from 'kscigcorelib';

// Component

 
@Injectable({
  providedIn: 'root'
})
export class NotifyGeneralService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService:ApiService,
    private envService: EnvService
    ) { }

  public getCustomerNotifyConfig(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiCustomerPreference?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get CustomerNotifyConfig data'))
    );
  }

  public setCustomerNotifyConfig(customerId: string, customerNotifyPreference: ApplicationConfig):Observable<CloudApiResponse> {
    return this.http.put<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiCustomerPreference/set?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(customerNotifyPreference), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Set CustomerNotifyConfig data'))
    );
  }

  public getLanguages(customerId: string, enabledForNotifyOnly: boolean): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiCustomerPreference/getLanguagePref?ecid=' + this.apiService.secureApiQueryString(customerId) + '&enabledForNotifyOnly=' + enabledForNotifyOnly, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get CustomerNotifyPreference data'))
    );
  }

  public setLanguagePreference(customerId: string, languagePreference: CustomerLanguagePreference):Observable<CloudApiResponse> {
    return this.http.put<CloudApiResponse>(this.envService.apiUrl + '/NotifyApiCustomerPreference/setLanguagePref?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(languagePreference, false), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Set LanguagePreference data'))
    );
  }

}
