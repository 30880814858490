<div class="settings-grid">
    <div class="settings-grid-item-tree">
      <div class="CaptionLabelSquare">Settings</div>
        <mat-tree class="settings-grid-tree" [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="CursorPointer" (click)="treeNodeSelected(node)" [ngClass]="{'selected-node':selectedNodeName==node.name}">
              {{getDisplayTreeNodeName(node.name)}}              
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              {{getDisplayTreeNodeName(node.name)}}
            </mat-tree-node>
          </mat-tree>
    </div>

    <div class="contentBox settings-grid-item-treeContent">
      <div class="CaptionLabelSquare alignCenter" >{{getDisplayTreeNodeName(selectedNodeName)}}</div>
      <div *ngIf="selectedNodeName == 'Settings'" class="alignCenter" style="margin-top: 40px;">Select a setting from left navigation pane</div>
      <app-app-general *ngIf="selectedNodeName == 'General_App'"></app-app-general>
      <app-user-subscriptions *ngIf="selectedNodeName == 'User Subscriptions'"></app-user-subscriptions>
      <!-- Notify Section Start -->
      <app-notify-general *ngIf="selectedNodeName == 'General_Notify'"></app-notify-general>      
      <app-notify-contact-categories *ngIf="selectedNodeName == 'Contact Categories'"></app-notify-contact-categories>
      <app-notify-contacts *ngIf="selectedNodeName == 'Staff Contacts'"></app-notify-contacts>
      <app-notify-message-categories *ngIf="selectedNodeName == 'Message Categories'"></app-notify-message-categories>
      <app-notify-messages *ngIf="selectedNodeName == 'Messages'"></app-notify-messages>
      <app-notify-stats *ngIf="selectedNodeName == 'Notify Stats'"></app-notify-stats>
      <!-- Notify Section End -->

      <!-- Media Section Start -->
      <app-media-default *ngIf="selectedNodeName == 'Default'"></app-media-default>
      <!-- Media Section End -->
    </div>
</div>
