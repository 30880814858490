// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, CustomerMediaData, CustomerDefaultMediaPreference, MediaModel, EnvService } from 'kscigcorelib';
import { HandleErrorService, ApiService  } from 'kscigcorelib';

// Application

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
    ) { }

  public getMediaTypes(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/MediaApiMediaType?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Media Types', []))
    );
  }

  public getMediaAll(customerId: string, isOriginUrlMedia:boolean = false): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/MediaApiMedia?ecid=' + this.apiService.secureApiQueryString(customerId) + "&isOriginUrlMedia=" + isOriginUrlMedia, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get all Media', []))
    );
  }
  
  public getMediaById(customerId: string, mediaId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/MediaApiMedia/getMediaById?ecid=' + this.apiService.secureApiQueryString(customerId) + "&mId=" + mediaId, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Media'))
    );
  }

  public getMediaCategories(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/MediaApiMediaCategory?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Media Categories', []))
    );
  }
  
  public getMediaCategoryMap(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/MediaApiMediaCategoryMap?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Media Category Map', []))
    );
  }

  public getMediaSettingsByCategory(customerId: string, category: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/MediaApiMediaSetting?ecid=' + this.apiService.secureApiQueryString(customerId) + "&category=" + category, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Media Categories', []))
    );
  }

  public getCustomerDefaultMediaPreference(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/MediaApiCustomerPreference/getDefaultMediaPref?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getCustomerDefaultMediaPreference'))
    );
  }

  public setCustomerDefaultMediaPreference(defaultMediaPref: CustomerDefaultMediaPreference){
    return this.http.put(this.envService.apiUrl + '/MediaApiCustomerPreference/setDefaultMediaPref', this.apiService.createApiRequestPayload(defaultMediaPref), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('setCustomerDefaultMediaPreference'))
    );
  }

  public getSessionMediaData(customerId: string, sessionId: string): Observable<CloudApiResponse> {
    let ecid:string = this.apiService.secureApiQueryString(customerId);
    let esid:string = this.apiService.secureApiQueryString(sessionId);
    
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/MediaApiCustomerMediaData/getSessionMediaData?ecid=' + ecid, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Session Media data'))
    );
  }
  
  public getPatientMediaData(customerId: string, patientId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/MediaApiCustomerMediaData/getPatientMediaData?ecid=' + this.apiService.secureApiQueryString(customerId) + "&epid=" + this.apiService.secureApiQueryString(patientId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Patient Media data'))
    );
  }

  public setSessionMediaData(userActionId:string, mediaData: CustomerMediaData){
    return this.http.post(this.envService.apiUrl + '/MediaApiCustomerMediaData/setSessionMediaData?euaid=' + this.apiService.secureApiQueryString(userActionId), this.apiService.createApiRequestPayload(mediaData), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Set Session Media Data'))
    );
  }

  public setPatientMediaData(userActionId:string, mediaData: CustomerMediaData){
    return this.http.post(this.envService.apiUrl + '/MediaApiCustomerMediaData/setPatientMediaData?euaid=' + this.apiService.secureApiQueryString(userActionId), this.apiService.createApiRequestPayload(mediaData), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Set Patient Media Data'))
    );
  }

  public deleteSessionMediaData(customerId: string) {
    let ecid:string = this.apiService.secureApiQueryString(customerId);
        
    return this.http.delete<CloudApiResponse>(this.envService.apiUrl + '/MediaApiCustomerMediaData/deleteSessionMediaData?ecid=' + ecid, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Delete Session Media data'))
    );
  }

  public deletePatientMediaData(customerId: string, patientId: string) {
    let ecid:string = this.apiService.secureApiQueryString(customerId);
    let epid:string = this.apiService.secureApiQueryString(patientId);
        
    return this.http.delete<CloudApiResponse>(this.envService.apiUrl + '/MediaApiCustomerMediaData/deletePatientMediaData?ecid=' + ecid + "&ePid=" + epid, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Delete Patient Media data'))
    );
  }

}
