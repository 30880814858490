// Angular
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription, fromEvent, Observable } from 'rxjs';

// Material
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, CloudApiResponse, EncryptionService } from 'kscigcorelib';

// Application Core
import { SessionHelper } from '../../shared/helpers/session.helper';

// Component
import { MessageCategoryViewModel } from './notify-message-categories-view-model';
import { ContactCategory, MessageCategory } from '../../notify/notify-model';
import { NotifyService } from '../../notify/notify.service';
import { NotifyMessageCategoriesService } from './notify-message-categories.service'
import { UIElementService } from 'src/app/shared/services/uielement.service';

@Component({
  selector: 'app-notify-message-categories',
  templateUrl: './notify-message-categories.component.html',
  styleUrls: ['./notify-message-categories.component.css']
})
export class NotifyMessageCategoriesComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;

  ColumnsToDisplay = [
    'ContactCategoryName',
    'MessageCategoryName',
    'DisplayOrder',
    'EditDelete'
  ];

  public customerId:string;
  public messageCategoryList: MessageCategoryViewModel[];
  public newMessageCategory: MessageCategory;
  public messageCategoryViewModel = new MatTableDataSource<MessageCategoryViewModel>();
  public displayedColumns: string[] = this.ColumnsToDisplay;
  public contactCategoryList: ContactCategory[];
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  public isValidMessageCategoryName: boolean;
  public isValidContactCategory: boolean;
  
  constructor(
    private sessionHelper:SessionHelper,
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,
    private notifyMessageCategoriesService:NotifyMessageCategoriesService,
    private notifyService:NotifyService,
    private elemService:UIElementService,
    private encryptionService: EncryptionService
    ) { }

  ngOnInit() {
    if(this.sessionHelper.isValidUserSession()){
      this.loadingBarService.startBar();
      this.customerId = this.sessionHelper.getCustomerId();
      this.initNewMessageCategory();
      // Load Page Data
      this.loadPageData();
    }
  }

  ngAfterViewInit() {    
    this.resizeElements();
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(e => {
      this.resizeElements();
    });
  }

  private resizeElements() {
    this.elemService.setElementWidth("msg-categories-container", window?.innerWidth
      - this.elemService.getElementWidth("settings-grid-item-tree"));   
  }

  private loadPageData(){
    this.notifyService.getContactCategories(this.customerId)
      .subscribe({
          next: (contactCategoryResult: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(contactCategoryResult.payload);            
                this.loggingService.logVerbose(decryptedPayload);                
                var contactCategory: ContactCategory[] = JSON.parse(decryptedPayload);
                this.contactCategoryList = contactCategory;
                this.loadMessageCategoryList(true);
              },
          error: () => { this.loggingService.logError("Error getting Contact categories"); },
          complete: () => { this.loggingService.logVerbose("Completed getting Contact categories"); }
      });
  }

  private loadMessageCategoryList(stopLoadingbar:boolean){
    this.notifyMessageCategoriesService.getAllMessageCategories(this.customerId)
      .subscribe({
          next: (messageCategoryResult: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(messageCategoryResult.payload);            
                this.loggingService.logVerbose(decryptedPayload);
                var messageCategory: MessageCategoryViewModel[] = JSON.parse(decryptedPayload);
                this.messageCategoryList = messageCategory;                
                this.messageCategoryViewModel.data.splice(0);
                messageCategory.forEach(element => {
                  if(!element.IsSystem){
                    let vm = new MessageCategoryViewModel();
                    vm.ContactCategoryId = element.ContactCategoryId;
                    vm.ContactCategoryName = element.ContactCategoryName;
                    vm.MessageCategoryId = element.MessageCategoryId;
                    vm.MessageCategoryName = element.MessageCategoryName;
                    vm.DisplayOrder = element.DisplayOrder;
                    vm.IsDeleted = element.IsDeleted;
                    vm.IsEditing = element.IsEditing;
                    vm.RowEditMode = element.RowEditMode;
                    this.messageCategoryViewModel.data.push(vm);
                  }
                });
                
                this.messageCategoryViewModel.sort = this.sort;
                if(stopLoadingbar){
                  this.loadingBarService.stopBar(); 
                } 
              },
          error: () => { this.loggingService.logError("Error loading Message Categories"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Message Categories"); }
      });
  }

  public hasScroll(element: ElementRef): boolean {
    return element.nativeElement.scrollHeight > element.nativeElement.clientHeight;
  }

  public isOnEditMode(): boolean {
    return this.messageCategoryViewModel.data.findIndex((messageCategory) => messageCategory.IsEditing) >= 0;
  }

  public startEdit(messageCategory: MessageCategoryViewModel): void {
    messageCategory.IsEditing = true;
  }

  public cancelEdit(messageCategory: MessageCategoryViewModel): void {
    // reset
    let msgCategory:MessageCategoryViewModel = this.messageCategoryList.find(m => m.MessageCategoryId == messageCategory.MessageCategoryId);
    messageCategory.MessageCategoryName = msgCategory.MessageCategoryName;
    messageCategory.ContactCategoryId = msgCategory.ContactCategoryId;
    messageCategory.IsEditing = false;
  }

  public deleteMessageCategory(messageCategory: MessageCategoryViewModel){
    this.loggingService.logInformation(messageCategory);
    this.notifyMessageCategoriesService.deleteMessageCategory(this.customerId, messageCategory.MessageCategoryId)
      .subscribe({
          next: (deleteResult: CloudApiResponse) => {
                var deleted: boolean = deleteResult.payload;
                if(deleted) {
                  this.loadMessageCategoryList(false);;
                  this.notificationBarService.showSuccess("Deleted " +  messageCategory.MessageCategoryName);
                  this.loggingService.logInformation("Category " +  messageCategory.MessageCategoryName + " has been deleted");
                } else {
                  this.notificationBarService.showError("Error deleting " +  messageCategory.MessageCategoryName);
                  this.loggingService.logError("Error deleting category " +  messageCategory.MessageCategoryName);  
                }
              },
          error: () => {
                  this.notificationBarService.showError("Error deleting " +  messageCategory.MessageCategoryName);
                  this.loggingService.logError("Error deleting category " +  messageCategory.MessageCategoryName);
              },
          complete: () => { this.loggingService.logVerbose("Completed deleting category " +  messageCategory.MessageCategoryName); }
      });
  }

  public undoDeleteMessageCategory(messageCategory: MessageCategoryViewModel){
    this.loggingService.logInformation(messageCategory);
    this.notifyMessageCategoriesService.undoDeleteMessageCategory(this.customerId, messageCategory.MessageCategoryId)
      .subscribe({
          next: (updateResult: CloudApiResponse) => {
                var updated: boolean = updateResult.payload;
                if(updated){
                  this.loadMessageCategoryList(false);
                  this.notificationBarService.showSuccess("Undone delete " +  messageCategory.MessageCategoryName);
                  this.loggingService.logInformation("Category " +  messageCategory.MessageCategoryName + " has been undeleted");
                } else {
                  this.notificationBarService.showError("Error undeleting " +  messageCategory.MessageCategoryName);
                  this.loggingService.logError("Error undeleting category " +  messageCategory.MessageCategoryName);
                }
              },
          error: () => {
                  this.notificationBarService.showError("Error undeleting " +  messageCategory.MessageCategoryName);
                  this.loggingService.logError("Error undeleting category " +  messageCategory.MessageCategoryName);
              },
          complete: () => { this.loggingService.logVerbose("Completed undeleting category " +  messageCategory.MessageCategoryName); }
      });
  }

  private editMessageCategory(messageCategory: MessageCategoryViewModel){
    if(messageCategory.MessageCategoryId != ''){
      this.notifyMessageCategoriesService.updateMessageCategory(this.customerId, messageCategory)
        .subscribe({
            next: () => {
                  messageCategory.IsEditing = false;
                  this.loadMessageCategoryList(false);
                  this.notificationBarService.showSuccess("Updated " +  messageCategory.MessageCategoryName);
                  this.loggingService.logInformation("Category " +  messageCategory.MessageCategoryName + " has been updated");
                },
            error: () => {
                  this.notificationBarService.showError("Error upating " +  messageCategory.MessageCategoryName);
                  this.loggingService.logInformation("Error upating category " +  messageCategory.MessageCategoryName);
                },
            complete: () => { this.loggingService.logVerbose("Completed upating category " +  messageCategory.MessageCategoryName); }
        });
    }
  }
  
  public cancelAdd(){
    this.loggingService.logVerbose(this.newMessageCategory.MessageCategoryName);
    this.initNewMessageCategory();
  }

  private initNewMessageCategory(){
    this.newMessageCategory = new MessageCategory();
  }

  public addMessageCategory(messageCategory: MessageCategory){
    this.loggingService.logInformation(messageCategory);
    this.notifyMessageCategoriesService.addMessageCategory(this.customerId, messageCategory)
      .subscribe({
          next: () => {
                this.loadPageData();
                this.initNewMessageCategory();
                this.notificationBarService.showSuccess("Added " +  messageCategory.MessageCategoryName);
                this.loggingService.logInformation("Category " +  messageCategory.MessageCategoryName + " has been added");
              },
          error: () => {
                this.notificationBarService.showError("Error adding " +  messageCategory.MessageCategoryName);
                this.loggingService.logInformation("Error adding category " +  messageCategory.MessageCategoryName);
              },
          complete: () => { this.loggingService.logVerbose("Completed adding category " +  messageCategory.MessageCategoryName); }
      });
  }

  public ValidateMessageCategory() {
    var name = this.newMessageCategory.MessageCategoryName.trim();
    if (name != '') {
      var elem = this.messageCategoryList.find(x => x.MessageCategoryName == name);
      this.isValidMessageCategoryName = (elem === undefined)
    } else {
      this.isValidMessageCategoryName = false;
    }
    this.isValidContactCategory = this.newMessageCategory.ContactCategoryId != undefined;
  }
}
