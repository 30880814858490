// Angular
import { Component, OnInit, Inject } from '@angular/core';

// Material
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';

// Shared Lib
import { LoadingBarService, NotificationBarService, LoggingService, CoreHelper } from 'kscigcorelib';

// Application Core
import { RouteHelper } from '../../../shared/helpers/route.helper';

// Component
import { NotifyMessagesService } from './../notify-messages.service';
import { Message } from '../../../notify/notify-model';
import { MessageViewModel } from './notify-message-editor.component-view-model';

@Component({
  selector: 'app-notify-message-editor',
  templateUrl: './notify-message-editor.component.html',
  styleUrls: ['./notify-message-editor.component.css']
})
export class NotifyMessageEditorComponent implements OnInit {

  public messageData:MessageViewModel = new MessageViewModel();
  public formMessageText:string = '';

  constructor(
    private routeHelper:RouteHelper,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,
    private loggingService:LoggingService,
    private notifyMessagesService:NotifyMessagesService,
    private coreHelper:CoreHelper,
    public dialogRef: MatDialogRef<NotifyMessageEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: MessageViewModel
    ) { }

  ngOnInit(): void {
    if(this.inputData != null && this.inputData.messageText != ''){
      this.loggingService.logVerbose("Edit Message");
      this.loggingService.logVerbose(this.inputData);
      this.messageData = this.inputData;
      this.formMessageText = this.inputData.messageText;
    } else {
      this.messageData = this.inputData;
      this.loggingService.logVerbose("New Message");
    }
  }

  public closeDialog(bRefreshData: boolean) {
    this.loggingService.logVerbose("Close dialog");
    this.dialogRef.close(bRefreshData);
  }

  public saveMessage(){
    this.loadingBarService.startBar();
    if(this.isMessageTextValid()){
      this.messageData.messageText = this.formMessageText;
      if(this.messageData.messageId == null){
        // Add new message
        this.loggingService.logVerbose("Add new message");
        this.notifyMessagesService.addMessage(this.messageData.customerId, this.getMessageDataToSave(this.messageData))
          .subscribe({
              next: ()=>{
                    let message = this.coreHelper.getRegularShortText(this.messageData.messageText);
                    this.loggingService.logVerbose("Message added " + message);
                    this.notificationBarService.showSuccess("Message added " +  message);
                    this.loadingBarService.stopBar();
                    this.closeDialog(true);
                  },
              error: () => {
                    let message = this.coreHelper.getRegularShortText(this.messageData.messageText);
                    this.notificationBarService.showError("Error adding " +  message);
                    this.loggingService.logError("Error adding message " + message);
                    this.loadingBarService.stopBar();
                    this.closeDialog(false);
                  },
              complete: () => { this.loggingService.logVerbose("Completed adding message " + this.coreHelper.getRegularShortText(this.messageData.messageText)); }
          });
      } else {
        // Update the mesage
        this.loggingService.logVerbose("Update message" + this.messageData.messageId);
        this.notifyMessagesService.updateMessage(this.messageData.customerId, this.getMessageDataToSave(this.messageData))
          .subscribe({
              next: () => {
                    let message = this.coreHelper.getRegularShortText(this.messageData.messageText);
                    this.loggingService.logVerbose("Message updated " + message);
                    this.notificationBarService.showSuccess("Message updated " +  message);
                    this.loadingBarService.stopBar();
                    this.closeDialog(true);
                  },
              error: () => {
                    let message = this.coreHelper.getRegularShortText(this.messageData.messageText);
                    this.notificationBarService.showError("Error updating " +  message);
                    this.loggingService.logError("Error updating message " + message);
                    this.loadingBarService.stopBar();
                    this.closeDialog(false);
                  },
              complete: () => { this.loggingService.logVerbose("Completed updating message " + this.coreHelper.getRegularShortText(this.messageData.messageText)); }
          });
      }
    } else {
      this.loggingService.logError("Error invalid message, cannot save");
      this.loadingBarService.stopBar();
    }
  }

  private getMessageDataToSave(messageVM:MessageViewModel):Message{
    var message:Message = null;
    if(messageVM != null){
      message = new Message();
      message.IsDeleted = messageVM.isDeleted;
      message.IsOldEditedEntry = messageVM.isOldEditedEntry;
      message.LanguageId = messageVM.languageId;
      message.LanguageName = messageVM.languageName;
      message.MessageCategoryId = messageVM.messageCategoryId;
      message.MessageCategoryName = messageVM.messageCategoryName;
      message.MessageId = messageVM.messageId;
      message.MessageText = messageVM.messageText;
    }
    return message;
  }

  private isMessageTextValid():boolean{
    let isValid = false;
    this.loggingService.logVerbose(this.formMessageText);
    // Verify message character limit
    if(this.formMessageText.length > 0  && this.formMessageText.length < 160){
      // If no a new message then verify if the message is changed
      if(this.messageData != null){
        if(this.formMessageText != this.messageData.messageText){
          isValid = true;
        } else {
          this.loggingService.logVerbose("No change");
        }
      } else {
        isValid = true;
      }
    }
    return isValid;
  }
}
