// Angular 
import { Component, OnInit, AfterViewInit, QueryList } from '@angular/core';

// Material
import { MatTab, MatTabChangeEvent as MatTabChangeEvent } from '@angular/material/tabs'
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';

// Shared Lib
import { LoadingBarService, NotificationBarService, LoggingService, CloudApiResponse, CoreHelper, EncryptionService } from 'kscigcorelib';

// Application Core Services
import { RouteHelper } from '../../shared/helpers/route.helper';
import { SessionHelper } from '../../shared/helpers/session.helper';

// Component
import { NotifyMessagesService } from './notify-messages.service';
import { NotifyMessageEditorComponent} from './notify-message-editor/notify-message-editor.component';
import { MessageCategory, ContactCategory, Message } from '../../notify/notify-model';
import { MessageViewModel } from './notify-message-editor/notify-message-editor.component-view-model';
import { UIElementService } from 'src/app/shared/services/uielement.service';

@Component({
  selector: 'app-notify-messages',
  templateUrl: './notify-messages.component.html',
  styleUrls: ['./notify-messages.component.css']
})
export class NotifyMessagesComponent implements OnInit, AfterViewInit {

  public customerId:string;
  public contactCategoryNames: string[] = [];
  public messageCategoryList: MessageCategory[] = [];
  public messageList: Message[] = [];
  private selectedMessageCategoryTabId:string = null;
  public showAddMessage:boolean = true;

  constructor(
    private routeHelper:RouteHelper,
    private sessionHelper:SessionHelper,
    private loadingBarService: LoadingBarService,
    private notificationService: NotificationBarService,
    private loggingService:LoggingService,
    private notifyMessagesService:NotifyMessagesService,
    private encryptionService: EncryptionService,
    private coreHelper:CoreHelper,
    private elemService: UIElementService,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
    if(this.sessionHelper.isValidUserSession()){
      // Start loading bar
      this.loadingBarService.startBar();
      this.customerId = this.sessionHelper.getCustomerId();
      // Load Page Data
      this.loadPageData();
    }
  }

  ngAfterViewInit(): void {
    let available_width = window?.innerWidth
    this.elemService.setElementWidth("worklist-data", window?.innerHeight - this.elemService.getHeaderHeight() - this.elemService.getFooterHeight()
                                        - this.elemService.getElementHeight("worklist-header") - 10 // margin-top for worklist-header
                                        - this.elemService.getElementHeight("worklist-paginator"));   
  }

  private loadPageData(){
    this.notifyMessagesService.getAllMessageCategories(this.customerId)
      .subscribe({
          next: (messageCategoryResult: CloudApiResponse) => {
                var decryptedCatPayload = this.encryptionService.decryptUsingAES256(messageCategoryResult.payload);
                this.loggingService.logVerbose(decryptedCatPayload);
                var messageCategory: MessageCategory[] = JSON.parse(decryptedCatPayload);
                messageCategory.forEach(element => {
                  if(!element.IsDeleted) {
                    this.messageCategoryList.push(element); // Do not add the deleted category for the UI                    
                  }
                });
                this.contactCategoryNames = [...new Set( this.messageCategoryList.map(obj => obj.ContactCategoryName)) ];
                this.notifyMessagesService.getMessage(this.customerId)
                  .subscribe({
                      next: (messageResult: CloudApiResponse) => {
                            var decryptedPayload = this.encryptionService.decryptUsingAES256(messageResult.payload);
                            this.loggingService.logVerbose(decryptedPayload);
                            this.messageList = JSON.parse(decryptedPayload);
                            //this.populateMessageListToShow(this.selectedMessageCategoryTabId);
                            this.loadingBarService.stopBar(); 
                          },
                      error: () => { this.loggingService.logError("Error loading messages"); },
                      complete: () => { this.loggingService.logVerbose("Completed loading messages"); }
                  });
                },
          error: () => { this.loggingService.logError("Error loading message categories"); },
          complete: () => { this.loggingService.logVerbose("Completed loading message categories"); }
      });
  }

  private RefreshMessageData(){
    this.notifyMessagesService.getMessage(this.customerId)
          .subscribe({
              next: (messageResult: CloudApiResponse) => {
                    var decryptedPayload = this.encryptionService.decryptUsingAES256(messageResult.payload);
                    this.loggingService.logVerbose(decryptedPayload);
                    var message: Message[] = JSON.parse(decryptedPayload);
                    this.messageList = message;
                    //this.populateMessageListToShow(this.selectedMessageCategoryTabId);
                  },
              error: () => { this.loggingService.logError("Error refreshing message list"); },
              complete: () => { this.loggingService.logVerbose("Completed refreshing message list"); }
          });
  }

  public openMessageEditor(message:Message){
    // Set the selected message categoryId for adding a new message
    var messageVM:MessageViewModel = new MessageViewModel();
    if(message == null){
      messageVM = new MessageViewModel();
      messageVM.customerId = this.customerId;
      messageVM.messageCategoryId = this.selectedMessageCategoryTabId;
    } else {
      messageVM = new MessageViewModel();
      messageVM.isDeleted = message.IsDeleted;
      messageVM.messageText = message.MessageText;
      messageVM.messageId = message.MessageId;
      messageVM.languageName = message.LanguageName;
      messageVM.languageId = message.LanguageId;
      messageVM.isOldEditedEntry = message.IsOldEditedEntry;
      messageVM.customerId = this.customerId;
      messageVM.messageCategoryId = message.MessageCategoryId;
    }
    const dialogRef = this.dialog.open(NotifyMessageEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageVM,
      minWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      this.RefreshMessageData();
    });
  }

  public messageCategoryChanged(tabChangeEvent: MatTabChangeEvent):void{
    this.selectedMessageCategoryTabId = tabChangeEvent.tab.textLabel;
    this.loggingService.logVerbose(this.selectedMessageCategoryTabId);
    if(this.isSystemCategory(this.selectedMessageCategoryTabId)){
      this.showAddMessage = false;
    } else{
      this.showAddMessage = true;
    }
    //this.populateMessageListToShow(this.selectedMessageCategoryTabId);
  }

  public contactCategoryChanged() {
    let tabs = document.querySelectorAll(".mdc-tab--active");
    if (tabs != null && tabs.length > 0) {
      this.showAddMessage = tabs.length < 2 || tabs[1].textContent.toLowerCase() != "system"; 
      if (tabs.length == 2) {    
        this.selectedMessageCategoryTabId = this.messageCategoryList.find(x => x.MessageCategoryName == tabs[1].textContent).MessageCategoryId;   
      }
    }
  }
  
  public deleteMessage(message: Message){
    this.loadingBarService.startBar();
    this.loggingService.logInformation(message);
    this.notifyMessagesService.deleteMessage(this.customerId, message.MessageId)
      .subscribe({
          next: () => {
                this.RefreshMessageData();
                
                this.notificationService.showSuccess("Deleted " +  this.coreHelper.getRegularShortText(message.MessageText));
                this.loggingService.logInformation("Message: " +  message.MessageText + " has been deleted");
                this.loadingBarService.stopBar();
              },
          error: () => {
                this.notificationService.showError("Error deleting " +  this.coreHelper.getRegularShortText(message.MessageText));
                this.loggingService.logError("Error deleting message " +  message.MessageText);
                this.loadingBarService.stopBar();
              },
          complete: () => { this.loggingService.logVerbose("Completed deleting message " +  message.MessageText); }
      });
  }

  public undoDeleteMessage(message: Message){
    this.loadingBarService.startBar();
    this.loggingService.logInformation(message);
    this.notifyMessagesService.undoDeleteMessage(this.customerId, message.MessageId)
      .subscribe({
          next: () => {
                this.RefreshMessageData();
                this.notificationService.showSuccess("Undone " +  this.coreHelper.getRegularShortText(message.MessageText));
                this.loggingService.logInformation("Message: " +  message.MessageText + " has been undeleted");
                this.loadingBarService.stopBar();
              },
          error: () => {
                this.notificationService.showError("Error undo deleting " +  this.coreHelper.getRegularShortText(message.MessageText));
                this.loggingService.logError("Error undo deleting Message " +  message.MessageText);
                this.loadingBarService.stopBar();
              },
          complete: () => { this.loggingService.logVerbose("Completed undo deleting Message " +  message.MessageText); }
      });
  }

  // public getMessageListForCategory(messageCategoryId: string){
  //   let tempMessages:Message[] = [];    
  //   this.messageList.forEach((message:Message) => {
  //     if(message.MessageCategoryId == messageCategoryId)
  //     {
  //       tempMessages.push(message);
  //     }
  //   });
  //   return tempMessages;
  // }
  
  public getMessageCountInCategory(messageCategoryId: string) {    
    return this.messageList != undefined ? this.messageList.filter(m => m.MessageCategoryId == messageCategoryId).length : 0;
  }

  private isSystemCategory(messageCategoryId: string):boolean{
    var matchFound:boolean = false;
    this.messageCategoryList.forEach((messageCategory:MessageCategory) => {
      if(messageCategory.MessageCategoryId == messageCategoryId && messageCategory.IsSystem == true) {
        matchFound = true;
      }
    });
    return matchFound;
  }

}
