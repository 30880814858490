<header>
    <!-- Home page Tool bar -->
    <mat-toolbar *ngIf="coreAppPageMode === coreAppPageModeEnum.logout">
      {{productName}}
      <span style="flex: 1 1 auto;"></span>
      <button mat-raised-button *ngIf="!isValidUserSession" (click)="login()">
        Login
      </button>
    </mat-toolbar>
    
    <!-- Login Tool bar -->
    <mat-toolbar *ngIf="coreAppPageMode === coreAppPageModeEnum.login || coreAppPageMode === coreAppPageModeEnum.error" >
      {{productName}} 
    </mat-toolbar>

    <!-- In App Tool bar --> 
    <mat-toolbar *ngIf="coreAppPageMode === coreAppPageModeEnum.appui" >
      <mat-toolbar-row>
        <div *ngIf="isWorklistEnabled && currentPatient && currentPatient.PatientId">
                    
          <div class="pid-data">
            <div>{{currentPatient.PatientId}}  ({{currentPatient.Gender}})<div></div>
            {{currentPatient.Name}} ({{currentPatientAge}} yrs)</div>
          </div>
        </div>
        <span style="flex: 1 1 auto;"></span>
        
        <span style="flex: 1 1 auto;"></span>
        <button mat-icon-button class="noHover">
          <mat-icon *ngIf="isPatientModeEnabled" class="show-enabled" matTooltip="Patient Mode enabled">lock</mat-icon>
          <mat-icon *ngIf="!isPatientModeEnabled" matTooltip="Patient Mode disabled">lock_open</mat-icon>
        </button>        
        <button mat-icon-button [matMenuTriggerFor]="notificationmenu">
          <mat-icon *ngIf="notificationCount == 0" matBadgeColor="warn">notifications</mat-icon>
          <mat-icon *ngIf="notificationCount > 0" matBadge="{{notificationCount}}" matBadgeColor="warn">notifications</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="usermenu">
          <mat-icon>account_circle</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-menu #usermenu="matMenu">
      <div class="CaptionLabelSquare" style="max-width: 200px;">
        <span *ngIf="userRole">({{userRole}})</span>&nbsp;{{username}} 
      </div>
      <mat-divider></mat-divider>
      <a mat-menu-item [routerLink]="'/activity'"> 
        <mat-icon>list</mat-icon>Activity
      </a>
      <mat-divider *ngIf="isAdminUser"></mat-divider>
      <a mat-menu-item [routerLink]="'/reports'" *ngIf="isAdminUser"> 
        <mat-icon>assessment</mat-icon>Utilization
      </a>
      <mat-divider></mat-divider>
      <a mat-menu-item [routerLink]="'/settings'"> 
        <mat-icon>settings</mat-icon>Settings
      </a>
      <mat-divider></mat-divider>
      <a mat-menu-item [routerLink]="'/help'"> 
        <mat-icon>help</mat-icon>Help
      </a>
      <mat-divider></mat-divider>
      <a mat-menu-item href="logout()"> 
        <mat-icon>power_settings_new</mat-icon>Logout
      </a>
    </mat-menu>

    <mat-menu #notificationmenu="matMenu">
      <div  style="width: 270px;"> 
        <div class="CaptionLabelSquare" style="width: 270px;">Notifications</div>
        <div *ngIf="notifications.length == 0" class="Padding10px">No items found</div>

        <div *ngFor="let notification of notifications">
          <div class="borderBottomBlue2px">
            <div [ngClass]= "notification.isDismissed ? 'lighterFont' : 'bolderFont'" class="Padding10px">
              <div style="float: right;">
                <button mat-mini-fab color="primary" (click)="dismissNotfication(notification.notificationId)" [disabled]="notification.isDismissed" style="width: 25px; height: 25px;">X</button>
              </div>
              <div><b>{{notification.title}}</b></div>
              <div>{{notification.detail}}</div>
              <!-- 
                <div>Start: {{notification.startDateTime|date :'short'}}</div> 
                <div>End: {{notification.expireDateTime |date :'short'}}</div> 
              -->
            </div>
          </div>
        </div>

      </div>

    </mat-menu>
  </header>
  <lib-loading-bar></lib-loading-bar>
