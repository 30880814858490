import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// Application Shared library
import { EnvService, LoggingService, coreAppPageMode } from 'kscigcorelib';

@Component({
  selector: 'app-help-guide',
  templateUrl: './help-guide.component.html',
  styleUrl: './help-guide.component.css'
})
export class HelpGuideComponent implements OnInit {

  pdfUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private envService: EnvService
  ) { }

  ngOnInit() {
    // Construct the PDF URL
    const pdfPath = '/assets/docs/ComfortOR1_User_Guide_v8.0.0.pdf'; // Adjust path if necessary
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfPath);
  }

}
