// Angular
import { AfterViewInit, Component, OnInit } from '@angular/core';

// Material
import { MatTabChangeEvent as MatTabChangeEvent } from '@angular/material/tabs'
import { MatRadioChange as MatRadioChange } from '@angular/material/radio'
import {MatDialog as MatDialog, MatDialogRef as MatDialogRef, MatDialogState as MatDialogState} from '@angular/material/dialog';

// Shared Lib
import { MessageBoxComponent, MessageBoxModel, MessageBoxType, CustomerLanguagePreference, CloudApiResponse } from 'kscigcorelib';
import { LoggingService, LoadingBarService, NotificationBarService, EncryptionService, Patient, ApplicationConfig } from 'kscigcorelib';

// Application Core Services
import { SessionHelper } from '../shared/helpers/session.helper';

// Component
import { NotifyService } from './notify.service';
import { NotifyContactsService } from '../settings/notify-contacts/notify-contacts.service';
import { NotifyGeneralService } from '../settings/notify-general/notify-general.service';
import { ContactEditorComponent } from '../shared/components/contact-editor/contact-editor.component';
import { ContactCategory, MessageCategory, Message, MobileCountryCode, MobileServiceProvider, ContactEditorData, MessageSendRequest, MessageSendResponse, EmailSendResponse, GlobalContact, MessageSendResultDetails, MessageLanguages, MessageLanguageText } from './notify-model'
import { ContactType, EmailSendType, OptInStatusType, SMSSendType } from './notify.enum';
import { PatientContact, SMSSendResponse } from './notify-model';
import { SharedPatientService } from '../shared/services/sharedpatient.service'
import { UIElementService } from '../shared/services/uielement.service';
import { ValidateInputDialogComponent } from '../media-play/validate-input-dialog/validate-input-dialog.component';


@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.css']
})
export class NotifyComponent implements OnInit {

  public isModuleEnabled:boolean = false;

  public customerId:string;
  public messageCategoryList: MessageCategory[] = [];
  public messageCategoryListToShow: MessageCategory[] = [];
  public mobileCountryCodeList : MobileCountryCode[] = [];
  public mobileServiceProviderList : MobileServiceProvider[] = [];
  public contactCategoryList: ContactCategory[] = [];
  public messageList: Message[] = [];
  public messageListToShow: Message[] = [];
  public languageList: CustomerLanguagePreference[] = [];
  public patientContactList: PatientContact[] = [];
  public globalContactList: GlobalContact[] = [];
  public contactList: PatientContact[] = [];
  public contactListToShow: PatientContact[] = [];
  public messageToSend:Message;
  public selectedContactCategory:ContactCategory;
  public messageSendResult = '';
  public messageSendResultClass = '';
  public currentPatientGuid: string;
  public bulkNotifyPatients:string[];
  public isDisplayPatientContactAdd:boolean = false;
  public showMsgSendDetails:boolean = false;
  public showMsgSendDetailsBox:boolean = false;
  public enableMsgSendButton:boolean = false;
  public messageSendResultDetails:MessageSendResultDetails[] = [];
  public systemMessageCategoryId:string = null;
  public systemMessageLaguages:MessageLanguages = null;
  public customerNotifyConfigs: ApplicationConfig[] = []
  public isSMSOptInEnabled: boolean = false;
  public isPatientModeEnabled: boolean = false;
  public patientModePassword: string= '';
  private dialogRef: MatDialogRef<ValidateInputDialogComponent>;
  public isContactDataLoaded: boolean = false;
  public isMessageDataLoaded: boolean = false;

  constructor(
    private sessionHelper:SessionHelper,
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private encryptionService:EncryptionService,
    private notifyService:NotifyService,
    private notificationBarService: NotificationBarService,
    private notifyContactsService: NotifyContactsService,
    private notifyGeneralService: NotifyGeneralService,
    private patientService: SharedPatientService,
    private elemService: UIElementService,    
    public dialog: MatDialog
  ) { 
    this.selectedContactCategory = new ContactCategory();
    this.currentPatientGuid = null;
  }

  ngOnInit() {
    this.isModuleEnabled = this.sessionHelper.isNotifyModuleEnabled();
    if(this.isModuleEnabled){
      this.messageToSend = new Message();
      if(this.sessionHelper.isValidUserSession()){
        this.customerId = this.sessionHelper.getCustomerId();   
        // Load Page Data
        this.loadPageData();
      }
    }    
  }

  
  getContactListHeight() {
    return (window?.innerHeight - this.elemService.getHeaderHeight() - this.elemService.getFooterHeight()
                                - 48) + "px"; //this.elemService.getElementHeight("mat-mdc-tab-header")
    //198
  }

  getSendMsgTextboxHeight() {
    let heightAvailable = window?.innerHeight - this.elemService.getHeaderHeight() - this.elemService.getFooterHeight();
    return (heightAvailable * 0.25 - this.elemService.getElementHeight("notifty-messageSend-header")) + "px";    
  }

  private loadPageData(){  
    // get selected/current patient
    var currentPatientDetails:Patient = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getCurrentPatient()));
    if (currentPatientDetails != null) {
      this.currentPatientGuid = currentPatientDetails.PatientGuid;      
    }
    this.bulkNotifyPatients = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getBulkNotifyPatients()));
    if (this.bulkNotifyPatients == null) {
      this.bulkNotifyPatients = this.sessionHelper.getBulkNotifyPatients();
      this.patientService.setBulkNotifyPatients(this.encryptionService.encryptUsingAES256(JSON.stringify(this.bulkNotifyPatients)));
    }
    
    this.isPatientModeEnabled = this.sessionHelper.getPatientModeSetting().IsEnabled;
    this.patientModePassword = this.sessionHelper.getPatientModeAdminPassword(true);
    this.preventBrowserBackNavigation();

    // load mobile data from session
    this.mobileCountryCodeList = this.sessionHelper.getNotifyMobileCountryCode();
    this.mobileServiceProviderList = this.sessionHelper.getNotifyMobileSvcProviders();

    this.loadMessageData();
    this.loadNotifyModuleData();
  }

  private preventBrowserBackNavigation() {
    if (this.isPatientModeEnabled) {
      this.loggingService.logDebug("Patient Mode is enabled. Restricting browser back navigation event.");
      history.pushState(null, null, location.href); 
      window.onpopstate = (event) => {      
          window.history.go(1);
      };        
    } else {
      this.loggingService.logDebug("Patient Mode is disabled. Allow normal browser back navigation");
    }
  }
    
  private loadMessageData(){
    // Start loading bar
    this.loadingBarService.startBar();
    this.notifyService.getMessage(this.customerId)
      .subscribe({ 
          next: (messageResult: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(messageResult.payload);
                this.loggingService.logVerbose(decryptedPayload);
                this.messageList = JSON.parse(decryptedPayload);
                this.notifyService.getMessageCategory(this.customerId, true)
                  .subscribe({
                      next: (messageCategoryResult: CloudApiResponse) => {
                            var decryptedCatPayload = this.encryptionService.decryptUsingAES256(messageCategoryResult.payload);
                            this.loggingService.logVerbose(decryptedCatPayload);
                            var messageCategory: MessageCategory[] = JSON.parse(decryptedCatPayload);                           
                            // Populate System Messages to send Opt-In Text
                            var systemMsgCategory:MessageCategory = messageCategory.find(x=>x.MessageCategoryName.toLowerCase() == 'system');
                            if(systemMsgCategory != null){
                              this.systemMessageCategoryId = systemMsgCategory.MessageCategoryId;
                              this.populateSystemMessageLanguages();
                            }
                            // Hide the system categories from the UI
                            this.messageCategoryList = messageCategory.filter(x=>x.MessageCategoryName.toLowerCase() != 'system');
                            
                            this.isMessageDataLoaded = true;
                          }, 
                      error: () => {
                          this.notificationBarService.showError("Error loading message categories");
                          this.loggingService.logError("Error loading message categories");
                          this.loadingBarService.stopBar();
                          },
                      complete: () => { this.loggingService.logVerbose("Completed loading message categories"); }
                  });
              },
          error: () => {
                this.notificationBarService.showError("Error loading messages");
                this.loggingService.logError("Error loading messages");
                this.loadingBarService.stopBar();
              },
          complete: () => { this.loggingService.logVerbose("Completed loading messages"); }
      });
  }
  
  // Get notify module related Data 
  private loadNotifyModuleData(){
    
    this.notifyGeneralService.getLanguages(this.customerId, true)
      .subscribe({ 
          next: (languagesResult: CloudApiResponse) => {
                this.loggingService.logVerbose(languagesResult);
                let languages: CustomerLanguagePreference[] = languagesResult.payload;
                if (languages != undefined) {
                  this.languageList = languages.filter(m => m.isEnabledForNotify);
                }          
                if (this.mobileCountryCodeList == null ||  this.mobileServiceProviderList == null) {
                  this.notifyContactsService.getMobileCountryCode()
                  .subscribe({ 
                      next: (mobileCountryCodesResult: CloudApiResponse) => {
                            this.loggingService.logVerbose(mobileCountryCodesResult);
                            this.mobileCountryCodeList = mobileCountryCodesResult.payload;
                            // Set in session
                            this.sessionHelper.setNotifyMobileCountryCode(this.mobileCountryCodeList);
                            this.notifyContactsService.getMobileServiceProvider()
                              .subscribe({
                                  next: (mobileServiceProvidersResult: CloudApiResponse) => {
                                        this.loggingService.logVerbose(mobileServiceProvidersResult);
                                        this.mobileServiceProviderList = mobileServiceProvidersResult.payload;
                                        // Set in session     
                                        this.sessionHelper.setNotifyMobileSvcProviders(this.mobileServiceProviderList);

                                        // Load contacts
                                        this.loadContactData();
                                      },
                                      error: () => {
                                            this.notificationBarService.showError("Error loading mobile service provider");
                                            this.loggingService.logError("Error loading mobile service provider");
                                          },
                                      complete: () => { this.loggingService.logVerbose("Completed loading mobile service provider"); }
                                  });
                              },
                          error: () => {
                                this.notificationBarService.showError("Error loading country codes");
                                this.loggingService.logError("Error loading country codes");
                              },
                          complete: () => { this.loggingService.logVerbose("Completed loading country coodes"); }
                      });
                }  else {
                  this.loadContactData();
                }              
              },
          error: () => {
                this.notificationBarService.showError("Error loading languages");
                this.loggingService.logError("Error loading languages");
              },
          complete: () => { this.loggingService.logVerbose("Completed loading languages"); }
      });
                          
  }

  private loadContactData(){
    // Start loading bar
    this.loadingBarService.startBar();
    
    this.notifyService.getContactCategories(this.customerId)
      .subscribe({ 
          next: (contactCategoryResult: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(contactCategoryResult.payload);
                this.loggingService.logVerbose(decryptedPayload);                
                var contactCategory: ContactCategory[] = JSON.parse(decryptedPayload);
                if(this.currentPatientGuid == null && (this.bulkNotifyPatients == null || this.bulkNotifyPatients.length == 0)){
                  contactCategory.forEach(x=> {
                    if(x.ContactCategoryName.toLowerCase() != 'patient contact'){
                      this.contactCategoryList.push(x);
                    }
                  });
                } else {
                  contactCategory.forEach(x=> {
                    this.contactCategoryList.push(x);
                  });
                }
                this.selectedContactCategory = this.contactCategoryList[0];
                this.isDisplayPatientContactAdd = (this.selectedContactCategory.IsDeletable == false);    
                this.loadContacts();
              }, 
          error: () => {
                this.notificationBarService.showError("Error loading Contact Categories");
                this.loggingService.logError("Error loading Contact Categories");
                this.loadingBarService.stopBar();
              },
          complete: () => { this.loggingService.logVerbose("Completed loading Contact Categories"); }
      }); 
  }

  private loadContacts(){
    this.notifyGeneralService.getCustomerNotifyConfig(this.customerId)
      .subscribe({
          next: (result: CloudApiResponse) => {
                this.loggingService.logVerbose(result);
                if(result != null && result.payload != null) {
                  var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                  let applicationConfigs: ApplicationConfig[] = JSON.parse(decryptedPayload);
                  applicationConfigs.forEach(config => {
                    if(config.Name == "IsSMSOptInEnabled"){
                      this.isSMSOptInEnabled = (config.Value == "1") ? true: false;
                    }
                  });
                  this.customerNotifyConfigs = applicationConfigs;
                }
                this.loadingBarService.stopBar(); 
              },
          error: () => {
                this.loggingService.logError("Error fetching notify preferences");
                this.notificationBarService.showError("Error fetching data");
                this.loadingBarService.stopBar(); 
              },
          complete: () => { this.loggingService.logVerbose("Completed fetching notify preferences"); }
      });


    this.notifyService.getGlobalContacts(this.customerId)
      .subscribe({ 
        next: (globalContactsResult: CloudApiResponse) => {
              this.loggingService.logVerbose(globalContactsResult);
              var decryptedContactPayload = this.encryptionService.decryptUsingAES256(globalContactsResult.payload);
              var globalContacts: GlobalContact[] = JSON.parse(decryptedContactPayload);
              this.globalContactList = globalContacts;
              if(this.currentPatientGuid != null){
                this.notifyService.getPatientContacts(this.customerId, this.currentPatientGuid)
                .subscribe({ 
                    next: (patientContactsResult: CloudApiResponse) => {
                          this.loggingService.logVerbose(patientContactsResult);
                          var decryptedContactPayload = this.encryptionService.decryptUsingAES256(patientContactsResult.payload);
                          var patientContacts: PatientContact[] = JSON.parse(decryptedContactPayload);
                          this.loggingService.logVerbose(patientContacts);
                          var patientContactCategoryId = this.contactCategoryList.find(x=>x.ContactCategoryName == "Patient Contact").ContactCategoryId;
                          patientContacts.forEach(patientContact => {
                            patientContact.ContactCategoryId = patientContactCategoryId;
                            var lang = this.languageList.find(x=>x.languageId == patientContact.LanguageId);
                            patientContact.LanguageName = '';
                            if(lang != undefined && lang != null){
                              patientContact.LanguageName = lang.languageName;
                            }
                            patientContact.MobileServiceProviderName = this.mobileServiceProviderList.find(x=>x.mobileServiceProviderId == patientContact.MobileServiceProviderId).serviceProviderName;
                            patientContact.MobileCountryCode = this.mobileCountryCodeList.find(x=>x.mobileCountryCodeId == patientContact.MobileCountryCodeId).countryCode;
                          });
                          this.patientContactList = patientContacts;
                          this.concatenateContactData(this.globalContactList, this.patientContactList);  
                        }, 
                    error: () => {
                          this.notificationBarService.showError("Error loading Patient Contacts");
                          this.loggingService.logError("Error loading Patient Contacts");
                          this.loadingBarService.stopBar();
                        },
                    complete: () => { this.loggingService.logVerbose("Completed loading Patient Contacts"); }
                });
              } else if(this.bulkNotifyPatients != null && this.bulkNotifyPatients.length > 0) {
                this.notifyService.getSelfContactForPatients(this.customerId, this.bulkNotifyPatients)
                .subscribe({ 
                    next: (patientContactsResult: CloudApiResponse) => {                          
                          var decryptedContactPayload = this.encryptionService.decryptUsingAES256(patientContactsResult.payload);
                          var patientContacts: PatientContact[] = JSON.parse(decryptedContactPayload);
                          this.loggingService.logVerbose(patientContacts);
                          var patientContactCategoryId = this.contactCategoryList.find(x=>x.ContactCategoryName == "Patient Contact").ContactCategoryId;
                          patientContacts.forEach(patientContact => {
                            patientContact.ContactCategoryId = patientContactCategoryId;
                            var lang = this.languageList.find(x=>x.languageId == patientContact.LanguageId);
                            patientContact.LanguageName = '';
                            if(lang != undefined && lang != null){
                              patientContact.LanguageName = lang.languageName;
                            }
                            patientContact.MobileServiceProviderName = this.mobileServiceProviderList.find(x=>x.mobileServiceProviderId == patientContact.MobileServiceProviderId).serviceProviderName;
                            patientContact.MobileCountryCode = this.mobileCountryCodeList.find(x=>x.mobileCountryCodeId == patientContact.MobileCountryCodeId).countryCode;
                          });
                          this.patientContactList = patientContacts;
                          this.concatenateContactData(this.globalContactList, this.patientContactList);  
                        }, 
                    error: () => {
                          this.notificationBarService.showError("Error loading Bulk Notify Patient Contacts");
                          this.loggingService.logError("Error loading Bulk Notify Patient Contacts");
                          this.loadingBarService.stopBar();
                        },
                    complete: () => { this.loggingService.logVerbose("Completed loading Bulk Notify Patient Contacts"); }
                });
              
              } else {
                this.concatenateContactData(globalContacts, []); 
              }
            }, 
        error: () => {
              this.notificationBarService.showError("Error loading Global Contacts");
              this.loggingService.logError("Error loading Global Contacts");
              this.loadingBarService.stopBar();
            },
        complete: () => { this.loggingService.logVerbose("Completed loading Global Contacts"); }
    }); 
  }

  private concatenateContactData(globalContacts:GlobalContact[], patientContacts: PatientContact[]){
    let allContactList: PatientContact[] = [];
    let globalContactList: PatientContact[] = [];
    var globalToPatientContact:PatientContact = new PatientContact();

    if(globalContacts != null && patientContacts != null){
      globalContacts.forEach(globalContact => {
        globalToPatientContact = new PatientContact();
        globalToPatientContact.Alias = globalContact.Alias;
        globalToPatientContact.ContactCategoryId = globalContact.ContactCategoryId;
        globalToPatientContact.ContactId = globalContact.ContactId;
        globalToPatientContact.Email = globalContact.Email;
        globalToPatientContact.FirstName = globalContact.FirstName;
        globalToPatientContact.IsDeleted = globalContact.IsDeleted;
        globalToPatientContact.IsContactPreferred = globalContact.IsContactPreferred;
        globalToPatientContact.IsEmailPreferred = globalContact.IsEmailPreferred;
        globalToPatientContact.IsSMSPreferred = globalContact.IsSMSPreferred;
        globalToPatientContact.LanguageId = globalContact.LanguageId;
        globalToPatientContact.LastName = globalContact.LastName;
        globalToPatientContact.MiddleName = globalContact.MiddleName;
        globalToPatientContact.Mobile = globalContact.Mobile;
        globalToPatientContact.MobileCountryCodeId = globalContact.MobileCountryCodeId;
        globalToPatientContact.MobileServiceProviderId = globalContact.MobileServiceProviderId;
        globalToPatientContact.ContactType = globalContact.ContactType;
        globalContactList.push(globalToPatientContact);
      });
      allContactList = globalContactList.concat(patientContacts);
    }
    this.contactList = allContactList;
    this.loggingService.logVerbose(this.contactList);
    this.populateContactListToShow(this.selectedContactCategory.ContactCategoryId);
    this.populateMessageCategoryListToShow(this.selectedContactCategory.ContactCategoryId);  
    // Load Message list to show
    if (this.messageCategoryListToShow.length > 0) {
      this.populateMessageListToShow(this.messageCategoryListToShow[0].MessageCategoryId);
    }  
    this.loadingBarService.stopBar(); 
  }

  private refreshPatientContacts(){
    this.loadContacts();
  }

  public openContactEditor(contact:PatientContact) {
    this.loggingService.logVerbose("Open Editor");
    this.loggingService.logVerbose(contact);
    let contactEditorData:ContactEditorData = new ContactEditorData();
    contactEditorData.contactData = contact;
    contactEditorData.customerId = this.customerId;
    contactEditorData.contactCategoryData = this.selectedContactCategory;
    contactEditorData.mobileCountryCodeData = this.mobileCountryCodeList;
    contactEditorData.mobileServiceProviderData = this.mobileServiceProviderList;
    contactEditorData.languageData = this.languageList;
    contactEditorData.systemMessage = this.systemMessageLaguages;
    if(contact == null){
      contactEditorData.contactOptInStatus = OptInStatusType.Unknown;
    } else{
      contactEditorData.contactOptInStatus = contact.OptInStatusEnumVal;
    }
    
    if (this.isPatientModeEnabled) {
      this.sessionHelper.setIsPatientModeInUse(true);
    }
    const dialogRef = this.dialog.open(ContactEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: contactEditorData,
      minWidth: '40vw',
      maxWidth: '40vw',
      disableClose: true,
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.refreshPatientContacts();
      } 
      if (this.isPatientModeEnabled) {
        this.showAddMoreContactsDialog();        
      }      
    });
  }

  public deleteContact(contact:PatientContact){
    this.loggingService.logInformation(contact);

    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to delete '" + contact.FirstName + " " + contact.LastName + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loggingService.logVerbose("Delete initiated");
        
        if (this.selectedContactCategory.ContactCategoryName.toLocaleLowerCase() == "patient contact") {
          if (this.currentPatientGuid != null) { // patient selected
            this.notifyService.deletePatientContact(this.customerId, contact.ContactId)
              .subscribe({ 
                  next: () => {
                        this.notificationBarService.showSuccess("Deleted " +  contact.FirstName);
                        this.loggingService.logInformation("contact " +  contact.FirstName + " has been deleted");
                        let patContact:PatientContact = new PatientContact();
                        patContact.PatientGUID = this.currentPatientGuid,
                        patContact.FirstName = contact.FirstName,
                        patContact.MiddleName = contact.MiddleName,
                        patContact.LastName = contact.LastName,
                        patContact.Alias = contact.Alias,
                        patContact.Email = contact.Email,
                        patContact.LanguageId = contact.LanguageId,
                        patContact.IsContactPreferred = contact.IsContactPreferred,
                        patContact.IsEmailPreferred = contact.IsEmailPreferred,
                        patContact.IsSMSPreferred = contact.IsSMSPreferred,
                        patContact.ContactCategoryId = contact.ContactCategoryId
                                          
                        // Remove the item from contact list
                        let contacts:PatientContact[] = [];
                        this.contactList.forEach(x=> {
                          if(x.ContactId != contact.ContactId){
                            contacts.push(x);
                          }
                        });
                        // updating the local property
                        this.contactList = contacts;
                        this.populateContactListToShow(this.selectedContactCategory.ContactCategoryId);
                      },
                  error: () => {
                        this.notificationBarService.showError("Error deleting " +  contact.FirstName);
                        this.loggingService.logError("Error deleting contact " +  contact.FirstName);
                      },
                  complete: () => { this.loggingService.logVerbose("Completed deleting contact " +  contact.FirstName); }
                });            

          } 
        }
      } else {
        this.loggingService.logVerbose("Delete was cancelled");
      }
    });
  }

  public ContactCategoryTabChange(tabChangeEvent: MatTabChangeEvent) {
    this.loggingService.logVerbose(tabChangeEvent.tab.textLabel);
    this.selectedContactCategory = this.getContactCategoryById(tabChangeEvent.tab.textLabel);
    this.loggingService.logVerbose(this.selectedContactCategory.ContactCategoryName);
    this.isDisplayPatientContactAdd = (this.selectedContactCategory.IsDeletable == false);    
    this.ContactCategoryTabChangeLogic(tabChangeEvent.tab.textLabel);
  }

  private ContactCategoryTabChangeLogic(ContactCategoryId: string){
    this.populateMessageCategoryListToShow(ContactCategoryId);
    this.populateContactListToShow(ContactCategoryId);
    if(this.messageCategoryListToShow.length > 0) {
      this.messageCategoryTabChangeLogic(this.messageCategoryListToShow[0].MessageCategoryId);
    } else {
      this.clearPreviousMessageSend(false);
    }
  }

  private populateMessageCategoryListToShow(ContactCategoryId: string){
    let tempMessageCategories:MessageCategory[] = [];
    this.messageCategoryListToShow = [];
    this.messageCategoryList.forEach((messageCategory:MessageCategory) => {
      if(messageCategory.ContactCategoryId == ContactCategoryId)
      {
        tempMessageCategories.push(messageCategory);
      }
    });
    this.messageCategoryListToShow = tempMessageCategories;
  }

  private populateContactListToShow(ContactCategoryId: string){
    let tempContacts:PatientContact[] = [];
    this.contactList.forEach((contact:PatientContact) => {
      if(contact.ContactCategoryId == ContactCategoryId)
      {
        tempContacts.push(contact);
      }
    });
    this.contactListToShow = tempContacts;
    this.isContactDataLoaded = true;
  } 

  public messageCategoryTabChange(tabChangeEvent: MatTabChangeEvent):void{
    this.loggingService.logVerbose(tabChangeEvent.tab.textLabel);
    this.messageCategoryTabChangeLogic(tabChangeEvent.tab.textLabel);
  }

  private messageCategoryTabChangeLogic(messageCategoryId: string){
    // Clear selected message
    this.clearPreviousMessageSend(false);
    this.populateMessageListToShow(messageCategoryId);
  }

  // Clear selected message
  private clearPreviousMessageSend(isNewMessage:boolean){
    if(!isNewMessage)
      this.messageToSend = new Message();
    this.messageSendResult = '';
    this.showMsgSendDetails = false;
    this.showMsgSendDetailsBox = false;
    this.enableMsgSendButton = false;
  }

  private populateMessageListToShow(messageCategoryId: string){
    let tempMessages:Message[] = [];
    this.messageListToShow = [];
    this.messageList.forEach((message:Message) => {
      if(message.MessageCategoryId == messageCategoryId && message.LanguageName.toLowerCase() == 'english')
      {
        tempMessages.push(message);
      }
    });
    this.messageListToShow = tempMessages;
  }

  public verifyOptInStatus(contact:PatientContact){
    // TODO: add logic to verify OptInStatus
    let originalValue = contact.OptInStatusEnumVal;
    contact.OptInStatusEnumVal = -1;
    this.notifyService.getOptInStatus(this.customerId, contact.ContactId)
      .subscribe({
        next:(verifyOptInResponseResult:CloudApiResponse)=>{
          this.loggingService.logVerbose(verifyOptInResponseResult);
          //var decryptedContactPayload = this.encryptionService.decryptUsingAES256(verifyOptInResponseResult.payload);
          var optInStatusType: OptInStatusType = JSON.parse(verifyOptInResponseResult.payload);
          contact.OptInStatusEnumVal = optInStatusType;
        },
        error:()=>{
          contact.OptInStatusEnumVal = originalValue;
        },
        complete:()=>{
        }
      });
  }

  public sendMessage(){
    this.clearPreviousMessageSend(true);
    let isMessageSend:Boolean = false;
    if(this.messageToSend.MessageText != ''){
      let recipientList: PatientContact[] = [];
      recipientList = this.getContactsToSendMessage();
      if(recipientList.length > 0){
        this.loadingBarService.startBar();
        // Disable the send button to avoid multiple clicks
        this.enableMsgSendButton = false;
        let messageSendRequest:MessageSendRequest = new MessageSendRequest();
        let msgToSendLanguage: MessageLanguages = new MessageLanguages();
        let msgLanguageText: MessageLanguageText = new MessageLanguageText();

        msgToSendLanguage.messageId = this.messageToSend.MessageId;
        messageSendRequest.message = msgToSendLanguage;

        this.messageList.forEach(msg => {
          if(msg.MessageId == this.messageToSend.MessageId){
            msgLanguageText = new MessageLanguageText();
            msgLanguageText.languageId = msg.LanguageId;
            msgLanguageText.messageText = msg.MessageText;
            messageSendRequest.message.messageLanguageTexts.push(msgLanguageText);
          }
        });


        messageSendRequest.recipientList = recipientList;
        messageSendRequest.emailType = EmailSendType.NotifyModule_Regular;
        messageSendRequest.smsType = SMSSendType.NotifyModule_Regular;
        
        this.notifyService.sendMessage(this.customerId, messageSendRequest)
          .subscribe({
              next: (messageSendResponseResult:CloudApiResponse) => {
                    this.loggingService.logVerbose(messageSendResponseResult);
                    var decryptedContactPayload = this.encryptionService.decryptUsingAES256(messageSendResponseResult.payload);
                    var messageSendResponse: MessageSendResponse = JSON.parse(decryptedContactPayload);
                    this.loggingService.logVerbose(messageSendResponse);
                    if(messageSendResponse != null){
                      var emailSendResponse:EmailSendResponse = messageSendResponse.EmailSendResponse;
                      var smsSendResponse:SMSSendResponse = messageSendResponse.SMSSendResponse;
                      this.populateMessageSendDetails(emailSendResponse, smsSendResponse);
                      if((messageSendResponse.IsSent)){
                        isMessageSend = true;
                      }
                      if(isMessageSend){
                        this.messageSendResultClass = "colorSuccessDark";
                        this.messageSendResult = "Message sent";
                        this.notificationBarService.showSuccess("Message sent");
                      } else {
                        this.messageSendResultClass = "colorError";
                        this.messageSendResult = "One or more messages failed.";
                        this.notificationBarService.showError("One or more messages failed.");
                      }
                    }
                    if((emailSendResponse != null && emailSendResponse.EmailProviderResponses.length > 0) ||
                          (smsSendResponse != null && smsSendResponse.SMSProviderResponses.length > 0)) {
                            this.showMsgSendDetails = true;
                    }
                    this.enableMsgSendButton = true;
                    this.loadingBarService.stopBar();
                  },
              error: () => {
                    this.messageSendResultClass = "colorError";
                    this.messageSendResult = "Error sending message";
                    this.notificationBarService.showError("Error sending message");
                    this.loggingService.logInformation("Error sending message");
                    //this.showMsgSendDetails = true;
                    this.enableMsgSendButton = true;
                    this.loadingBarService.stopBar();
                  },
              complete: () => { this.loggingService.logVerbose("Completed sending mesage"); }
          });
         
      } else {
        this.messageSendResultClass = "colorError";
        this.messageSendResult = "Please select a contact or contact point.";
        this.enableMsgSendButton = true;
      }
    } else {
      this.messageSendResultClass = "colorError";
      // Select a message to send
      this.messageSendResult = "Please select a message.";
    }
  }

  public sendWelcomeText(contact:PatientContact){
    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to send welcome text to '" + contact.FirstName + " " + contact.LastName + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loadingBarService.startBar();
        // Clear previous message send options and results
        this.clearPreviousMessageSend(false);
        if(this.systemMessageLaguages != null && this.systemMessageLaguages.messageId != null){
          this.loggingService.logVerbose('Sending welcome text');
          let messageSendRequest:MessageSendRequest = new MessageSendRequest();
          messageSendRequest.message = this.systemMessageLaguages;
          messageSendRequest.recipientList.push(contact);
          messageSendRequest.smsType = SMSSendType.NotifyModule_OptInText;

          this.notifyService.sendMessage(this.customerId, messageSendRequest)
            .subscribe({
              next: (messageSendResponseResult:CloudApiResponse) => {
                    this.loggingService.logVerbose(messageSendResponseResult);
                    var decryptedContactPayload = this.encryptionService.decryptUsingAES256(messageSendResponseResult.payload);
                    var messageSendResponse: MessageSendResponse = JSON.parse(decryptedContactPayload);
                    this.loggingService.logVerbose(messageSendResponse);
                    if(messageSendResponse != null){
                      if((messageSendResponse.IsSent)){
                        this.loggingService.logVerbose("Welcome text sent");
                        this.notificationBarService.showSuccess("Welcome text sent");
                      } else {
                        this.notificationBarService.showError("Failed sending welcome text");
                        this.loggingService.logError("Failed sending welcome text");


                        let welcomeSMSFailedBoxData:MessageBoxModel = new MessageBoxModel();
                        welcomeSMSFailedBoxData.message = messageSendResponse.SMSSendResponse.SMSProviderResponses[0].ResponseMessage;
                        welcomeSMSFailedBoxData.messageBoxType = MessageBoxType.error;
                        const dialogRef = this.dialog.open(MessageBoxComponent, {
                          backdropClass: '',
                          panelClass:'[{ContentBox}]',
                          data: welcomeSMSFailedBoxData,
                          minWidth: '50vw',
                          disableClose: true,
                        });
                      }
                    }
                    this.loadingBarService.stopBar();
                },
              error: () => {
                this.notificationBarService.showError("Error sending welcome text");
                this.loggingService.logError("Error sending welcome text");
                this.loadingBarService.stopBar();
                },
              complete: () => { this.loggingService.logVerbose("Completed sending welcome text mesage"); }
          });
        } else {
          this.loggingService.logError('system messageCategoryId not found');
          this.notificationBarService.showError("Error sending welcome text");
          this.loadingBarService.stopBar();
        }
      }
    });
  }

  private populateMessageSendDetails(emailSendResponse:EmailSendResponse, smsSendResponse:SMSSendResponse){
    var messageSendResult:MessageSendResultDetails = new MessageSendResultDetails();
    this.messageSendResultDetails = [];

    if(emailSendResponse != null && emailSendResponse.EmailProviderResponses != null){
      emailSendResponse.EmailProviderResponses.forEach(sendResponse => {

        if(sendResponse!=null) {
          sendResponse.EmailProviderResponse.forEach(providerResponse => {
            messageSendResult = new MessageSendResultDetails();
            messageSendResult.contactName = providerResponse.Recipient.FirstName + " " + providerResponse.Recipient.LastName;
            messageSendResult.contactPoint = providerResponse.Recipient.Email;
            messageSendResult.isSent = sendResponse.IsSent;
            messageSendResult.responseMessage = sendResponse.ResponseMessage;
            this.messageSendResultDetails.push(messageSendResult);
          });
        }
      });
    }

    if(smsSendResponse != null && smsSendResponse.SMSProviderResponses != null) {
      smsSendResponse.SMSProviderResponses.forEach(sendResponse => {
        if(sendResponse!=null) {
          sendResponse.SMSProviderResponse.forEach(providerResponse => {
            messageSendResult = new MessageSendResultDetails();
            messageSendResult.contactName = providerResponse.Recipient.FirstName + " " + providerResponse.Recipient.LastName;
            messageSendResult.contactPoint = providerResponse.Recipient.Mobile;
            messageSendResult.isSent = providerResponse.IsSent;
            messageSendResult.responseMessage = providerResponse.ResponseMessage ;
            this.messageSendResultDetails.push(messageSendResult);
          });
        }
      });
    }
  }

  private getContactCategoryById(ContactCategoryId:string):ContactCategory{
    let ContactCategory:ContactCategory = null;
    ContactCategory = this.contactCategoryList.find(x=>x.ContactCategoryId == ContactCategoryId);
    return ContactCategory;
  }

  private onMessageSelection(message:Message, target: HTMLElement) {
    this.messageToSend = message;
    this.enableMsgSendButton = true;
    this.clearAllMessageSelection();
    var parentElem: HTMLElement = target.parentElement;
    parentElem.parentElement.parentElement.classList.add("isActive");
  }
  
  private clearAllMessageSelection() {
    var listItemElems = document.querySelectorAll<HTMLElement>(".mat-mdc-list-item")
    listItemElems.forEach((elem) => {
      elem.classList.remove("isActive");
    });
  }

  public onClickSendDetails(){
    this.showMsgSendDetailsBox = true;
  }

  public onClickSendDetailsClose(){
    this.showMsgSendDetailsBox = false;
  }

  private getContactsToSendMessage():PatientContact[]{
    let contacts: PatientContact[] = [];
    this.contactListToShow.forEach(contact =>{
      if(contact.IsContactPreferred 
          && (contact.IsEmailPreferred || contact.IsSMSPreferred)) {
            contacts.push(contact);
      }
    });
    this.loggingService.logVerbose(contacts.length);
    return contacts;
  }

  private populateSystemMessageLanguages(){
    if(this.messageList != null && this.messageList.length > 0){
      this.systemMessageLaguages = new MessageLanguages();
      let msgLangText:MessageLanguageText = new MessageLanguageText();
      this.messageList.forEach(msg => {
        if(msg.MessageCategoryId == this.systemMessageCategoryId){
          msgLangText = new MessageLanguageText();
          this.systemMessageLaguages.messageId = msg.MessageId;
          msgLangText.languageId = msg.LanguageId;
          msgLangText.messageText = msg.MessageText;
          this.systemMessageLaguages.messageLanguageTexts.push(msgLangText);
        }
      });
    }
  }

  showAddMoreContactsDialog() {
      
    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Add more Contacts?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loggingService.logVerbose("Opening Add contact dialog");
        this.openContactEditor(null);
      } else {
        this.loggingService.logVerbose("Add more contacts was cancelled");
        this.requestPatientModePassword();
      }
    });
  }


  requestPatientModePassword() {
    
    if (this.isPatientModeEnabled ) {
      
        if (!this.dialogRef || this.dialogRef.getState() !== MatDialogState.OPEN) {
          
            // Open dialog requesting password
            this.dialogRef = this.dialog.open(ValidateInputDialogComponent, {
              data: { title: "Enter Password", message: "Enter password to exit", 
                      errorMessage: "Invalid password", inputLabel: "", validateInputWith: this.patientModePassword},
              disableClose: true,
              //closeOnNavigation: false,
              enterAnimationDuration: '0ms',
              exitAnimationDuration: '0ms'
            });
      
            this.dialogRef.afterClosed().subscribe(isValidated => {
              this.loggingService.logVerbose('The password dialog was closed');
              
              if (!isValidated) {
                this.showAddMoreContactsDialog();
              } else {
                this.sessionHelper.setIsPatientModeInUse(false);               
              }
            });
        }      
    } 
  }
}
