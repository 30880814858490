// Angular
import { Component, AfterViewInit } from '@angular/core';
import { Subject, Subscription, merge, of, fromEvent, Observable } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators';
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';

// Shared Lib
import { AuthenticatedUserInfo, coreAppPageMode, PatientModeSetting, PatientModeAdminConfig } from 'kscigcorelib';
import { LoggingService, CoreHelper, CloudApiResponse, IdleTimeoutService, HeartbeatService, EncryptionService } from 'kscigcorelib';

// Application
import { SessionHelper } from './shared/helpers/session.helper';
import { CustomerService } from '../app/shared/services/customer.service';
import { RouteHelper } from './shared/helpers/route.helper';

import { AuthService } from './shared/services/auth.service';
import { IdleTimeoutSetting } from './shared/models/idletimeout.model';
import { IdleTimeoutSettingService } from './shared/services/idletimeoutsetting.service';
import { UIElementService } from './shared/services/uielement.service';
import { PatientModeService } from './shared/services/patientmode.service';
import { DeviceDetectorService } from './shared/services/devicedetector.service';
import { DeviceCookieService } from './shared/services/ksdevicecookie.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();
  public isUserLoggedIn = false;
  public bodyClass:string = "bodyBaseColor";
  public coreAppPageModeEnum = coreAppPageMode;
  public coreAppPageMode = coreAppPageMode.none;

  public isWorklistEnabled:boolean = false;
  public isNotifyEnabled:boolean = false;
  public isMediaEnabled:boolean = false;

  public networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(
    private loggingService: LoggingService,
    private sessionHelper: SessionHelper,
    private routeHelper: RouteHelper,
    private customerService: CustomerService,
    private msalBroadcastService:MsalBroadcastService,
    private coreHelper:CoreHelper,
    private router: Router,
    private heartbeatService: HeartbeatService,
    private idleTimeoutService: IdleTimeoutService,
    private idleTimeoutSettingService: IdleTimeoutSettingService,
    private elemService: UIElementService,
    private authService: AuthService,
    private patientModeService: PatientModeService,
    private deviceService: DeviceDetectorService,
    private encryptionService: EncryptionService,
    private deviceCookieService: DeviceCookieService
    ) { 
      router.events.subscribe((val) => {
        if(val instanceof NavigationEnd){
          this.loggingService.logVerbose("------ AppComponent Route NavigationEnd ---------");          
        }
        if (val instanceof NavigationStart) {  
          if (!val.url.toLowerCase().endsWith("logout") && this.sessionHelper.getIsPatientModeInUse()) {
             this.routeHelper.NavigateToLogoutPage();
          }     
        } 
      });

      this.idleTimeoutSettingService.getIdleTimeoutObservable().subscribe({
                                  next: (x) => { 
                                    this.enableIdleTimeoutTimer(x); },
                                  error: () => { this.loggingService.logError("Failed to enable/disable idle timeout"); }
                            });  

    }

  public ngOnInit(): void {
    this.checkNetworkStatus();
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.loggingService.logVerbose("----InteractionStatus None")
        this.coreAppPageMode = this.coreHelper.getApplicationPageMode();
        this.loggingService.logVerbose("App PageMode: " + this.coreAppPageMode);
        if(this.coreAppPageMode == this.coreAppPageModeEnum.login){
          this.bodyClass = "";
        }
        this.validateUserSession();
      });
     
      //this.deviceIsTablet = this.deviceService.isTablet();
      this.loggingService.logDebug("Device is tablet: " + this.deviceService.isTablet());
  }

  public ngAfterViewInit() {
    this.updateContentViewHeight();

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(e => {
      this.updateContentViewHeight();
    });
  }

  private updateContentViewHeight() {        
    let elem = this.elemService.getHtmlElement("app-grid-content");
    if (elem != undefined) {
      elem.style.height = (window?.innerHeight - this.elemService.getHeaderHeight() - this.elemService.getFooterHeight()) + "px";                  
    }
  }

  private validateUserSession() {
    this.loggingService.logVerbose("Validate UserSession via landing page");
    
    if(this.sessionHelper.isValidUserSession()){
      var userAccount:AuthenticatedUserInfo = this.sessionHelper.getLoggedInUser();
      this.loggingService.logVerbose("User found");
      this.loggingService.logVerbose(userAccount);
      
      this.heartbeatService.startTick(this.authService);

      this.loggingService.logDebug("Loading Idle Timeout setting");
      let idleTimeoutSetting: IdleTimeoutSetting = this.sessionHelper.getIdleTimeoutSetting();
      if (idleTimeoutSetting) {
        this.loggingService.logDebug("Loaded Idle Timeout setting from session");
      } else {
        this.idleTimeoutSettingService.getIdleTimeoutSetting(this.sessionHelper.getCustomerId(), this.deviceCookieService.getDeviceId(true))
          .subscribe({
              next: (idleTimeoutResult: CloudApiResponse) => {
                    this.loggingService.logVerbose(idleTimeoutResult.payload);
                    let idleTimeoutSettingPayload: IdleTimeoutSetting = idleTimeoutResult.payload;
                                    
                    if (idleTimeoutSettingPayload != null) {
                      this.loggingService.logDebug("Successfully loaded Idle Timeout setting");
                      let isIdleTimeoutDisabled = idleTimeoutSettingPayload.isTimeoutDisabled;  
                      // update session  
                      this.sessionHelper.setIdleTimeoutSetting(idleTimeoutSettingPayload);
                    
                      if (!isIdleTimeoutDisabled) {
                        this.enableIdleTimeoutTimer(!isIdleTimeoutDisabled);
                      }
                    }                    
                  },
              error: () => { this.loggingService.logError("Error loading Idle Timeout setting"); },
              complete: () => { this.loggingService.logVerbose("Completed loading Idle Timeout setting"); }
          });
      }
      
      this.loadPatientModeData();

      if((this.coreAppPageMode == this.coreAppPageModeEnum.login)){
        this.routeHelper.NavigateToApp();
      } else {
          this.isWorklistEnabled = this.sessionHelper.isWorklistModuleEnabled();
          this.isNotifyEnabled = this.sessionHelper.isNotifyModuleEnabled();
          this.isMediaEnabled = this.sessionHelper.isMediaModuleEnabled();
          
          // All good the app page is up
          this.loggingService.logDebug("Valid Session");
      }
    } else {
      this.loggingService.logInformation("Invalid session.");
      if(this.coreAppPageMode != this.coreAppPageModeEnum.login 
        && this.coreAppPageMode != this.coreAppPageModeEnum.logout
        && this.coreAppPageMode != this.coreAppPageModeEnum.home){
        this.routeHelper.NavigateToLoginPage();
      }
    }
  }

  private enableIdleTimeoutTimer(timeoutEnabled:boolean) {
        
    if (timeoutEnabled) {
        // load idle timeout minutes and start the timer
        this.customerService.getCustomerIdleTimeoutMinutes(this.sessionHelper.getCustomerId())
          .subscribe({
            next: (timeoutResult) => {
                  this.loggingService.logDebug("Idle Timeout Minutes: " + timeoutResult.payload);                  
                  this.idleTimeoutService.startTimer(this.authService, timeoutResult.payload);                  
              },
            error: () => {
                  this.loggingService.logDebug("Failed to get Idle Timeout Minutes, using default...");
                  this.idleTimeoutService.startTimer(this.authService);                  
              },
            complete: () => { this.loggingService.logVerbose("Completed getting Idle timeout minutes"); }            
          });          
    } else {
      this.idleTimeoutService.stopTimer();
    }
  }

  private checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
      });
  }

  private loadPatientModeData() {
    this.patientModeService.getPatientModeSetting(this.sessionHelper.getCustomerId(), this.deviceCookieService.getDeviceId(true), this.deviceService.isTablet())
        .subscribe({
            next: (pmodeResult: CloudApiResponse) => {
                  var deserializedResult = this.encryptionService.decryptUsingAES256(pmodeResult.payload);
                  let patientModePayload: PatientModeSetting = JSON.parse(deserializedResult);
                  this.loggingService.logVerbose(patientModePayload);                    
                  if (patientModePayload != null) {
                    // save to session and notify other components
                    this.sessionHelper.setPatientModeSetting(patientModePayload);
                    this.patientModeService.enablePatientMode(patientModePayload.IsEnabled);
                    this.loggingService.logDebug("Successfully loaded Patient mode. IsEnabled = " + patientModePayload.IsEnabled);
                    //if (patientModePayload.IsEnabled) {
                      this.loadPatientModePassword();
                    //}
                  }                                     
                },
            error: () => { this.loggingService.logError("Error getting Patient Mode setting"); },
            complete: () => { this.loggingService.logVerbose("Completed getting Patient Mode setting"); }
        });    
  }

  private loadPatientModePassword() {
    //if (patientModeIsEnabled) {
      this.loggingService.logDebug("Loading Patient mode password");
      this.patientModeService.getPatientModeAdminConfig(this.sessionHelper.getCustomerId())
      .subscribe({
          next: (apiResponse: CloudApiResponse) => {
                var deserializedResult = this.encryptionService.decryptUsingAES256(apiResponse.payload);
                let adminConfig: PatientModeAdminConfig = JSON.parse(deserializedResult);
                if (adminConfig  != null) {
                  let patientModePassword = this.encryptionService.decryptUsingAES256(adminConfig.Password);  
                  this.sessionHelper.setPatientModeAdminPassword(patientModePassword);              
                  this.loggingService.logDebug("Successfully loaded patient mode password");
                }
              },
          error: () => { this.loggingService.logError("Error loading Patient Mode password"); },
          complete: () => { this.loggingService.logVerbose("Completed loading patient mode password");}
      });
    //}  
  }

}
