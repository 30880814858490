// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Application Shared library
import { EnvService, LoggingService, coreAppPageMode } from 'kscigcorelib';

// Application
import { applicationToolbar, appPageName } from '../constants/app.enum';
import { SessionHelper } from './session.helper';


@Injectable({
  providedIn: 'root'
})
export class RouteHelper {

  public baseHref:string = '';

  constructor(
    private router:Router,
    private loggingService:LoggingService,
    private sessionHelper:SessionHelper,
    private envService: EnvService
    ) {
      if(this.envService.baseHref == '/'){
        this.baseHref = '';        
      } else {
        this.baseHref = this.envService.baseHref;
      }
     }

  public RoutePage(pagePath:string){
    this.router.navigateByUrl(pagePath);
  }

  public NavigatePage(pagePath:appPageName, queryString:string) {
    this.loggingService.logVerbose("Navigate request:" + pagePath + "queryString:" + queryString);
    this.loggingService.logVerbose("Basehref:" + this.envService.baseHref);
    if(queryString != null && queryString.length > 0){
      queryString = "?" + queryString;
    } else {
      queryString = "";
    }
    location.href = location.protocol + "//" + location.host + this.baseHref + pagePath.toString() + queryString;
  }

  public NavigateToLogoutPage(){
    this.NavigatePage(appPageName.logout, null);
  }

  public NavigateToLoginPage(){
    this.NavigatePage(appPageName.login, null);
  }

  public NavigateToApp(){
    if(this.sessionHelper.isWorklistModuleEnabled()){
      this.NavigatePage(appPageName.worklist, null);
    } else if (this.sessionHelper.isNotifyModuleEnabled()){
      this.NavigatePage(appPageName.notify, null);
    } else if(this.sessionHelper.isMediaModuleEnabled()){
      this.NavigatePage(appPageName.media, null);
    } else {
      this.NavigatePage(appPageName.settings, null);
    }
  }

  public getCurrentToolBarPageName():applicationToolbar{
    let locationPath = location.pathname;
    if(locationPath == this.baseHref + "/worklist"){
      return applicationToolbar.worklist;
    } else if(locationPath == this.baseHref + "/notify"){ 
      return applicationToolbar.notify;
    } else if(locationPath == this.baseHref + "/media"){ 
      return applicationToolbar.media;
    } else if(locationPath == this.baseHref + "/media/play"){ 
      return applicationToolbar.mediaPlay;
    } else { 
      return applicationToolbar.none;
    }   
  } 
}
