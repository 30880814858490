// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { HandleErrorService, CloudApiResponse, NotificationStatus, EnvService } from 'kscigcorelib';
import { ApiService } from 'kscigcorelib';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor( private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService:ApiService,
    private envService: EnvService
    ) { }

    public getNotifications(): Observable<CloudApiResponse> {
      return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiNotification', this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('getNotifications', []))
      );
    }

    public dismissNotification(customerId, notificationStatus:NotificationStatus): Observable<CloudApiResponse> {
      return this.http.post<CloudApiResponse>(this.envService.apiUrl + '/AppCoreApiNotification/dismiss?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(notificationStatus), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('dismissNotification'))
      );
    }
}