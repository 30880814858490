// Angular
import { Component, Input, OnInit } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';

// Material
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

// Application
import { SessionHelper } from '../shared/helpers/session.helper';

// Shared Lib
import { LoggingService, CoreHelper } from 'kscigcorelib';
import { UITreeInterface, UITreeInterfaceFlatNode } from 'kscigcorelib';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public selectedNodeName:string; 
  public isAdminRole:boolean;

  private TREE_DATA: UITreeInterface[] = [
    {
      name: 'App Features',
      children: [
        {name: 'General_App', expandable:false, level:0},        
      ]
    }
  ];
  
  /************** Tree set up code start ***************************/
  private _transformer = (node: UITreeInterface, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<UITreeInterfaceFlatNode>(
    node => node.level, 
    node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, 
    node => node.level, 
    node => node.expandable, 
    node => node.children);

  dataSource = new MatTreeFlatDataSource(
    this.treeControl, 
    this.treeFlattener);
/************** Tree set up code end ***************************/

  constructor(
    private loggingService:LoggingService,
    private coreHelper:CoreHelper,
    private sessionHelper:SessionHelper
  ) {
    
     this.isAdminRole = this.sessionHelper.isAdminRole();
    
    if (this.isAdminRole) {
      this.TREE_DATA[0].children.push(
        {name: 'User Subscriptions', expandable:false, level:0},      
      )
    }

    // Only add Notify related settings if Notify Module is enabled
    if(this.sessionHelper.isNotifyModuleEnabled() && this.isAdminRole){
      this.TREE_DATA.push({
        name: 'Notify',
        children: [
          {name: 'General_Notify', expandable:false, level:0},
          {name: 'Contact Categories', expandable:false, level:0},
          {name: 'Staff Contacts', expandable:false, level:0},
          {name: 'Message Categories', expandable:false, level:0},
          {name: 'Messages', expandable:false, level:0},
          {name: 'Notify Stats', expandable:false, level:0},
        ]
      });
    }
    
    // Only add Media related settings if Media Module is enabled
    if(this.sessionHelper.isMediaModuleEnabled() && this.isAdminRole){
      this.TREE_DATA.push({
        name: 'Media',
        children: [
          {name: 'Default', expandable:false, level:0},
        ]
      });
    } 

    this.dataSource.data = this.TREE_DATA;
    this.selectedNodeName = "Settings";
  }

  hasChild = (_: number, node: UITreeInterfaceFlatNode) => node.expandable;

  ngOnInit() {
    this.treeControl.expandAll();
    // Loading the first Setting page
    this.treeNodeSelected(this.treeControl.dataNodes[1]);
  }

  treeNodeSelected(node:UITreeInterfaceFlatNode){
    this.loggingService.logVerbose(node.name);
    this.selectedNodeName = node.name;
  }

  getDisplayTreeNodeName(nodeName:string) :string{
    return this.coreHelper.getDisplayTreeNodeName(nodeName);
  }
}
