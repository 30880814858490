// Angular
import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from 'rxjs';

// Shared Lib
import { CoreHelper, NotificationBarService, LoggingService, NotificationStatus } from 'kscigcorelib';
import { CloudApiResponse, coreAppPageMode, PatientModeSetting, EncryptionService, Patient } from 'kscigcorelib';

// Application Core
import { RouteHelper } from '../shared/helpers/route.helper';
import { SessionHelper } from '../shared/helpers/session.helper';
import { NotificationService } from '../shared/services/notification.service';
import { PatientModeService } from '../shared/services/patientmode.service';
import { DeviceDetectorService } from '../shared/services/devicedetector.service';
import { SharedPatientService } from '../shared/services/sharedpatient.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  @Input() public isWorklistEnabled:boolean = false;
  @Input() public isNotifyEnabled:boolean = false;
  @Input() public isMediaEnabled:boolean = false;

  public username:string;
  public userRole:string;
  public coreAppPageModeEnum = coreAppPageMode;
  public coreAppPageMode = coreAppPageMode.none;
  public isUserLoggedIn:boolean = false;
  public isAdminUser:boolean = false;
  public productName:string = "";
  
  public showProgressBar:boolean = false;
  public isPatientModeEnabled = false;
  public notifications:NotificationStatus[] = [];
  public notificationCount:number = 0;

  public deviceIsTablet: boolean;

  currentPatient: Patient;
  currentPatientAge: number;
  patientSubscription: Subscription;
  patientModeSubscription: Subscription;

  constructor(
    private coreHelper: CoreHelper,
    private routeHelper: RouteHelper,
    private sessionHelper: SessionHelper,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private notificationService: NotificationService,
    private patientModeService: PatientModeService,
    private deviceService: DeviceDetectorService,
    private patientService: SharedPatientService,
    private encryptionService: EncryptionService,
    router: Router) { 
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.initHeader();
        }
      });

      this.patientSubscription = this.patientService.getCurrentPatientObservable().subscribe(x => { this.setPatient(JSON.parse(this.encryptionService.decryptUsingAES256(x))); });
      this.patientModeSubscription = this.patientModeService.getPatientModeObservable().subscribe(x => { this.isPatientModeEnabled = x; });   
    }

  ngOnInit() {        
    
  }

  initHeader(){
    this.coreAppPageMode = this.coreHelper.getApplicationPageMode();
    this.productName = environment.productName;
    this.isUserLoggedIn = this.sessionHelper.isValidUserSession();
    this.deviceIsTablet = this.deviceService.isTablet();

    if(this.isUserLoggedIn){
      var userInfo = this.sessionHelper.getLoggedInUser();
      if(userInfo != null && userInfo != undefined){
        this.username = userInfo.Name;
        this.isAdminUser = this.sessionHelper.isAdminRole();
        this.userRole = this.coreHelper.getRoleDisplayName(userInfo.Role);
      }
      // call getNotifications if usersession is valid
      this.getNotifications();
      let pModeSetting = this.sessionHelper.getPatientModeSetting();
      if (pModeSetting != null) {
        this.isPatientModeEnabled = pModeSetting.IsEnabled;
      }
      if (this.currentPatient == null) {
        var patient = this.sessionHelper.getSelectedPatient();
        this.currentPatient = patient;
        this.patientService.setCurrentPatient(this.encryptionService.encryptUsingAES256(JSON.stringify(this.currentPatient)));
      }
    } else {
      this.loggingService.logVerbose("No Profile fetched as user is not logged in");
    }
    if(this.coreAppPageMode == this.coreAppPageModeEnum.appui){
      this.showProgressBar = false;
    }
  }

  login(){
    this.routeHelper.NavigateToLoginPage();
  }

  public logout(){
    this.routeHelper.NavigateToLogoutPage();
  }

  navigateToApp(){
    this.routeHelper.NavigateToApp();
  }
  
  getNotifications(){
    this.notificationService.getNotifications()
          .subscribe({
             next: (notificationsResult: CloudApiResponse) => {
                    this.loggingService.logVerbose(notificationsResult);
                    this.notifications = notificationsResult.payload;
                    this.updateUndismissedNotificationCount();
                  }, 
            error: () => { this.loggingService.logError("Error getting notifications"); },
            complete: () => { this.loggingService.logVerbose("Successfully retrieved Notifications"); }
          });
  }

  updateUndismissedNotificationCount() {
    this.notificationCount = this.notifications.filter(m => m.isDismissed == false).length;
  }

  dismissNotfication(notificationId: string) {
    var notificationStatus = this.notifications.find(m => m.notificationId == notificationId);
    notificationStatus.isDismissed = true;
    
    this.notificationService.dismissNotification(this.sessionHelper.getCustomerId(), notificationStatus)
          .subscribe({
             next: (result: CloudApiResponse) => {
                  this.loggingService.logVerbose(result.payload);
                  var dismissed = result.payload;
                  if (dismissed)
                    this.updateUndismissedNotificationCount();
                  }, 
            error: () => { this.loggingService.logError("Error dismissing notification"); },
            complete: () => { this.loggingService.logVerbose("Successfully dismissed notification"); }
          });
  }

  public getDateOnly(datetime:string):string{
    return this.coreHelper.getDateOnly(datetime);
  }

  public calculateAge(date: string) 
  { 
    const now = new Date(); 
    const diff = Math.abs(Date.parse(date) - Date.parse(now.toString()));
    const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
    return age
  } 

  public setPatient(data:Patient) {
    this.currentPatient = data; 
    if (data != undefined) {
      this.currentPatientAge = this.calculateAge(data.DOB)
    }
  }
  
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.patientSubscription.unsubscribe();
  }
}
