<div class="borderBlue" style="height: calc(100% - 37px);">
    <button mat-icon-button class="square" style="float: right; right:6px;" (click)="openContactEditor(null)" *ngIf="((contactCategoryList.length) > 0)" title="Add Global Contact">
        <mat-icon>person_add</mat-icon>
    </button>

    <div *ngIf="(contactCategoryList.length == 0)">
        <div style="padding:20px;" class="alignCenter">
            No contact categories or contacts found.
        </div>
    </div>

    <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms" style="display:inline;" (selectedTabChange)="contactCategoryChange($event)">
        <mat-tab [label]="contactCategory.ContactCategoryId" *ngFor="let contactCategory of contactCategoryList">
            <ng-template mat-tab-label>
                <span>{{contactCategory.ContactCategoryName}}</span>
            </ng-template>

            <div  class="overflowAuto" style="height: calc(100vh - 64px - 37px - 48px - 86px);">
                <div *ngIf="contactListToShow.length > 0">
                    <div *ngFor="let contact of contactListToShow">
                        <div style="width: 100%;" class="notify-grid-contactList borderBottomBlue Padding10px">
                            <div>
                                <div class="notify-grid-contactnameRow">
                                    <mat-checkbox [checked]="contact.IsContactPreferred" style="margin-top: -10px;"></mat-checkbox>
                                    <div style="margin-left: 5px;">
                                        {{contact.FirstName}}&nbsp;{{contact.LastName}}
                                        &nbsp;&nbsp;&nbsp;&nbsp;<span *ngIf="contact.Alias != null && contact.Alias != ''"> ({{contact.Alias}})</span>
                                    </div>
                                </div>
                                <div class="padding-left-30">                                        
                                    <div *ngIf="contact.Mobile != null && contact.Mobile != ''">
                                        <mat-checkbox [checked]="contact.IsSMSPreferred"></mat-checkbox> 
                                        <mat-icon style="vertical-align: middle;">phone</mat-icon>
                                        <span *ngIf="contact.MobileCountryCode == '+1'">&nbsp;&nbsp;{{contact.MobileCountryCode}}({{contact.Mobile.slice(0,3)}}){{contact.Mobile.slice(3,6)}}-{{contact.Mobile.slice(6,10)}}</span>
                                        <span *ngIf="contact.MobileCountryCode != '+1'">&nbsp;&nbsp;{{contact.MobileCountryCode}}{{contact.Mobile}}</span>
                                        <span *ngIf="contact.MobileServiceProviderName != null && contact.MobileServiceProviderName != ''">
                                            ({{contact.MobileServiceProviderName}})
                                        </span>
                                                                                   
                                    </div>
                                    <div *ngIf="contact.Email != null && contact.Email != ''">
                                        <mat-checkbox [checked]="contact.IsEmailPreferred"></mat-checkbox> 
                                        <mat-icon style="vertical-align: middle;">mail_outline</mat-icon>
                                        <span>&nbsp;&nbsp;{{contact.Email}}</span>
                                                                                   
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: right;">
                                <div *ngIf="contact.IsDeleted==0">
                                    <button mat-icon-button (click)="openContactEditor(contact)" title="Edit">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="deleteContact(contact)" title="Delete">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                                <div *ngIf="contact.IsDeleted==1">
                                    <button mat-icon-button (click)="undoDeleteContact(contact)" title="Undo">
                                        <mat-icon>undo</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="contactListToShow.length == 0">
                    <div style="padding:20px;" class="alignCenter">
                        No contacts found
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>