<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content class="messageBox">
  <!-- <p>{{data.message}}</p> -->
  <p class="error" hidden>{{data.errorMessage}}</p>
  <mat-form-field>
    <div>{{data.inputLabel}}</div>
    <input matInput [(ngModel)]="data.userInput" type="password">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onCancelClick()">
    Cancel<mat-icon>cancel</mat-icon>
  </button>
  <button mat-raised-button cdkFocusInitial (click)="onSubmitClick()">
    Submit<mat-icon>check</mat-icon>
  </button>
</div>
