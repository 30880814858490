// Angular 
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';

// Shared Lib
import { CloudApiResponse, EnvService, LoginAttemptRequest, LoginSuccessRequest, LoginErrorRequest } from 'kscigcorelib';
import { LoggingService, HandleErrorService, SessionService, ApiService, AbstractAuthService, IPService } from 'kscigcorelib';

// Application


@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractAuthService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private loggingService: LoggingService,
    private msalAuthService: MsalService,
    private apiService: ApiService,
    private sessionService: SessionService,
    private ipService: IPService,
    private envService: EnvService
    ) { 
      super(loggingService, msalAuthService, sessionService, ipService);
    }

  public registerLoginAttempt(loginAttemptRequest: LoginAttemptRequest) {
    return this.http.post(this.envService.apiUrl + '/AuthApiAuth/RegisterLoginAttempt', this.apiService.createApiRequestPayload(loginAttemptRequest))
    .pipe(
      catchError(this.handleError.handleError<any>('RegisterLoginAttempt', []))
    );
  }

  public registerLoginSuccess(loginSuccessRequest: LoginSuccessRequest) {
    return this.http.post(this.envService.apiUrl + '/AuthApiAuth/RegisterLoginSuccess', this.apiService.createApiRequestPayload(loginSuccessRequest), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('RegisterLoginSuccess', []))
    );
  }

  public registerLoginEnd(loginAttemptRequestId:string){
    return this.http.post(this.envService.apiUrl + '/AuthApiAuth/RegisterLoginEnd', this.apiService.createApiRequestPayload(loginAttemptRequestId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('registerLoginEnd', []))
    );
  }

  public registerLoginError(loginErrorRequest: LoginErrorRequest){
    return this.http.post(this.envService.apiUrl + '/AuthApiAuth/RegisterLoginError', this.apiService.createApiRequestPayload(loginErrorRequest), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('registerLoginEnd', []))
    );
  }

  public isUserAuthorized(userEmail:string, customerId:string){
    return this.http.get(this.envService.apiUrl + '/AuthApiAuth/IsUserAuthorized?eue=' + this.apiService.secureApiQueryString(userEmail) +"&ecid=" + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('registerLoginEnd', []))
    );
  }

  public registerHeartbeatTick(loginAttemptRequestId: string):Observable<any> {
    return this.http.post(this.envService.apiUrl + '/AuthApiAuth/tick', this.apiService.createApiRequestPayload(loginAttemptRequestId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('RegisterHeartbeatTick'))
    );
  }

  public getProfile() {
    return this.http.get(this.envService.GRAPH_ENDPOINT + '/memberOf')
      .pipe(
        catchError(this.handleError.handleError<any>('getProfile', []))
      );
  }

  public getUsers(customerId: string):Observable<CloudApiResponse> {
    return this.http.get(this.envService.apiUrl + '/AuthApiUser/GetUsers?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getUsers', []))
    );
  }
  
}
