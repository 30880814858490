import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';

// Application Shared library
import { LoggingService, EnvService } from 'kscigcorelib';

import { SessionHelper } from '../helpers/session.helper';

export const AuthGuard: CanActivateFn = (route, state) => {
    const sessionHelper = inject(SessionHelper);
    const router = inject(Router);
    const loggingService = inject(LoggingService)

    var authUser = sessionHelper.getLoggedInUser();
    if (authUser) {
        // Specifying pages by role
        if((sessionHelper.isValidUserRole() && state.url == '/notify') 
            || (sessionHelper.isValidUserRole() && state.url == '/media')  
            || (sessionHelper.isValidUserRole() && state.url == '/mediaplay')  
            || (sessionHelper.isValidUserRole() && state.url == '/worklist')   
            || (sessionHelper.isValidUserRole() && state.url == '/activity')    
            || (sessionHelper.isValidUserRole() && state.url == '/reports')    
            || (sessionHelper.isValidUserRole() && state.url == '/settings')    
            || (sessionHelper.isValidUserRole() && state.url == '/help')    
        ){
            // authorized so return true
            return true;
        } else {
            loggingService.logError("Unauthorized access, routing to home page");
            router.navigate(['/unauthorized']);
            return false;
        }
    }

    // not logged in so redirect to login page with the return url
    router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
}