<div *ngIf="!isModuleEnabled">
    <div class="alignCenter Padding5px">
        Oops! You are not subscribed to this module.
    </div>
</div>

<div *ngIf="isModuleEnabled" class="ContentBox Padding5px">
    
    <div *ngIf="!media || !media.MediaId">{{mediaLoadStatusMsg}}</div>

    <div *ngIf="isPremierMediaEnabled && isPremierMediaSettingsLoaded && media != undefined && media.MediaModuleTypeId == premierModuleTypeId">        
        <app-media-player-shaka [mediaUrl]="media.MediaUrl" [posterUrl]="media.ThumbnailPath" [startPlayPositionSeconds]="savedMediaPref.PlayPositionSeconds" 
                    [swankPortalId]="media.ExMediaPortalId" [widevineLicenseServerUrl]="widevineLicenseServerUrl" [fairplayLicenseServerUrl]="fairplayLicenseServerUrl"
                    [fairplayCertificateUrl]="fairplayCertificateUrl" 
                    [isPatientModeEnabled]="isPatientModeEnabled" [patientModePassword]="patientModePassword"
                    (mediaPlayEvent)="onMediaPlay($event)" 
                    (mediaPlayExitEvent)="onMediaPlayExit($event)"                    
                    >
        </app-media-player-shaka>        
    </div>
   
</div>
