// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { CloudApiResponse, EnvService } from 'kscigcorelib';
import { HandleErrorService, CoreHelper, ApiService } from 'kscigcorelib';

// Application
import { WorklistItem, SessionPatient } from '../shared/models/worklist.interface';
import { NotifyContact, PatientContact } from '../notify/notify-model'

@Injectable({
  providedIn: 'root'
})
export class WorklistService {

  constructor(
    private coreHelper: CoreHelper,
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
    ) { }

  public getLocations(customerId: string, userOnly: boolean): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/WorklistApiCriteria/GetLocations?ecid=' + this.apiService.secureApiQueryString(customerId) + "&userOnly=" + userOnly, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Location data', []))
    );
  }

  public getTimeFilters(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/WorklistApiCriteria/GetTimeFilters?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Location data', []))
    );
  }

  public getSMSConsentFilters(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/WorklistApiCriteria/GetSMSConsentFilters?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Location data', []))
    );
  }

  public getPatients(customerId: string, criteriaId: number, locId: number, timeFilterId: number, consentFilterId: number, keyword: string): Observable<CloudApiResponse> {
    var queryString = "&criteriaId=" + criteriaId + "&locId=" + locId + "&timeFilter=" + timeFilterId + "&consentFilter=" + consentFilterId + "&keyword=" + keyword;
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/WorklistApiPatient/GetAll?ecid=' + this.apiService.secureApiQueryString(customerId) + queryString, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Worklist data', []))
    );
  }

  public addPatient(customerId: string, wlItem: WorklistItem){    
    return this.http.post(this.envService.apiUrl + '/WorklistApiPatient/Add?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(wlItem, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Add Worklist item'))
    );
  }

  public updatePatient(customerId: string, wlItem: WorklistItem){
    return this.http.put(this.envService.apiUrl + '/WorklistApiPatient/update?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(wlItem, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Update Worklist item'))
    );
  }

  public deletePatient(customerId: string, wlItemId: string){
    return this.http.delete(this.envService.apiUrl + '/WorklistApiPatient/delete?ecid=' + this.apiService.secureApiQueryString(customerId) + "&ewlid=" + this.apiService.secureApiQueryString(wlItemId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Delete Worklist item'))
    );
  }

  public selectOrDeselectPatient(customerId: string, sessionPatient:SessionPatient, isSelected: boolean){
    return this.http.post(this.envService.apiUrl + '/WorklistApiPatient/selection?ecid=' + this.apiService.secureApiQueryString(customerId) + "&isSelected=" + isSelected, this.apiService.createApiRequestPayload(sessionPatient, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Select or Deselect Patient'))
    );
  }
}
